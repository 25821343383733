import {
  ADD_HEADING,
  AddHeadingAction,
  DELETE_HEADING,
  DeleteHeadingAction,
  EDIT_HEADING,
  EditHeadingAction,
  SET_HEADINGS,
  SetHeadingAction
} from './types';
import axios from 'axios';
import { IHeading } from '../../interfaces/heading';

export const setHeading = (headings: IHeading[]): SetHeadingAction => ({
  type: SET_HEADINGS,
  payload: headings
});

export const addHeading = (heading: IHeading): AddHeadingAction => ({
  type: ADD_HEADING,
  payload: heading
});

export const deleteHeading = (id: string): DeleteHeadingAction => ({
  type: DELETE_HEADING,
  id
});

export const editHeading = (heading: IHeading): EditHeadingAction => ({
  type: EDIT_HEADING,
  payload: heading
});

export const fetchHeading: any = (url: string) => {
  return (dispatch) => {
    axios
      .get<IHeading[]>(url)
      .then((res) => res.data)
      .then((data) => {

        dispatch(setHeading(data));
      })
      .catch((err) => console.error('Server connections error'));
  };
};
