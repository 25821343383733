import axios, { AxiosResponse } from 'axios';

interface UpdateData {
  // Define the structure of the data you want to update
  // For example, assuming you are updating a patient
  id: string;
  name: string;
  // Add other properties as needed
}

export const addData = async (url: string, newData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(url, newData);
    return response;
  } catch (error) {
    console.error('Error adding data:', error);
    throw error;
  }
};

export const deleteData = async (url: string, deleteData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(`${url}`);
    return response;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};

export const updateData = async (url: string, updateData: UpdateData): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(url, updateData);
    return response;
  } catch (error) {
    // Handle error
    console.error('Error updating data:', error);
    throw error;
  }
};
