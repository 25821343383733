import {
  ADD_DOCTOR,
  AddDoctorAction,
  DELETE_DOCTOR,
  DeleteDoctorAction,
  EDIT_DOCTOR,
  EditDoctorAction,
  SET_DOCTORS,
  SetDoctorAction
} from './types';
import { message } from 'antd';

import { DocResponse, IDoctor } from '../../interfaces/doctor';
import axios from 'axios';
import { clearLocalStorage, logout } from '../auth/action';

export const setDoctors = (doctors: IDoctor[]): SetDoctorAction => ({
  type: SET_DOCTORS,
  payload: doctors
});

export const addDoctor = (doctor: IDoctor): AddDoctorAction => ({
  type: ADD_DOCTOR,
  payload: doctor
});

export const deleteDoctor = (id: string): DeleteDoctorAction => ({
  type: DELETE_DOCTOR,
  id
});

export const editDoctor = (doctor: IDoctor): EditDoctorAction => ({
  type: EDIT_DOCTOR,
  payload: doctor
});

export const fetchDoctors: any = (url: string, headers: any) => {
  return (dispatch) => {
    axios
      .get<DocResponse>(url, { headers })
      .then((res) => {
        const doctorsData = res.data?.data?.data?.doctors;
        dispatch(setDoctors(doctorsData || []));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          message.error('Token Expired: Login Again');
          dispatch(logout());
          clearLocalStorage();
          return;
        }
        message.error('Server connections error');
      });
  };
};
