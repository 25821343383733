import { IHospital } from '../interfaces/hospital';
import { useDispatch, useSelector } from 'react-redux';

import * as hospitalActions from '../redux/hospitals/actions';
import { IAppState } from '../interfaces/app-state';

export function useHospitals() {
  const dispatch = useDispatch();
  const hospitals = useSelector<IAppState, IHospital[]>((state) => state.hospitals);

  const editHospital = (hospital: IHospital) => {
    return dispatch(hospitalActions.editHospital(hospital));
  };

  const addHospital = (hospital: IHospital) => {
    return dispatch(hospitalActions.addHospital(hospital));
  };

  const deleteHospital = (id: string) => {
    return dispatch(hospitalActions.deleteHospital(id));
  };

  return { hospitals, addHospital, editHospital, deleteHospital };
}
