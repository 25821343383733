import { IDisease } from '../../interfaces/disease';

export const SET_DISEASES = '[disease] Set';
export const ADD_DISEASE = '[disease] Add';
export const EDIT_DISEASE = '[disease] Edit';
export const DELETE_DISEASE = '[disease] Delete';

export interface SetDiseaseAction {
  type: typeof SET_DISEASES;
  payload: IDisease[];
}

export interface AddDiseaseAction {
  type: typeof ADD_DISEASE;
  payload: IDisease;
}

export interface EditDiseaseAction {
  type: typeof EDIT_DISEASE;
  payload: IDisease;
}

export interface DeleteDiseaseAction {
  type: typeof DELETE_DISEASE;
  _id: string;
}

export type DiseaseActions =
  | SetDiseaseAction
  | AddDiseaseAction
  | DeleteDiseaseAction
  | EditDiseaseAction;
