import {
  ADD_FORMULA,
  AddFormulaAction,
  DELETE_FORMULA,
  DeleteFormulaAction,
  EDIT_FORMULA,
  EditFormulaAction,
  SET_FORMULAS,
  SetFormulaAction
} from './types';


import { IFormula } from '../../interfaces/formula';
import axios from 'axios';

export const setFormulas = (formula: IFormula[]): SetFormulaAction => ({
  type: SET_FORMULAS,
  payload: formula
});

export const addFormula = (formula: IFormula): AddFormulaAction => ({
  type: ADD_FORMULA,
  payload: formula
});

export const deleteFormula = (id: string): DeleteFormulaAction => ({
  type: DELETE_FORMULA,
  id
});

export const editFormula = (formula: IFormula): EditFormulaAction => ({
  type: EDIT_FORMULA,
  payload: formula
});

export const fetchFormulas: any = (url: string) => {
  return (dispatch) => {
    axios
      .get<IFormula[]>(url)
      .then((res) => res.data)
      .then((data) => {
        dispatch(setFormulas(data));
      })
      .catch((err) => console.error('Server connections error'));
  };
};
