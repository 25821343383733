import { Store, applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { createHashHistory } from 'history';

import { authReducer } from './auth/reducer';
import { diseaseReducer } from './disease/reducer';
import { doctorsReducer } from './doctors/reducer';
import { formGroupsReducer } from './formGroup/reducer';
import { formsReducer } from './forms/reducer';
import { formulasReducer } from './formulas/reducer';
import { headingReducer } from './heading/reducer';
import { hospitalsReducer } from './hospitals/reducer';
import { organReducer } from './organ/reducer';
import { pageDataReducer } from './page-data/reducer';
import { patientsReducer } from './patients/reducer';
import { reportReducer } from './reports/reducer';
import { settingsReducer } from './settings/reducer';
import { specieReducer } from './specie/reducer';
import { testParametersReducer } from './testParameter/reducer';

export const history = createHashHistory();

const rootReducer = combineReducers({
  auth: authReducer,
  pageData: pageDataReducer,
  settings: settingsReducer,
  patients: patientsReducer,
  species: specieReducer,
  doctors: doctorsReducer,
  testParameters: testParametersReducer,
  organs: organReducer,
  formulas: formulasReducer,
  hospitals: hospitalsReducer,
  diseases: diseaseReducer,
  forms: formsReducer,
  headings: headingReducer,
  reports: reportReducer,
  formGroups: formGroupsReducer
});
export type AppState = ReturnType<typeof rootReducer>;
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store: Store<AppState> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
