import {
  ADD_SPECIE,
  AddSpecieAction,
  DELETE_SPECIE,
  DeleteSpecieAction,
  EDIT_SPECIE,
  EditSpecieAction,
  SET_SPECIES,
  SetSpecieAction
} from './types';
import { message } from 'antd';

import { ISpecie } from '../../interfaces/specie';
import axios from 'axios';
import { clearLocalStorage, logout } from '../auth/action';

export const setSpecies = (species: ISpecie[]): SetSpecieAction => ({
  type: SET_SPECIES,
  payload: species
});

export const addSpecie = (specie: ISpecie): AddSpecieAction => ({
  type: ADD_SPECIE,
  payload: specie
});

export const deleteSpecie = (_id: string): DeleteSpecieAction => ({
  type: DELETE_SPECIE,
  _id
});

export const editSpecie = (specie: ISpecie): EditSpecieAction => ({
  type: EDIT_SPECIE,
  payload: specie
});

export const fetchSpecie: any = (url: string, headers: any) => {
  return (dispatch) => {
    axios
      .get<{ data: ISpecie[] }>(url, { headers })
      .then((res) => res.data)
      .then((data) => {
        dispatch(setSpecies(data.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          message.error('Token Expired: Login Again');
          dispatch(logout());
          clearLocalStorage();
          return;
        }
        message.error('Server connections error');
      });
  };
};
