import { useEffect, useState } from 'react';
import { usePageData } from '../../../hooks/usePage';
import { useOrgan } from '../../../hooks/useOrgan';
import OrganTable from './OrganTable';
import { IPageData } from '../../../interfaces/page';
import AddOrgan from './AddOrgan/AddOrgan';
import { fetchOrgan } from '../../../redux/organ/actions';
import { useDispatch } from 'react-redux';
import { getAllOrganURL, getAllSpecieURL } from '../../../constants/url';
import { fetchSpecie } from '../../../redux/specie/actions';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';

const pageData: IPageData = {
  title: 'Organ',
  fulFilled: true
};

const OrganPage = () => {
  const { organs, editOrgan, deleteOrgan } = useOrgan();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const role = localStorage.getItem('role');

  usePageData(pageData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        await dispatch(fetchOrgan(getAllOrganURL, headers));
      } catch (error) {
        message.error('Failed to fetch organ data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <>
      <Spin
        spinning={loading}
        size='large'
        indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
      >
        {role === 'admin' ? (
          <header className='page-header'>
            <AddOrgan />
          </header>
        ) : (
          ''
        )}
        <OrganTable onDeleteOrgans={deleteOrgan} onEditOrgans={editOrgan} organs={organs} />
      </Spin>
    </>
  );
};

export default OrganPage;
