import React from 'react';

import { usePageData } from '../../../hooks/usePage';
import { useTestParameter } from '../../../hooks/useTestParameter';

import TestParameterTable from './TestparameterTable'; // Corrected import

import { IPageData } from '../../../interfaces/page';
import { usePatients } from '../../../hooks/usePatients';
import AddTestParameter from './AddTestParameter/AddTestParameter';

const pageData: IPageData = {
  title: 'Test Parameters',
  fulFilled: true
};

const TestParameterPage = () => {
  const { testParameters, editTestParameter, deleteTestParameter } = useTestParameter(); 
  const { patients } = usePatients();
  usePageData(pageData);
  return (
    <>
      <header className='page-header'>
        <AddTestParameter />
      </header>
      <TestParameterTable
        onDeleteTestParameter={deleteTestParameter}
        onEditTestParameter={editTestParameter}
        testParameters={testParameters}
      />
    </>
  );
};

export default TestParameterPage;
