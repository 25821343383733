import { usePageData } from '../../../hooks/usePage';
import { useSpecies } from '../../../hooks/useSpecie';
import SpecieTable from './SpecieTable';
import { IPageData } from '../../../interfaces/page';
import AddSpecie from './AddSpecie/AddSpecie';
import { useEffect, useState } from 'react';
import { fetchSpecie } from '../../../redux/specie/actions';
import { useDispatch } from 'react-redux';
import { getAllSpecieURL } from '../../../constants/url';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';

const pageData: IPageData = {
  title: 'Species',
  fulFilled: true
};

const SpeciePage = () => {
  const role = localStorage.getItem('role');
  const { species, editSpecie, deleteSpecie } = useSpecies();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  usePageData(pageData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        await dispatch(fetchSpecie(getAllSpecieURL, headers));
      } catch (error) {
        message.error('Failed to fetch species data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <>
      <Spin
        spinning={loading}
        size='large'
        indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
      >
        {role === 'admin' ? (
          <header className='page-header'>
            <AddSpecie />
          </header>
        ) : (
          ''
        )}
        <SpecieTable onDeleteSpecie={deleteSpecie} onEditSpecie={editSpecie} species={species} />
      </Spin>
    </>
  );
};

export default SpeciePage;
