import { ADD_SPECIE, DELETE_SPECIE, EDIT_SPECIE, SpeciesActions, SET_SPECIES } from './types';
import { ISpecie } from '../../interfaces/specie';

const initialState: ISpecie[] = [];

export function specieReducer(state: ISpecie[] = initialState, action: SpeciesActions): ISpecie[] {
  switch (action.type) {
    case SET_SPECIES: {
      return [...action.payload];
    }

    case EDIT_SPECIE: {
      const editedSpecie = state.map((el) => (el._id !== action.payload._id ? el : action.payload));

      return [...editedSpecie];
    }

    case ADD_SPECIE: {
      return [{ ...action.payload }, ...state];
    }

    case DELETE_SPECIE: {
      const species = state.filter((el) => el._id !== action._id);

      return [...species];
    }

    default: {
      return state;
    }
  }
}
