import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearLocalStorage, logout } from '../../../redux/auth/action';

const SettingsDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    try {
      dispatch(logout());
      clearLocalStorage();
      navigate('/sign-in', { replace: true });
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <Button type='link' onClick={handleLogout}>
      <i className='icofont-logout'></i>
      Log Out
    </Button>
  );
};

export default SettingsDropdown;
