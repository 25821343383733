import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Table, message } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useState } from 'react';

import { getAllOrganURL } from '../../../constants/url';
import { IOrgan } from '../../../interfaces/organ';
import OrganForm from './AddOrgan/OrganForm';
import { deleteData } from './AddOrgan/api';

type Props = {
  organs: IOrgan[];
  onEditOrgans?: (organ: IOrgan) => void;
  onDeleteOrgans?: (id: string) => void;
};

const OrganTable = ({ organs, onEditOrgans = () => null, onDeleteOrgans = () => null }: Props) => {
  const [organ, setOrgan] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const closeModal = () => setVisibility(false);

  const handleDeleteSpecie = async (id) => {
    try {
      await deleteData(getAllOrganURL + `/${id}`, id);
      message.success('Organ deleted successfully');
      onDeleteOrgans(id);
    } catch (error) {
      message.error('Failed to delete form. Please try again.');
    }
  };

  const handleEditOrgan = (organ: IOrgan) => {
    setOrgan(organ);
    setVisibility(true);
  };
  // useEffect(() => {
  //   if (organ) setVisibility(true);
  // }, [organ]);
  const role = localStorage.getItem('role');

  const actions = (organ: IOrgan) => (
    <div className='buttons-list nowrap justify-content-center'>
      <Button
        disabled={role === 'doctor'}
        onClick={() => handleEditOrgan(organ)}
        shape='circle'
        type='primary'
      >
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Popconfirm
        title='Are you sure to delete this row?'
        okText='Yes'
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={() => handleDeleteSpecie(organ._id)}
        disabled={role === 'doctor'}
      >
        <Button shape='circle' danger>
          <span className='icofont icofont-ui-delete' />
        </Button>
      </Popconfirm>
    </div>
  );
  const uniqueSpecies = Array.from(
    new Set(organs.map((organ) => organ.specie?.name).map((species) => species.toLowerCase()))
  );
  const columns: ColumnProps<IOrgan>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Organ',
      align: 'center',

      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'specie',
      dataIndex: 'specie',
      title: 'Specie',
      align: 'center',
      filters: uniqueSpecies.map((species) => ({ text: species, value: species })),
      onFilter: (value, record) => record.specie?.name?.toLowerCase() === value,
      render: (specie) => (specie ? <strong>{specie.name}</strong> : null)
    }
  ];
  if (role === 'admin') {
    columns.push({
      key: 'actions',
      title: 'Actions',
      align: 'center',
      render: actions
    });
  }
  const pagination = organs.length <= 10 ? false : {};

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={organs}
        columns={columns}
      />

      <Modal
        open={visibility}
        footer={null}
        onCancel={closeModal}
        title={<h3 className='title'>Update organ</h3>}
      >
        <OrganForm
          submitText='Update Organ'
          onCancel={closeModal}
          onSubmit={onEditOrgans}
          Organ={organ}
        />
      </Modal>
    </>
  );
};

export default OrganTable;
