import { useState } from 'react';
import { ColumnProps } from 'antd/es/table';
import { Table, Button, Modal, Popconfirm, message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { ISpecie } from '../../../interfaces/specie';

import SpecieForm from './AddSpecie/SpecieForm';
import { deleteData } from './AddSpecie/api';
import { getAllSpecieURL } from '../../../constants/url';

type Props = {
  species: ISpecie[];
  onEditSpecie?: (specie: ISpecie) => void;
  onDeleteSpecie?: (id: string) => void;
};

const SpecieTable = ({
  species,
  onEditSpecie = () => null,
  onDeleteSpecie = () => null
}: Props) => {
  const [specie, setSpecie] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const closeModal = () => setVisibility(false);

  const handleDeleteSpecie = async (id) => {
    try {
      await deleteData(`${getAllSpecieURL}/${id}`, id);
      message.success('Specie deleted successfully');
      onDeleteSpecie(id);
    } catch (error) {
      message.error('Failed to delete Specie. Please try again.');
    }
  };
  const handleEditSpecie = (specie: ISpecie) => {
    setSpecie(specie);
    setVisibility(true);
  };

  const role = localStorage.getItem('role');

  const actions = (specie: ISpecie) => (
    <div className='buttons-list nowrap justify-content-center'>
      <Button
        disabled={role === 'doctor'}
        onClick={() => handleEditSpecie(specie)}
        shape='circle'
        type='primary'
      >
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Popconfirm
        title='Are you sure to delete this row?'
        okText='Yes'
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={() => handleDeleteSpecie(specie._id)}
        disabled={role === 'doctor'}
      >
        <Button shape='circle' danger>
          <span className='icofont icofont-ui-delete' />
        </Button>
      </Popconfirm>
    </div>
  );

  const columns: ColumnProps<ISpecie>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Specie',
      align: 'center',

      render: (name) => <strong>{name}</strong>
    }
  ];

  if (role === 'admin') {
    columns.push({
      key: 'actions',
      title: 'Actions',
      align: 'center',
      render: actions
    });
  }
  const pagination = species.length <= 10 ? false : { showSizeChanger: false };

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={species}
        columns={columns}
      />

      <Modal
        open={visibility}
        footer={null}
        onCancel={closeModal}
        title={<h3 className='title'>Edit specie</h3>}
      >
        <SpecieForm
          submitText='Update specie'
          onCancel={closeModal}
          onSubmit={onEditSpecie}
          Specie={specie}
        />
      </Modal>
    </>
  );
};

export default SpecieTable;
