import { Button, Modal } from 'antd';
import { useState } from 'react';

import { useFormGroup } from '../../../../hooks/useFormGroup';
import { IFormGroups } from '../../../../interfaces/formGroup';
import { FloatButton } from 'antd';
import FormGroupForm from './FormGroupForm';

const AddFormGroup = () => {
  const { addFormGroup } = useFormGroup();
  const [visible, setVisible] = useState(false);

  const handleClick = () => setVisible(!visible);

  const closeModal = () => setVisible(false);

  const handleAddFormGroup = (formGroup: IFormGroups) => {
    addFormGroup(formGroup);
    closeModal();
  };

  return (
    <div className='add-form-group'>
      <FloatButton
        className='float_button'
        onClick={handleClick}
        icon={<i className='icofont-ui-add'></i>}
      />
      <Modal
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<h3 className='title'>Add Form Group</h3>}
      >
        <FormGroupForm onCancel={closeModal} onSubmit={handleAddFormGroup} />
      </Modal>
    </div>
  );
};

export default AddFormGroup;
