import { Button, Input, message, Spin } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { hasErrorFactory } from '../../../../utils/hasError';

import { useEffect, useState } from 'react';
import { getAllSpecieURL } from '../../../../constants/url';
import { ISpecie } from '../../../../interfaces/specie';
import { editSpecie } from '../../../../redux/specie/actions';
import { addData, updateData } from './api';

import { LoadingOutlined } from '@ant-design/icons';

type Props = {
  onSubmit: (Specie: any) => void;
  onCancel: () => void;
  Specie?: ISpecie;
  submitText?: string;
};

const defaultSubmitText = 'Add Specie';
const emptySpecie = {
  name: null
};

const SpecieScheme = Yup.object({
  name: Yup.string().required()
});

const SpecieForm = ({
  submitText = defaultSubmitText,
  Specie = emptySpecie,
  onSubmit,
  onCancel
}: Props) => {
  const [loading, setLoading] = useState(false);
  const { setValues, handleSubmit, setFieldValue, handleBlur, touched, values, errors, isValid } =
    useFormik<ISpecie>({
      validationSchema: SpecieScheme,
      initialValues: Specie,
      onSubmit: async (values: any) => {
        try {
          setLoading(true);

          if (submitText === 'Update specie') {
            let res = await updateData(getAllSpecieURL + `/${values._id}`, values);
            editSpecie(values);
            if (res?.status === 201) {
              message.success('Specie updated successfully');
              setLoading(false);
            }
            onSubmit(res?.data?.data);
          } else {
            let res = await addData(getAllSpecieURL, values);
            message.success('Specie Added Successfully');
            onSubmit(res.data.data);
            setLoading(false);
          }
          onCancel();
        } catch (error) {
          console.error('An error occurred:', error);
          message.error('An error occurred while processing your request');
        } finally {
          setLoading(false);
        }
      }
    });
  const hasError = hasErrorFactory(touched, errors);

  const handleCancel = () => {
    onCancel();
  };
  useEffect(() => {
    setValues(Specie);
  }, [Specie]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <label htmlFor='specieTitle' style={{ fontWeight: 'bold' }}>
            <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i> Title:
          </label>
          <Input
            id='specieTitle'
            placeholder='Title'
            name='name'
            type='text'
            onBlur={handleBlur}
            onChange={(value) => setFieldValue('name', value.target.value)}
            value={values.name}
            className={hasError('name')}
          />
        </div>
        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>
          <Button disabled={!isValid || loading} type='primary' htmlType='submit'>
            {loading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: 'white' }} spin />} />
            ) : (
              submitText
            )}
          </Button>
        </div>
      </form>
    </>
  );
};

export default SpecieForm;
