import { ITestParameter } from '../../interfaces/testParameter';

export const SET_TEST_PARAMETERS = '[testParameter] Set';
export const ADD_TEST_PARAMETER = '[testParameter] Add';
export const EDIT_TEST_PARAMETER = '[testParameter] Edit';
export const DELETE_TEST_PARAMETER = '[testParameter] Delete';

export interface SetTestParametersAction {
  type: typeof SET_TEST_PARAMETERS;
  payload: ITestParameter[];
}

export interface AddTestParameterAction {
  type: typeof ADD_TEST_PARAMETER;
  payload: ITestParameter;
}

export interface EditTestParameterAction {
  type: typeof EDIT_TEST_PARAMETER;
  payload: ITestParameter;
}

export interface DeleteTestParameterAction {
  type: typeof DELETE_TEST_PARAMETER;
  id: string;
}

export type TestParameterActions =
  | SetTestParametersAction
  | AddTestParameterAction
  | DeleteTestParameterAction
  | EditTestParameterAction;
