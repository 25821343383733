import { ADD_DISEASE, DELETE_DISEASE, DiseaseActions, EDIT_DISEASE, SET_DISEASES } from './types';
import { IDisease } from '../../interfaces/disease';

const initialState: IDisease[] = [];

export function diseaseReducer(
  state: IDisease[] = initialState,
  action: DiseaseActions
): IDisease[] {
  switch (action.type) {
    case SET_DISEASES: {
      return [...action.payload];
    }

    case EDIT_DISEASE: {
      const editedDisease = state.map((el) =>
        el._id !== action.payload._id ? el : action.payload
      );
      return [...editedDisease];
    }

    case ADD_DISEASE: {
      return [{ ...action.payload }, ...state];
    }

    case DELETE_DISEASE: {
      const diseases = state.filter((el) => el._id !== action._id);
      return [...diseases];
    }

    default: {
      return state;
    }
  }
}
