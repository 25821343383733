import { Button, Input, Select, Spin, message } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { hasErrorFactory } from '../../../utils/hasError';

import { useEffect, useState } from 'react';
import { calculateBSA, getTodayDate } from '../../../constants/functions';
import { getAllPatientURL } from '../../../constants/url';
import { useSpecies } from '../../../hooks/useSpecie';
import { IPatient } from '../../../interfaces/patient';
import { editPatient } from '../../../redux/patients/actions';
import { addData, updateData } from './api';

import { LoadingOutlined } from '@ant-design/icons';

type Props = {
  onSubmit: (patient: IPatient) => void;
  onCancel: () => void;
  patient?: IPatient;
  submitText?: string;
};

const defaultSubmitText = 'Add patient';
const emptyPatient = {
  name: '',
  email: '',
  case_number: '',
  breed: '',
  sex: '',
  doctor_id: '',
  ref_doctor: null,
  bsa: '',
  weight: '',
  age: '',
  dob: '',
  registration_date: '',
  visit_number: 0,
  specie: null
};

const patientScheme = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().notRequired(),
  case_number: Yup.string().required(),
  breed: Yup.string().required(),
  sex: Yup.string().required(),
  ref_doctor: Yup.string().notRequired(),
  weight: Yup.string().required(),
  dob: Yup.string().notRequired(),
  age: Yup.string().required(),
  specie: Yup.mixed().required()
});

const PatientForm = ({
  submitText = defaultSubmitText,
  patient = emptyPatient,
  onSubmit,
  onCancel
}: Props) => {
  const [loading, setLoading] = useState(false);

  const {
    setFieldTouched,
    setFieldValue,
    handleSubmit,
    setValues,
    handleBlur,
    touched,
    values,
    errors,
    isValid
  } = useFormik<IPatient>({
    validationSchema: patientScheme,
    initialValues: patient,
    onSubmit: async (values: any) => {
      setLoading(true);
      try {
        const todayDate = getTodayDate();
        // const calculatedDOB = calculateAge(values?.dob);
        let updatedValues = {
          ...values,
          registration_date: todayDate,
          doctor_id: storedDoctorId,
          age: values?.age,
          bsa: calculateBSA(values?.weight)
        };
        if (submitText === 'Update Patient') {
          let { patient_id, ...updatedPatientWithoutID } = {
            ...values,
            doctor_id: values?.doctor_id?._id ? values?.doctor_id?._id : values?.doctor_id,
            specie: values?.specie?._id ? values?.specie?._id : values.specie,
            patient_id: values.patient_id,
            age: values?.age
          };
          let res = await updateData(getAllPatientURL + `/${values._id}`, updatedPatientWithoutID);
          editPatient(res?.data?.data);
          if (res?.status === 201) {
            message.success(`${res?.data?.message}`);
          }
          onSubmit(res?.data?.data);
        } else {
          let res = await addData(getAllPatientURL, updatedValues);
          if (res?.status === 200) {
            message.success(`${res?.data?.message}`);
          }
          onSubmit(res?.data?.data);
        }
        onCancel();
      } catch (error) {
        message.error('An error occurred');
      } finally {
        setLoading(false);
      }
    }
  });
  const { species } = useSpecies();
  let storedDoctorId = localStorage.getItem('doctor_id');

  const hasError = hasErrorFactory(touched, errors);

  const handleCancel = () => {
    // resetForm();
    onCancel();
  };
  const genderOptions = [
    { value: 'M', label: 'M' },
    { value: 'F', label: 'F' },
    { value: 'O', label: 'O' }
  ];

  useEffect(() => {
    setValues(patient);
  }, [patient]);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='form-group'></div>
        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <label htmlFor='patient-name' style={{ fontWeight: 'bold' }}>
                <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>{' '}
                Name:
              </label>
              <Input
                id='patient-name'
                placeholder='Name'
                name='name'
                type='text'
                onBlur={handleBlur}
                onChange={(value) => setFieldValue('name', value.target.value)}
                value={values.name}
                className={hasError('name')}
              />
            </div>
          </div>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <label htmlFor='patient-specie' style={{ fontWeight: 'bold' }}>
                <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>{' '}
                Specie:
              </label>
              <Select
                id='patient-specie'
                placeholder='Select Specie'
                value={values?.specie?._id ? values?.specie?._id : values?.specie}
                onChange={(value) => {
                  setFieldValue('specie', value);
                }}
                className={values?.specie?._id ? '' : hasError('specie')}
                onBlur={() => setFieldTouched('specie')}
              >
                {species.map((item) => {
                  return (
                    <Select.Option key={item?._id} value={item._id}>
                      {item?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-12'>
            <div className='form-group'>
              <label htmlFor='patient-email' style={{ fontWeight: 'bold' }}>
                Email:
              </label>
              <Input
                id='patient-email'
                name='email'
                type='email'
                placeholder='email'
                onBlur={handleBlur}
                onChange={(value) => setFieldValue('email', value.target.value)}
                value={values.email}
                className={hasError('email')}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <label htmlFor='patient-breed' style={{ fontWeight: 'bold' }}>
                <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>{' '}
                Breed:
              </label>
              <Input
                id='patient-breed'
                placeholder='Breed'
                name='breed'
                type='text'
                onBlur={handleBlur}
                onChange={(value) => setFieldValue('breed', value.target.value)}
                value={values.breed}
                className={hasError('breed')}
              />
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <label htmlFor='patient-refDoc' style={{ fontWeight: 'bold' }}>
                Ref. Doc.:
              </label>
              <Input
                id='patient-refDoc'
                name='ref_doctor'
                type='text'
                placeholder='Ref. Doc.'
                onBlur={handleBlur}
                onChange={(value) => setFieldValue('ref_doctor', value.target.value)}
                value={values.ref_doctor}
                className={hasError('ref_doctor')}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <label htmlFor='patient-caseNumber' style={{ fontWeight: 'bold' }}>
                <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>{' '}
                Case Number:
              </label>
              <Input
                min={0}
                id='patient-caseNumber'
                placeholder='Case Number'
                name='case_number'
                type='number'
                onBlur={handleBlur}
                onChange={(value) => setFieldValue('case_number', value.target.value)}
                value={values.case_number}
                className={hasError('case_number')}
              />
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <label htmlFor='patient-weight' style={{ fontWeight: 'bold' }}>
                <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>{' '}
                Weight:
              </label>
              <Input
                id='patient-weight'
                name='weight'
                type='text'
                placeholder='Weight'
                onBlur={handleBlur}
                onChange={(value) => {
                  const isValidNumber = /^\d*\.?\d*$/.test(value.target.value);
                  if (isValidNumber || value.target.value === '' || value.target.value === '0') {
                    setFieldValue('weight', value.target.value);
                  }
                }}
                value={values.weight}
                className={hasError('weight')}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <label htmlFor='patient-dob' style={{ fontWeight: 'bold' }}>
                Date of Birth:
              </label>
              <Input
                id='patient-dob'
                name='dob'
                type='date'
                placeholder='DOB'
                onBlur={handleBlur}
                onChange={(value) => setFieldValue('dob', value.target.value)}
                value={values.dob}
                className={hasError('dob')}
              />
            </div>
          </div>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <label htmlFor='patient-age' style={{ fontWeight: 'bold' }}>
                <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>{' '}
                Age:
              </label>
              <Input
                id='patient-age'
                name='age'
                type='text'
                placeholder='AGE'
                onBlur={handleBlur}
                onChange={(value) => setFieldValue('age', value.target.value)}
                value={values.age}
                className={hasError('age')}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <label htmlFor='patient-sex' style={{ fontWeight: 'bold' }}>
                <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>{' '}
                Sex:
              </label>
              <Select
                id='patient-sex'
                placeholder='Select Sex'
                value={values.sex === '' ? 'Select Sex' : values.sex}
                onChange={(value) => {
                  setFieldValue('sex', value);
                }}
                className={hasError('sex')}
                onBlur={() => setFieldTouched('sex')}
              >
                {genderOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <label htmlFor='patient-bsa' style={{ fontWeight: 'bold' }}>
                BSA:
              </label>
              <Input
                disabled
                id='patient-bsa'
                name='bsa'
                type='number'
                value={calculateBSA(values?.weight)}
              />
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>

          <Button disabled={!isValid || loading} type='primary' htmlType='submit'>
            {loading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: 'white' }} spin />} />
            ) : (
              submitText
            )}
          </Button>
        </div>
      </form>
    </>
  );
};

export default PatientForm;
