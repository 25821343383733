import { Select, message, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { saveConclusionTemplate, getDiseasesBySpecie } from './api';
import { getAllReportURL, getAllDiseaseURL } from '../../../constants/url';
import { useDispatch } from 'react-redux';
import { IDisease } from '../../../interfaces/disease';


const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    ["blockquote"],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}
const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'color', 'background', 'clean'
];

const ConclusionTemplate = ({ repordId, setLoading, specie }) => {
  const [diseases, setDiseases] = useState<IDisease[]>([]);
  const [selectedDisease, setSelectedDisease] = useState(null);
  const [conclusionTemplate, setConclusionTemplate] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        const res = await getDiseasesBySpecie(getAllDiseaseURL, headers);
        const filteredDiseases = res.data.data.filter(
          (disease) => disease.organ.specie._id === specie
        );
        setDiseases(filteredDiseases);
      } catch (error) {
        message.error('Failed to fetch disease data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleDiseaseChange = (value) => {
    const selected = diseases.find((item) => item._id === value);
    setSelectedDisease(selected);
    if (selected) {
      setConclusionTemplate(selected.conclusionTemplate);
    }
  };

  const handleConclusionChange = (content) => {
    setConclusionTemplate(content);
  };

  const saveConclusionTemplateAPI = async () => {
    if (selectedDisease && conclusionTemplate) {
      try {
        const payload = {
          diseaseId: selectedDisease._id,
          conclusionTemplate: conclusionTemplate
        };

        const response = await saveConclusionTemplate(getAllReportURL, repordId, payload);

        if (response) {
          message.success('Report Submitted Successfully!');
          setTimeout(() => {
            // Route to the dashboard
            navigate('/reports');
          }, 2000);
        } else {
          message.error('Failed to save Report');
        }
      } catch (error) {
        message.error('Error saving Report');
      }
    } else {
      message.error('Select a disease and provide a conclusion template');
    }
  };

  return (
    <>
      <div className='form-group mt-4 '>
        <label htmlFor='diseaseSelect' style={{ fontWeight: 'bold' }}>
          Disease:
        </label>
        <Select
          id='diseaseSelect'
          placeholder='Select Disease'
          onChange={handleDiseaseChange}
          style={{ width: '35em', marginLeft: '1em' }}
        >
          {diseases.map((item) => (
            <Select.Option key={item._id} value={item._id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      {selectedDisease && (
        <div className='form-group '>
          <label htmlFor='conclusionTemplate' style={{ fontWeight: 'bold' }}>
            Conclusion:
          </label>
          <ReactQuill
            value={conclusionTemplate}
            onChange={handleConclusionChange}
            placeholder='Compose your Findings...'
            modules={modules}
            formats={formats}
            style={{ marginTop: '0.5rem' }}
          />
          <div className='d-flex mt-3 justify-content-center '>
            <Button type='primary' onClick={saveConclusionTemplateAPI}>
              Submit Report
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ConclusionTemplate;
