import { ADD_HEADING, DELETE_HEADING, EDIT_HEADING, HeadingsActions, SET_HEADINGS } from './types';
import { IHeading } from '../../interfaces/heading';

const initialState: IHeading[] = [];

export function headingReducer(
  state: IHeading[] = initialState,
  action: HeadingsActions
): IHeading[] {
  switch (action.type) {
    case SET_HEADINGS: {
      return [...action.payload];
    }

    case EDIT_HEADING: {
      const editedHeadings = state.map((el) => (el.id !== action.payload.id ? el : action.payload));

      return [...editedHeadings];
    }

    case ADD_HEADING: {
      return [{ ...action.payload }, ...state];
    }

    case DELETE_HEADING: {
      const headings = state.filter((el) => el.id !== action.id);

      return [...headings];
    }

    default: {
      return state;
    }
  }
}
