import { ITestParameter } from '../interfaces/testParameter';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../redux/testParameter/actions';

import { IAppState } from '../interfaces/app-state';

export function useTestParameter() {
  const dispatch = useDispatch();
  const testParameters = useSelector<IAppState, ITestParameter[]>((state) => state.testParameters);

  const editTestParameter = (testParameter: ITestParameter) => {
    return dispatch(actions.editTestParameter(testParameter));
  };

  const addTestParameter = (testParameter: ITestParameter) => {
    return dispatch(actions.addTestParameter(testParameter));
  };

  const deleteTestParameter = (id: string) => {
    return dispatch(actions.deleteTestParameter(id));
  };

  return { testParameters, addTestParameter, editTestParameter, deleteTestParameter };
}
