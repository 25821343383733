import { ADD_ORGAN, DELETE_ORGAN, EDIT_ORGAN, OrgansActions, SET_ORGANS } from './types';
import { IOrgan } from '../../interfaces/organ';

const initialState: IOrgan[] = [];

export function organReducer(state: IOrgan[] = initialState, action: OrgansActions): IOrgan[] {
  switch (action.type) {
    case SET_ORGANS: {
      return [...action.payload];
    }

    case EDIT_ORGAN: {
      const editedSpecie = state.map((el) => (el._id !== action.payload._id ? el : action.payload));

      return [...editedSpecie];
    }

    case ADD_ORGAN: {
      return [{ ...action.payload }, ...state];
    }

    case DELETE_ORGAN: {
      const organs = state.filter((el) => el._id !== action._id);
      return [...organs];
    }

    default: {
      return state;
    }
  }
}
