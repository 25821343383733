import React, { useState } from 'react';
import { Button, Modal } from 'antd';

import SpecieForm from './SpecieForm';
import { useSpecies } from '../../../../hooks/useSpecie';
import { FloatButton } from 'antd';
import { ISpecie } from '../../../../interfaces/specie';

const AddSpecie = () => {
  const { addSpecie } = useSpecies();
  const [visible, setVisible] = useState(false);

  const handleClick = () => setVisible(!visible);

  const closeModal = () => setVisible(false);

  const handleAddSpecie = (specie: ISpecie) => {
    addSpecie(specie);
    closeModal();
  };

  return (
    <div className='add-specie'>
      <FloatButton
        className='float_button'
        onClick={handleClick}
        icon={<i className='icofont-ui-add'></i>}
      />
      <Modal
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<h3 className='title'>Add Specie</h3>}
      >
        <SpecieForm onCancel={closeModal} onSubmit={handleAddSpecie} />
      </Modal>
    </div>
  );
};

export default AddSpecie;
