import { useEffect, useState } from 'react';

import axios from 'axios';
import BaseLayout from '../base/BaseLayout';

import Logo from '../components/logo/Logo';
import Navbar from '../components/navbar/Navbar';
import LogoSvg from './../../assets/img/logo2.png';

import Menu from '../components/menu/Menu';
import NavLoader from '../components/navbar/NavLoader';

import { toggleSidebar } from '../../redux/settings/actions';
import Actions from '../components/actions/Actions';

import { useDispatch, useSelector } from 'react-redux';
import { useSearchData } from '../../hooks/useSearchData';

import { IAppState } from '../../interfaces/app-state';

import Breadcrumbs from '../components/footer/Breadcrumbs';
import './Vertical.scss';

type Props = {
  children: any;
};

const VerticalLayout = ({ children }: Props) => {
  const dispatch = useDispatch();

  const settings = useSelector((state: IAppState) => state.settings);
  const pageData = useSelector((state: IAppState) => state.pageData);

  const searchData = useSearchData();
  const onSidebarToggle = () => dispatch(toggleSidebar());

  const [menuData, setMenuData] = useState([]);

  const organizationMenuurl = ' /data/menuForOrganization.json';
  const Default = '/data/menu.json';
  const doctorurl = '/data/menuForDoctor.json';
  const role = localStorage.getItem('role');

  useEffect(() => {
    async function fetchMenuData() {
      if (role === 'hospital') {
        const result = await axios(organizationMenuurl);

        setMenuData(result.data);
      } else if (role === 'doctor') {
        const result = await axios(doctorurl);

        setMenuData(result.data);
      } else {
        const result = await axios(Default);

        setMenuData(result.data.slice(0, 9));
      }
    }

    fetchMenuData().catch((err) => console.log('Server Error', err));
  }, []);

  const nav = (
    <Navbar
      boxed={settings.boxed}
      color={settings.topbarColor}
      background={settings.topbarBg}
      orientation='horizontal'
    >
      <button className='no-style navbar-toggle d-lg-none' onClick={onSidebarToggle}>
        <span />
        <span />
        <span />
      </button>

      {/* <Search layout='vertical' data={searchData} /> */}

      {pageData.breadcrumbs ? (
        <Breadcrumbs breadcrumbs={pageData.breadcrumbs} />
      ) : (
        <div className='navbar-info-div'>
          <h3 className='nav-info-div-heading'>{pageData.title || 'Dashboard'}</h3>
          {/* <p className='nav-info-div-sub_heading'>Wednesday,12 August 2021</p> */}
        </div>
      )}
      <Actions />

      <NavLoader loaded={pageData.loaded} type={'top-bar'} />
    </Navbar>
  );

  const sideNav = (
    <Navbar
      onClickOutside={onSidebarToggle}
      opened={settings.sidebarOpened}
      color={settings.sidebarColor}
      background={settings.sidebarBg}
      orientation='vertical'
    >
      <Logo src={LogoSvg} />

      <Menu
        onCloseSidebar={onSidebarToggle}
        opened={settings.sidebarOpened}
        orientation='vertical'
        data={menuData}
      />

      {/* <AddPatient /> */}

      {/* <Menu className='assistant-menu' orientation='vertical'>
        <NavLink
          to='/vertical/settings'
          className={`link ${({ isActive }) => (isActive ? 'active' : 'inactive')}`}
          replace
        >
          <span className='link-icon icofont icofont-ui-settings' />

          <span className='link-text'>Settings</span>
        </NavLink>

        <NavLink
          to='/vertical/default-dashboard'
          className={`link ${({ isActive }) => (isActive ? 'active' : 'inactive')}`}
          replace
        >
          <span className='link-icon icofont icofont-question-square' />

          <span className='link-text'>FAQ & Support</span>
        </NavLink>
      </Menu> */}

      <NavLoader loaded={pageData.loaded} type={'nav-bar'} />
    </Navbar>
  );

  return (
    <>
      <BaseLayout orientation='vertical' nav={nav} sideNav={sideNav}>
        {children}
      </BaseLayout>
    </>
  );
};

export default VerticalLayout;
