import {
  ADD_REPORT,
  AddReportAction,
  DELETE_REPORT,
  DeleteReportAction,
  EDIT_REPORT,
  EditReportAction,
  SET_REPORT,
  SetReportAction
} from './types';
import { message } from 'antd';

import { IReport, ReportApiData } from '../../interfaces/report';
import axios from 'axios';
import { clearLocalStorage, logout } from '../auth/action';

export const setReport = (reports: IReport[]): SetReportAction => ({
  type: SET_REPORT,
  payload: reports
});

export const addReport = (report: IReport): AddReportAction => ({
  type: ADD_REPORT,
  payload: report
});

export const deleteReport = (id: string): DeleteReportAction => ({
  type: DELETE_REPORT,
  id
});

export const editReport = (report: IReport): EditReportAction => ({
  type: EDIT_REPORT,
  payload: report
});

export const fetchReports: any = (url: string, headers: any) => {
  return (dispatch) => {
    axios
      .get<{ data: ReportApiData }>(url, { headers })
      .then((res) => {
        dispatch(setReport(res.data?.data?.records));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          message.error('Token Expired: Login Again');
          dispatch(logout());
          clearLocalStorage();
          return;
        }
        message.error('Server connections error');
      });
  };
};
