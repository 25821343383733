import {
  ADD_TEST_PARAMETER,
  DELETE_TEST_PARAMETER,
  EDIT_TEST_PARAMETER,
  TestParameterActions,
  SET_TEST_PARAMETERS
} from './types';
import { ITestParameter } from '../../interfaces/testParameter';

const initialState: ITestParameter[] = [];

export function testParametersReducer(
  state: ITestParameter[] = initialState,
  action: TestParameterActions
): ITestParameter[] {
  switch (action.type) {
    case SET_TEST_PARAMETERS: {
      return [...action.payload];
    }

    case EDIT_TEST_PARAMETER: {
      const editedTestParameter = state.map((el) =>
        el.id !== action.payload.id ? el : action.payload
      );
      return [...editedTestParameter];
    }

    case ADD_TEST_PARAMETER: {
      return [{ ...action.payload }, ...state];
    }

    case DELETE_TEST_PARAMETER: {
      const testParameters = state.filter((el) => el.id !== action.id);
      return [...testParameters];
    }

    default: {
      return state;
    }
  }
}
