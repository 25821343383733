const calculateAge = (dob: string): string => {
  const today = new Date();
  const birthDate = new Date(dob);

  let ageYears = today.getFullYear() - birthDate.getFullYear();
  let ageMonths = today.getMonth() - birthDate.getMonth();
  let ageDays = today.getDate() - birthDate.getDate();

  if (ageDays < 0) {
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, birthDate.getDate());
    ageDays = Math.floor((today.getTime() - lastMonth.getTime()) / (1000 * 60 * 60 * 24));
    ageMonths--;
  }

  if (ageMonths < 0) {
    ageYears--;
    ageMonths += 12;
  }

  if (ageYears === 0) {
    if (ageMonths === 0) {
      return `${ageDays} days`;
    } else {
      return `${ageMonths} month${ageMonths > 1 ? 's' : ''}`;
    }
  } else {
    if (ageMonths === 0) {
      return `${ageYears} year${ageYears > 1 ? 's' : ''}`;
    } else {
      return `${ageYears} year${ageYears > 1 ? 's' : ''} ${ageMonths} month${
        ageMonths > 1 ? 's' : ''
      }`;
    }
  }
};

const getTodayDate = (): string => {
  const today = new Date();
  const year = today.getFullYear();
  let month = String(today.getMonth() + 1);
  let day = String(today.getDate());

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
};
const calculateBSA = (weight: string) => {
  const weightNumber = parseFloat(weight);
  let weightInGrams = weightNumber * 1000;
  if (!isNaN(weightInGrams)) {
    const rawBSA = (10.1 * Math.pow(weightInGrams, 2 / 3)) / Math.pow(10, 4);
    return parseFloat(rawBSA.toFixed(2));
  } else {
    return 0;
  }
};

const customBSA = (weight: string, userFormula: string) => {
  const weightNumber = parseFloat(weight);
  let weightInGrams = weightNumber * 1000;
  if (!isNaN(weightInGrams) && userFormula.trim() !== '') {
    try {
      const dynamicFormula = userFormula.replace(/W/g, weightInGrams.toString());
      const rawBSA = eval(dynamicFormula);
      if (typeof rawBSA === 'number' && !isNaN(rawBSA) && isFinite(rawBSA) && rawBSA >= 0) {
        return parseFloat(rawBSA.toFixed(2));
      } else {
        console.error('Invalid result from the formula:', rawBSA);
        return 0;
      }
    } catch (error) {
      console.error('Error evaluating the formula:', error);
      return 0;
    }
  } else {
    console.error('Invalid weight or empty formula');
    return 0;
  }
};

// Example usage:
const weightValue = '32';
const userDefinedFormula = '10.1 * (W ** (2/3)) / 10 ** 4';
customBSA(weightValue, userDefinedFormula);

const calculateSQRT = (weight: string) => {
  const weightNumber = parseFloat(weight);
  if (!isNaN(weightNumber)) {
    const rawSqrt = 2 * (2.9 * Math.sqrt(0.015 + Math.pow(weightNumber - 27.4, 2) / 25947.2));
    return parseFloat(rawSqrt.toFixed(2));
  } else {
    return 0;
  }
};

calculateSQRT('3');
export { calculateAge, calculateBSA, calculateSQRT, customBSA, getTodayDate };
