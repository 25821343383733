import { ISpecie } from '../interfaces/specie';
import { useDispatch, useSelector } from 'react-redux';

import * as headingsActions from '../redux/heading/actions';

import { IAppState } from '../interfaces/app-state';
import { IHeading } from '../interfaces/heading';

export function useHeading() {
  const dispatch = useDispatch();
  const headings = useSelector<IAppState, IHeading[]>((state) => state.headings);

  const editHeading = (heading: IHeading) => {
    return dispatch(headingsActions.editHeading(heading));
  };

  const addHeading = (heading: IHeading) => {
    return dispatch(headingsActions.addHeading(heading));
  };

  const deleteHeading = (id: string) => {
    return dispatch(headingsActions.deleteHeading(id));
  };

  return { headings, addHeading, editHeading, deleteHeading };
}
