import { IFormula } from '../../interfaces/formula';

export const SET_FORMULAS = '[formula] Set';
export const ADD_FORMULA = '[formula] Add';
export const EDIT_FORMULA = '[formula] Edit';
export const DELETE_FORMULA = '[formula] Delete';

export interface SetFormulaAction {
  type: typeof SET_FORMULAS;
  payload: IFormula[];
}

export interface AddFormulaAction {
  type: typeof ADD_FORMULA;
  payload: IFormula;
}

export interface EditFormulaAction {
  type: typeof EDIT_FORMULA;
  payload: IFormula;
}

export interface DeleteFormulaAction {
  type: typeof DELETE_FORMULA;
  id: string;
}

export type FormulaActions =
  | SetFormulaAction
  | AddFormulaAction
  | DeleteFormulaAction
  | EditFormulaAction;

