import { ADD_FORMULA, DELETE_FORMULA, EDIT_FORMULA, FormulaActions, SET_FORMULAS } from './types';
import { IFormula } from '../../interfaces/formula';

const initialState: IFormula[] = [];

export function formulasReducer(
  state: IFormula[] = initialState,
  action: FormulaActions
): IFormula[] {
  switch (action.type) {
    case SET_FORMULAS: {
      return [...action.payload];
    }

    case EDIT_FORMULA: {
      const editedFormula = state.map((el) => (el.id !== action.payload.id ? el : action.payload));

      return [...editedFormula];
    }

    case ADD_FORMULA: {
      return [{ ...action.payload }, ...state];
    }

    case DELETE_FORMULA: {
      const formula = state.filter((el) => el.id !== action.id);
      return [...formula];
    }

    default: {
      return state;
    }
  }
}
