import { useState } from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Table, message } from 'antd';
import { ColumnProps } from 'antd/es/table';

import { formGroupUrl } from '../../../constants/url';
import { IFormGroups } from '../../../interfaces/formGroup';
import GroupForm from './AddGroup/FormGroupForm';
import { deleteData } from './AddGroup/api';

type Props = {
  formGroups: IFormGroups[];
  onEditFormGroup?: (formGroup: IFormGroups) => void;
  onDeleteFormGroup?: (id: string) => void;
};

const FormGroupTable = ({
  formGroups,
  onEditFormGroup = () => null,
  onDeleteFormGroup = () => null
}: Props) => {
  const [formGroup, setFormGroup] = useState<IFormGroups>(null);
  const [visibility, setVisibility] = useState<boolean>(false);

  const closeModal = () => setVisibility(false);

  const handleDeleteFormGroup = async (id: string) => {
    try {
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      let res = await deleteData(formGroupUrl + `/${id}`, headers);
      if (res.status === 200) {
        message.success('Form group deleted successfully');
      }
      onDeleteFormGroup(id);
    } catch (error) {
      message.error('Failed to delete form group. Please try again.');
    }
  };
  const handleEditFormGroup = (formGroup: IFormGroups) => {
    setFormGroup(formGroup);
    setVisibility(true);
  };

  const role = localStorage.getItem('role');

  const actions = (formGroup: IFormGroups) => (
    <div className='buttons-list nowrap justify-content-center'>
      <Button
        disabled={role === 'doctor'}
        onClick={() => handleEditFormGroup(formGroup)}
        shape='circle'
        type='primary'
      >
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Popconfirm
        disabled={role === 'doctor'}
        title='Are you sure to delete this row?'
        okText='Yes'
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={() => handleDeleteFormGroup(formGroup._id)}
      >
        <Button shape='circle' danger>
          <span className='icofont icofont-ui-delete' />
        </Button>
      </Popconfirm>
    </div>
  );

  const columns: ColumnProps<IFormGroups>[] = [
    {
      key: 'title',
      dataIndex: 'title',
      title: 'Title',
      align: 'center',
      sorter: (a, b) => (a.title > b.title ? 1 : -1),
      render: (title) => <strong>{title}</strong>
    },
    {
      key: 'description',
      dataIndex: 'description',
      align: 'center',
      title: 'Description',
      render: (description) => <p>{description}</p>
    }
  ];

  if (role === 'admin') {
    columns.push({
      key: 'actions',
      title: 'Actions',
      align: 'center',
      render: actions
    });
  }

  const pagination = formGroups.length <= 10 ? false : { showSizeChanger: false };

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={formGroups}
        columns={columns}
      />

      <Modal
        visible={visibility}
        footer={null}
        onCancel={closeModal}
        title={<h3 className='title'>Update Form Group</h3>}
      >
        <GroupForm
          submitText='Update Form Group'
          onCancel={closeModal}
          onSubmit={onEditFormGroup}
          formGroups={formGroup}
        />
      </Modal>
    </>
  );
};

export default FormGroupTable;
