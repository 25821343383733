import { useState } from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Table, message } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { IDisease } from '../../../interfaces/disease';

import { getAllDiseaseURL } from '../../../constants/url';
import DiseaseForm from './AddDisease/DiseaseForm';
import { deleteData } from './AddDisease/api';

type Props = {
  diseases: IDisease[];
  onEditDisease?: (disease: IDisease) => void;
  onDeleteDisease?: (id: string) => void;
};

const DiseaseTable = ({
  diseases,
  onEditDisease = () => null,
  onDeleteDisease = () => null
}: Props) => {
  const [disease, setDisease] = useState<IDisease>(null);
  const [visibility, setVisibility] = useState<boolean>(false);

  const closeModal = () => setVisibility(false);

  const handleDeleteDisease = async (id: string) => {
    try {
      await deleteData(getAllDiseaseURL + `/${id}`);
      message.success('Disease deleted successfully');
      onDeleteDisease(id);
    } catch (error) {
      message.error('Failed to delete Disease. Please try again.');
    }
  };
  const handleEditDisease = (disease: IDisease) => {
    setDisease(disease);
    setVisibility(true);
  };

  const role = localStorage.getItem('role');

  const actions = (disease: IDisease) => (
    <div className='buttons-list nowrap justify-content-center'>
      <Button
        disabled={role === 'doctor'}
        onClick={() => handleEditDisease(disease)}
        shape='circle'
        type='primary'
      >
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Popconfirm
        disabled={role === 'doctor'}
        title='Are you sure to delete this row?'
        okText='Yes'
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={() => handleDeleteDisease(disease._id)}
      >
        <Button shape='circle' danger>
          <span className='icofont icofont-ui-delete' />
        </Button>
      </Popconfirm>
    </div>
  );
  const uniqueSpecies = Array.from(
    new Set(diseases.map((disease) => disease.organ?.specie?.name.toLowerCase()))
  );

  const uniqueOrgans = Array.from(
    new Set(diseases.map((disease) => disease.organ?.name.toLowerCase()))
  );

  const columns: ColumnProps<IDisease>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      align: 'center',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'species',
      dataIndex: 'organ',
      align: 'center',
      title: 'Specie',
      filters: uniqueSpecies.map((species) => ({ text: species, value: species })),
      onFilter: (value, record) => record.organ?.specie?.name?.toLowerCase() === value,
      render: (organ) => (organ ? <strong>{organ?.specie?.name}</strong> : null)
    },
    {
      key: 'organs',
      dataIndex: 'organ',
      align: 'center',
      title: 'Organ',
      filters: uniqueOrgans.map((organ) => ({ text: organ, value: organ })),
      onFilter: (value, record) => record.organ?.name?.toLowerCase() === value,
      render: (organ) => (organ ? <strong>{organ.name}</strong> : null)
    }
  ];

  if (role === 'admin') {
    columns.push({
      key: 'actions',
      title: 'Actions',
      align: 'center',
      render: actions
    });
  }

  const pagination = diseases.length <= 10 ? false : { showSizeChanger: false };

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={diseases}
        columns={columns}
      />

      <Modal
        open={visibility}
        footer={null}
        onCancel={closeModal}
        title={<h3 className='title'>Update Disease</h3>}
      >
        <DiseaseForm
          submitText='Update Disease'
          onCancel={closeModal}
          onSubmit={onEditDisease}
          diseases={disease}
        />
      </Modal>
    </>
  );
};

export default DiseaseTable;
