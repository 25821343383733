import {
  ADD_PATIENT,
  AddPatientAction,
  DELETE_PATIENT,
  DeletePatientAction,
  EDIT_PATIENT,
  EditPatientAction,
  SET_PATIENTS,
  SetPatientAction
} from './types';
import { message } from 'antd';

import { IPatient } from '../../interfaces/patient';
import axios from 'axios';
import { clearLocalStorage, logout } from '../auth/action';

export const setPatients = (patients: IPatient[]): SetPatientAction => ({
  type: SET_PATIENTS,
  payload: patients
});

export const addPatient = (patient: IPatient): AddPatientAction => ({
  type: ADD_PATIENT,
  payload: patient
});

export const deletePatient = (id: string): DeletePatientAction => ({
  type: DELETE_PATIENT,
  id
});

export const editPatient = (patient: IPatient): EditPatientAction => ({
  type: EDIT_PATIENT,
  payload: patient
});

export const fetchPatients: any = (url: string, headers: any) => {
  return (dispatch) => {
    axios
      .get<{ data: IPatient[] }>(url, { headers })
      .then((res) => res.data)
      .then((data) => {
        dispatch(setPatients(data.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          message.error('Token Expired: Login Again');
          dispatch(logout());
          clearLocalStorage();
          return;
        }
        message.error('Server connections error');
      });
  };
};
