import React from 'react';
import { IPageData } from '../../../interfaces/page';
import { usePageData } from '../../../hooks/usePage';
import './EmailTemplate.scss';
const pageData: IPageData = {
  title: 'Sample Report',
  fulFilled: true
};

const EmailTemplate: React.FC = () => {
  usePageData(pageData);

  return (
    <div className='email-template-container'>
      {/* Section 1: Title and Logo */}
      <div className='section header'>
        <h3>Cardiac Ultrasound Report</h3>
        <img src={require('../../../assets/img/logo2.png')} alt='Logo' />
      </div>

      {/* Section 2: Patient Information */}
      <div className='section'>
        <div className='container'>
          <div className='column'>
            <table>
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td className='user-info'>Donovan, Lyve</td>
                </tr>
                <tr>
                  <td>Birthdate:</td>
                  <td className='user-info'>01/06/2020</td>
                </tr>
                <tr>
                  <td>Patient ID:</td>
                  <td className='user-info'>374399</td>
                </tr>
                <tr>
                  <td>Gender:</td>
                  <td className='user-info'>Female</td>
                </tr>
                <tr>
                  <td>Weight:</td>
                  <td className='user-info'>4.5 kg</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='column'>
            <table>
              <tbody>
                <tr>
                  <td>Date:</td>
                  <td className='user-info'>01/06/2022</td>
                </tr>
                <tr>
                  <td>Tape</td>
                </tr>
                <tr>
                  <td>Sonographer:</td>
                  <td className='user-info'>Dr. Aslam, Muhammad Waseem</td>
                </tr>
                <tr>
                  <td>Ref. Doc.</td>
                  <td className='user-info'>Physician</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Section 3: Measurements */}
      <div className='section'>
        <div className='container'>
          <div className='column custom-gap'>
            <h4>2D</h4>
            <table>
              <tbody>
                <tr>
                  <td>Ao Diam:</td>
                  <td>1.5cm</td>
                </tr>
                <tr>
                  <td>LA Diam:</td>
                  <td>1.9cm</td>
                </tr>
                <tr>
                  <td>LA/Ao:</td>
                  <td>1.69</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='column custom-gap'>
            <h4>M-Mode</h4>
            <table>
              <tr>
                <td>IVSd:</td>
                <td>0.6cm</td>
              </tr>
              <tr>
                <td>LVIDd:</td>
                <td>2.8cm</td>
              </tr>
              <tr>
                <td>LVPWd:</td>
                <td>0.5cm</td>
              </tr>
              <tr>
                <td>IVSs:</td>
                <td>0.8cm</td>
              </tr>
              <tr>
                <td>LVIDs:</td>
                <td>1.7cm</td>
              </tr>
              <tr>
                <td>LVPWs:</td>
                <td>0.8cm</td>
              </tr>
              <tr>
                <td>EDV(Teich):</td>
                <td>30 ml</td>
              </tr>
              <tr>
                <td>ESV(Teich):</td>
                <td>9 ml</td>
              </tr>
              <tr>
                <td>EF(Teich):</td>
                <td>71%</td>
              </tr>
              <tr>
                <td>%FS:</td>
                <td>39%</td>
              </tr>
              <tr>
                <td>SV(Teich):</td>
                <td>21 ml</td>
              </tr>
              <tr>
                <td>RVIDd:</td>
                <td>0.5cm</td>
              </tr>
              <tr>
                <td>EPSS:</td>
                <td>0.1cm</td>
              </tr>
            </table>
          </div>
          <div className='column custom-gap'>
            <h4>Doppler</h4>
            <table>
              <tr>
                <td>MV E Vel:</td>
                <td>0.99 m/s</td>
              </tr>
              <tr>
                <td>MV DecT:</td>
                <td>150 ms</td>
              </tr>
              <tr>
                <td>MV A Vel:</td>
                <td>0.65 m/s</td>
              </tr>
              <tr>
                <td>MV E/A Ratio:</td>
                <td>1.53</td>
              </tr>
              <tr>
                <td>IVRT:</td>
                <td>67 ms</td>
              </tr>
              <tr>
                <td>VSD Vmax:</td>
                <td>4.88 m/s</td>
              </tr>
              <tr>
                <td>VSD maxPG:</td>
                <td>95.60 mmHg</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      {/* Section 4: Images Grid */}
      <div className='section'>
        <h4>Images</h4>
        <div className='img-grid'>
          <img src={require('../../../assets/img/scan.png')} alt='scan' />
          <img src={require('../../../assets/img/scan2.png')} alt='scan' />
        </div>
      </div>

      {/* Section 5: Referral Diagnosis Grid */}
      <div className='section'>
        <h4>Referral Diagnosis</h4>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque enim repudiandae quia
          atque expedita tenetur corporis temporibus, perspiciatis corrupti? Omnis suscipit enim
          deleniti doloribus libero veniam tempora voluptatem porro nobis laudantium cum, animi
          itaque debitis eos aliquid excepturi numquam facilis?
        </p>
      </div>

      {/* Section 6: Findings Grid */}
      <div className='section'>
        <h4>Findings</h4>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facilis rerum vitae laudantium
          velit, error voluptatum cumque reiciendis consequatur minima culpa alias, aliquam ea
          inventore deleniti? Veritatis fugit magni explicabo fugiat quia autem, sint quasi,
          quisquam architecto ipsam consequatur voluptatum nobis amet? Ea cupiditate temporibus
          praesentium sed ipsum reiciendis maxime dolorum!
        </p>
      </div>

      {/* Section 7: Conclusions */}
      <div className='section'>
        <h4>Conclusions</h4>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium amet, tenetur
          molestias quos unde eum ea temporibus obcaecati optio velit! Nemo illum voluptate sed
          tempore vel numquam corrupti! Debitis fugit, magnam esse unde aliquid saepe asperiores
          adipisci recusandae minima mollitia, error doloribus deleniti non excepturi ea cumque ab
          facere quaerat? Rem exercitationem fugiat rerum sed fugit! Velit saepe eligendi ipsum!
        </p>
      </div>
    </div>
  );
};

export default EmailTemplate;
