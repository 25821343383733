import {
  ADD_HOSPITAL,
  AddHospitalAction,
  DELETE_HOSPITAL,
  DeleteHospitalAction,
  EDIT_HOSPITAL,
  EditHospitalAction,
  SET_HOSPITALS,
  SetHospitalAction
} from './types';

import { IHospital } from '../../interfaces/hospital';
import axios from 'axios';

export const setHospitals = (hospitals: IHospital[]): SetHospitalAction => ({
  type: SET_HOSPITALS,
  payload: hospitals
});

export const addHospital = (hospital: IHospital): AddHospitalAction => ({
  type: ADD_HOSPITAL,
  payload: hospital
});

export const deleteHospital = (id: string): DeleteHospitalAction => ({
  type: DELETE_HOSPITAL,
  id
});

export const editHospital = (hospital: IHospital): EditHospitalAction => ({
  type: EDIT_HOSPITAL,
  payload: hospital
});

export const fetchHospitals: any = (url: string) => {
  return (dispatch) => {
    axios
      .get<IHospital[]>(url)
      .then((res) => res.data)
      .then((data) => {
        dispatch(setHospitals(data));
      })
      .catch((err) => console.error('Server connections error'));
  };
};

// export const fetchHospitals: any = (url: string) => {
//   return (dispatch) => {
//     axios
//       .get<{ data: IHospital[] }>(url)
//       .then((res) => res.data)
//       .then((data) => {
//         dispatch(setHospitals(data.data));
//       })
//       .catch((err) => console.error('Server connections error'));
//   };
// };
