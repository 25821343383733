import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../redux/forms/actions';

import { IAppState } from '../interfaces/app-state';
import { IForms } from '../interfaces/forms';

export function useForms() {
  const dispatch = useDispatch();
  const forms = useSelector<IAppState, IForms[]>((state) => state.forms);

  const editForm = (form: IForms) => {
    return dispatch(actions.editForm(form));
  };

  const addForm = (form: IForms) => {
    return dispatch(actions.addForm(form));
  };

  const deleteForm = (id: string) => {
    return dispatch(actions.deleteForm(id));
  };

  return { forms, addForm, editForm, deleteForm };
}
