import { usePageData } from '../../../hooks/usePage';

import FormulaTable from './FormulaTable';

import { IPageData } from '../../../interfaces/page';
import { useFormula } from '../../../hooks/useFormula';
import AddFormula from './AddFormula/AddFormula';

const pageData: IPageData = {
  title: 'Formulas',
  fulFilled: true
};

const OrganPage = () => {
  const { formulas, editFormula, deleteFormula } = useFormula();
  usePageData(pageData);
  return (
    <>
      <header className='page-header'>
        <AddFormula />
      </header>
      <FormulaTable
        onDeleteFormulas={deleteFormula}
        onEditFormulas={editFormula}
        formulas={formulas}
      />
    </>
  );
};

export default OrganPage;
