import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';
import PublicLayout from '../../layout/public/Public';
import './sessions.scss';

const ThankYou = () => {
  return (
    <PublicLayout bgImg={`${window.origin}/content/register-login-bg.png`}>
      <Result status='success' title='Your registration is successful. Please proceed to login.' />
      <Link to='/log-in'>
        <Button
          type='primary'
          style={{
            width: '100%',
            marginTop: '2rem'
          }}
        >
          Login
        </Button>
      </Link>
    </PublicLayout>
  );
};

export default ThankYou;
