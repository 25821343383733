import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../redux/formGroup/actions';

import { IAppState } from '../interfaces/app-state';
import { IFormGroups } from '../interfaces/formGroup';

export function useFormGroup() {
  const dispatch = useDispatch();
  const formGroups = useSelector<IAppState, IFormGroups[]>((state) => state.formGroups);

  const editFormGroup = (formGroup: IFormGroups) => {
    return dispatch(actions.editFormGroup(formGroup));
  };

  const addFormGroup = (formGroup: IFormGroups) => {
    return dispatch(actions.addFormGroup(formGroup));
  };

  const deleteFormGroup = (id: string) => {
    return dispatch(actions.deleteFormGroup(id));
  };

  return { formGroups, addFormGroup, editFormGroup, deleteFormGroup };
}
