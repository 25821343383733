import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { FloatButton } from 'antd';
import DiseaseForm from './DiseaseForm';
import { useDisease } from '../../../../hooks/useDisease';

import { IDisease } from '../../../../interfaces/disease';

const AddDisease = () => {
  const { addDisease } = useDisease();
  const [visible, setVisible] = useState(false);

  const handleClick = () => setVisible(!visible);

  const closeModal = () => setVisible(false);

  const handleAddDisease = (disease: IDisease) => {
    addDisease(disease);
    closeModal();
  };

  return (
    <div className='add-disease'>
      <FloatButton
        className='float_button'
        onClick={handleClick}
        icon={<i className='icofont-ui-add'></i>}
      />
      <Modal
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<h3 className='title'>Add Disease</h3>}
      >
        <DiseaseForm onCancel={closeModal} onSubmit={handleAddDisease} />
      </Modal>
    </div>
  );
};

export default AddDisease;
