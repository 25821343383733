import { message } from 'antd';
import axios from 'axios';
import { FormGroupApiData, IFormGroups } from '../../interfaces/formGroup';
import { clearLocalStorage, logout } from '../auth/action';
import {
  ADD_FORMS_GROUP,
  AddFormGroupsAction,
  DELETE_FORMS_GROUP,
  DeleteFormGroupsAction,
  EDIT_FORMS_GROUP,
  EditFormGroupsAction,
  SET_FORMS_GROUP,
  SetFormGroupsAction
} from './types';

export const setFormGroups = (formGroups: IFormGroups[]): SetFormGroupsAction => ({
  type: SET_FORMS_GROUP,
  payload: formGroups
});

export const addFormGroup = (formGroup: IFormGroups): AddFormGroupsAction => ({
  type: ADD_FORMS_GROUP,
  payload: formGroup
});

export const deleteFormGroup = (id: string): DeleteFormGroupsAction => ({
  type: DELETE_FORMS_GROUP,
  id
});

export const editFormGroup = (formGroup: IFormGroups): EditFormGroupsAction => ({
  type: EDIT_FORMS_GROUP,
  payload: formGroup
});

export const fetchFormGroups: any = (url: string, headers: any) => {
  return (dispatch: any) => {
    axios
      .get<{ data: FormGroupApiData }>(url, { headers })
      .then((res) => {
        dispatch(setFormGroups(res.data?.data?.records));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          message.error('Token Expired: Login Again');
          dispatch(logout());
          clearLocalStorage();
          return;
        }
        message.error('Server connections error');
      });
  };
};
