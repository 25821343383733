import { IForms } from '../../interfaces/forms';
import { ADD_FORMS, DELETE_FORMS, EDIT_FORMS, FormsActions, SET_FORMS } from './types';

const initialState: IForms[] = [];

export function formsReducer(state: IForms[] = initialState, action: FormsActions): IForms[] {
  switch (action.type) {
    case SET_FORMS: {
      return [...action.payload];
    }

    case EDIT_FORMS: {
      const editedForms = state.map((el) => (el._id !== action.payload._id ? el : action.payload));

      return [...editedForms];
    }

    case ADD_FORMS: {
      return [{ ...action.payload }, ...state];
    }

    case DELETE_FORMS: {
      const forms = state.filter((el) => el._id !== action.id);
      return [...forms];
    }

    default: {
      return state;
    }
  }
}
