import React, { useState, useRef } from 'react';
import './form.scss';

interface ImageFile {
  id: number;
  url: string;
  name: string;
}

interface MultipleImgUploadProps {
  onImagesChange: (files: File[] | null) => void;
  initialImages?: File[];
}

const MultipleImgUpload: React.FC<MultipleImgUploadProps> = ({ onImagesChange, initialImages }) => {
  const [images, setImages] = useState<ImageFile[]>([]);
  const urlPromoRef = useRef<HTMLInputElement>(null);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      const updatedImages = filesArray.map((file) => ({
        id: Math.floor(Math.random() * 10000),
        url: URL.createObjectURL(file),
        name: file.name
      }));
      setImages((prevImages) => [...prevImages, ...updatedImages]);
      onImagesChange(filesArray);
    }
  };

  const removeImage = (id: number, index) => {
    const updatedImages = images.filter((image) => image.id !== id);
    setImages(updatedImages);
    const deleteImage = initialImages.filter((_, initialImageIndex) => initialImageIndex !== index);
    onImagesChange(deleteImage);
  };

  return (
    <div className='MultipleImgUpload'>
      <label htmlFor='fileInput' className='fileInputButton'>
        <i className='icofont-ui-add'> </i> Add Images
        <input
          type='file'
          accept='.jpeg, .png, .jpg, .gif'
          multiple
          onChange={onInputChange}
          style={{ display: 'none' }}
          id='fileInput'
          ref={urlPromoRef}
        />
      </label>

      <div className='image-preview'>
        {images.map((image, index) => (
          <div key={image.id} className='image-container'>
            <img src={image.url} alt={`${image.id}`} className='preview-image' />
            <button className='delete-button' onClick={() => removeImage(image.id, index)}>
              x
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultipleImgUpload;
