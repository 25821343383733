import {
  ADD_ORGAN,
  AddOrganAction,
  DELETE_ORGAN,
  DeleteOrganAction,
  EDIT_ORGAN,
  EditOrganAction,
  SET_ORGANS,
  SetOrganAction
} from './types';
import { message } from 'antd';

import { IOrgan } from '../../interfaces/organ';
import axios from 'axios';
import { clearLocalStorage, logout } from '../auth/action';

export const setOrgans = (organs: IOrgan[]): SetOrganAction => ({
  type: SET_ORGANS,
  payload: organs
});

export const addOrgan = (organ: IOrgan): AddOrganAction => ({
  type: ADD_ORGAN,
  payload: organ
});

export const deleteOrgan = (_id: string): DeleteOrganAction => ({
  type: DELETE_ORGAN,
  _id
});

export const editOrgan = (organ: IOrgan): EditOrganAction => ({
  type: EDIT_ORGAN,
  payload: organ
});

export const fetchOrgan: any = (url: string, headers: any) => {
  return (dispatch) => {
    axios
      .get<{ data: IOrgan[] }>(url, { headers })
      .then((res) => res.data)
      .then((data) => {
        dispatch(setOrgans(data.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          message.error('Token Expired: Login Again');
          dispatch(logout());
          clearLocalStorage();
          return;
        }
        message.error('Server connections error');
      });
  };
};
