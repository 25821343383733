import {
  ADD_HOSPITAL,
  DELETE_HOSPITAL,
  EDIT_HOSPITAL,
  HospitalActions,
  SET_HOSPITALS
} from './types';
import { IHospital } from '../../interfaces/hospital';

const initialState: IHospital[] = [];

export function hospitalsReducer(
  state: IHospital[] = initialState,
  action: HospitalActions
): IHospital[] {
  switch (action.type) {
    case SET_HOSPITALS: {
      return [...action.payload];
    }

    case EDIT_HOSPITAL: {
      const editedHospitals = state.map((el) =>
        el.id !== action.payload.id ? el : action.payload
      );
      return [...editedHospitals];
    }

    case ADD_HOSPITAL: {
      return [{ ...action.payload }, ...state];
    }

    case DELETE_HOSPITAL: {
      const hospitals = state.filter((el) => el.id !== action.id);
      return [...hospitals];
    }

    default: {
      return state;
    }
  }
}
