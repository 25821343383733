import React, { useState } from 'react';
import { Button, Modal } from 'antd';

import TestParameterForm from './TestParameterForm';
import { useTestParameter } from '../../../../hooks/useTestParameter';

import { ITestParameter } from '../../../../interfaces/testParameter';

const AddTestParameter = () => {
  const { addTestParameter } = useTestParameter();
  const [visible, setVisible] = useState(false);

  const handleClick = () => setVisible(!visible);

  const closeModal = () => setVisible(false);

  const handleAddTestParameter = (testParameter: ITestParameter) => {
    addTestParameter(testParameter);
    closeModal();
  };

  return (
    <div className='add-test-parameter'>
      <Button type='primary' onClick={handleClick}>
        <span className='icofont icofont-plus mr-2' style={{ fontSize: '1.3em' }} />
        Add Test Parameter
      </Button>
      <Modal
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<h3 className='title'>Add Test Parameter</h3>}
      >
        <TestParameterForm onCancel={closeModal} onSubmit={handleAddTestParameter} />
      </Modal>
    </div>
  );
};

export default AddTestParameter;
