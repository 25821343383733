import { IReport } from '../../interfaces/report';

export const SET_REPORT = '[report] Set';
export const ADD_REPORT = '[report] Add';
export const EDIT_REPORT = '[report] Edit';
export const DELETE_REPORT = '[report] Delete';

export interface SetReportAction {
  type: typeof SET_REPORT;
  payload: IReport[];
}

export interface AddReportAction {
  type: typeof ADD_REPORT;
  payload: IReport;
}

export interface EditReportAction {
  type: typeof EDIT_REPORT;
  payload: IReport;
}

export interface DeleteReportAction {
  type: typeof DELETE_REPORT;
  id: string;
}

export type ReportActions =
  | SetReportAction
  | AddReportAction
  | DeleteReportAction
  | EditReportAction;
