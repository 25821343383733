import { ADD_REPORT, DELETE_REPORT, EDIT_REPORT, ReportActions, SET_REPORT } from './types';
import { IReport } from '../../interfaces/report';

const initialState: IReport[] = [];

export function reportReducer(state: IReport[] = initialState, action: ReportActions): IReport[] {
  switch (action.type) {
    case SET_REPORT: {
      return [...action.payload];
    }

    case EDIT_REPORT: {
      const editedReports = state.map((el) =>
        el._id !== action.payload._id ? el : action.payload
      );

      return [...editedReports];
    }

    case ADD_REPORT: {
      return [{ ...action.payload }, ...state];
    }

    case DELETE_REPORT: {
      const reports = state.filter((el) => el._id !== action.id);
      return [...reports];
    }

    default: {
      return state;
    }
  }
}
