import { FloatButton } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAllDoctorURL, getAllReportURL } from '../../../constants/url';
import { usePageData } from '../../../hooks/usePage';
import { IRange } from '../../../interfaces/forms';
import { IPageData } from '../../../interfaces/page';
import './EmailTemplate.scss';
import './Print.scss';
import { getReport } from './api';
const pageData: IPageData = {
  title: 'Report',
  fulFilled: true
};

const EmailTemplate: React.FC = () => {
  const [report, setReport] = useState({
    caseNumber: '',
    doctorId: {
      _id: '',
      fullName: ''
    },
    findings: [],
    formTitle: '',
    hospitalId: '',
    organId: {},
    patientAge: '',
    patientBsa: '',
    patientDoctorRef: '',
    patientId: {
      age: '',
      patient_id: '',
      breed: '',
      case_number: '',
      dob: '',
      name: '',
      sex: ''
    },
    patientWeight: '',
    reportId: '',
    reportImages: [],
    sections: [],
    specieId: {},
    createdAt: '',
    conclusionTemplate: ''
  });
  const [viewReport, setViewReport] = useState(false);
  const [doctorLogo, setDoctorLogo] = useState(null);
  const [formatedDate, setFormatedDate] = useState('');
  const [pageData, setPageData] = useState<IPageData>({
    title: 'Report',
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Reports',
        route: 'reports'
      },
      {
        title: 'View Report'
      }
    ]
  });

  usePageData(pageData);
  const { id } = useParams();
  const doctor_id = localStorage.getItem('doctor_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const reportRes = await getReport(`${getAllReportURL}/${id}`);
        setReport(reportRes?.data?.data);
        setPageData((prevData) => ({
          ...prevData,
          breadcrumbs: [
            ...prevData.breadcrumbs.slice(0, -1),
            {
              title:
                reportRes.data.data?.formTitle ||
                prevData.breadcrumbs[prevData.breadcrumbs.length - 1].title
            }
          ]
        }));
        setViewReport(true);

        const response = await axios.get(`${getAllDoctorURL}/${doctor_id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setDoctorLogo(response?.data?.data);
      } catch (error) {
        console.log('Failed to fetch data');
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (report?.createdAt) {
      const date = new Date(report.createdAt);
      const newDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }).format(date);
      setFormatedDate(newDate);
    }
  }, [report, setReport]);

  const printPage = () => {
    // var printContent = document.getElementById('print-content').innerHTML;
    // var originalContent = document.body.innerHTML;
    // document.body.innerHTML = printContent;
    // window.print();
    // document.body.innerHTML = originalContent;

    const printWindow = window.open('', '', 'height=1000,width=1200');

    if (printWindow) {
      printWindow.document.write(`<html><head>
      <style>
      :root {
        --main-color: main-palette(500);
        --main-bg: #fff;
        --boxed-width: 1140px;
        font-family: 'Lato', sans-serif;
      }
      
      .email-template-container h4 {
        text-decoration: underline;
      }
      
      .header {
        
        display: flex;
      
        justify-content: space-between;
      }
      .header h3 {
        color: #336cfb;
        text-transform: capitalize;
        font-size: 2rem;
      }
       h5 {
        font-size: 1rem;
  font-weight: bold;
        text-transform: capitalize;
      }
      .head_image_logo{
         display: flex;
        justify-content: space-between;
          gap: 1rem;
      }
      .report_logo {
        height: 5rem;
        width: 5rem;
    object-fit: contain;
      }
      
      .user-info {
        font-size: 1rem;
        font-weight: bold;
      }
      
      .custom-gap {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
      
      .section {
        padding: 1rem;
      }
      .section:not(:last-child) {
        border-bottom: 1px solid lightgray;
      }
      
      .temp-container {
        display: flex;
         justify-content: flex-start;
  padding: 1rem 0;
  flex-wrap: wrap;
  gap: 2rem;
      }

      .info-container {
        justify-content: space-between;
      }
      
      .column {
        flex: none;
      }

      .report-container .column{
        flex: 1 1 30%;
        max-width: 100%;
      }

      .report-container .column td{
        margin-bottom:8px;
      }
      
      .img-grid {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        max-width: 100%;
        justify-content: center;
        align-items: center;
      }
      
      .table_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4rem;
      }
      
      .float_button {
        display: none !important;
      }
.table_header_title{
   display: flex;
   justify-content: flex-start;
   align-items: center;
   text-align: left;
}
.table_heading:first-child {
  width: 50%;
}
.table_heading {
  margin: 0;
  max-height: 4rem;
  font-weight: bold;
  font-size: 1.15rem;
  width: 28%;
}
.heading_custom_width{
  width: 45%;
  
}
.range_vange {
  width: 36px;
}

.table_row_gap {
  display: flex;
  justify-content: space-between;
  width:100% !important; 
  font-size: 12px;
      border-bottom: 2px solid #4b4848;
  
}
.ranges-table {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  width: 100% !important;
}
.ranges_setting {
  width: 60% !important;
}
.condition_setting {
  width: 40% !important;
}
.values_setting {
  width: 10% !important;
}
.investigation_setting{
  display:flex;
  align-items:center;
}
.table_row_gap td:first-child {
  width:25%;
  
}
.result_setting{
  width:35%;
      align-items: center;
    display: flex;
    justify-content: center;
}
.table_row_gap td {
  margin: 0;
  align-items: center;
  font-size:14px;
  
  background: #faf7f7;
  border-bottom: 1px solid white;
}

.rangeValue:first-child {
  padding-left: 0;

  border: none;
}
.rangeValue {
  padding: 0 0.3em 0;
  border-left: 1px solid white;
  
}


    
.rangeValue:last-child {
  
  padding-right: 0;
 
}
.ref_value_width{
  display: flex;
  justify-content:center;
  
width:40% !important;

}
.section_title_div{
      display: flex;
       margin-bottom: 12px;
    flex-direction: row;
    justify-content: space-between;
        border-bottom: 2px solid black;
}
.section_title_top{
  display: flex;
   
    flex-direction: row;
    
}

.section_title_bot{
  display: flex;
   justify-content:center;
    width:35%;
    gap:1rem;
}
.report_heading {
        color: #336cfb;
        text-transform: capitalize;
          text-wrap: wrap;
        font-size: 1.25rem !important;
      }
       @media (min-width: 950px) {
    .temp-container{
      gap: 1rem;
    }
    .column {
      flex: 1 1 30%;
      max-width: 30%;
      }
      .report_heading {
        font-size: 1.75rem !important;
      }
  }
      </style>
      </head><body>`);
      printWindow.document.write(document.getElementById('print-content').innerHTML);
      printWindow.document.write('</body></html>');

      const logoImage = new Image();
      logoImage.src = require('../../../assets/img/logo2.png');

      logoImage.onload = function () {
        printWindow.print();
        printWindow.document.close();
      };

      // printWindow.print();
      // printWindow.document.close();
    } else {
      console.error('Print window is not available.');
    }
  };
  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  const renderStandardFields = (standard: string, ranges: IRange[]) => {
    const groupedRanges: { [title: string]: IRange[] } = {};
    ranges.forEach((range) => {
      if (range.title) {
        if (!groupedRanges[range.title]) {
          groupedRanges[range.title] = [];
        }
        groupedRanges[range.title].push(range);
      }
    });

    return (
      <React.Fragment key={standard}>
        {groupedRanges[standard]
          ?.filter((rec) => rec.rangeValue !== undefined && rec.rangeValue !== '')
          .sort((a, b) => parseFloat(a.rangeValue) - parseFloat(b.rangeValue))
          .map((rec, index) => (
            <React.Fragment key={index}>
              {rec?.rangeValue && (
                <div style={{ gap: '0.5rem' }} className='d-flex'>
                  <strong>{rec.title}</strong>
                  <div className=''>
                    <strong>{rec.condition}</strong>
                    <span>{rec.rangeValue}</span>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  };

  return (
    viewReport && (
      <>
        <div className='email-template-container' id='print-content'>
          <div className='section header'>
            <h3 className='report_heading'>{report?.formTitle}</h3>
            {doctorLogo?.logoMediaId?.url && (
              <div className='head_image_logo d-flex  '>
                <img className='report_logo' src={doctorLogo?.logoMediaId?.url} alt='Doc Logo' />
                <img
                  className='ml-3 report_logo'
                  src={require('../../../assets/img/logo2.png')}
                  alt='Brand Logo'
                />
              </div>
            )}
          </div>

          <div className='section'>
            <div className='temp-container info-container'>
              <div className='column'>
                <table>
                  <tbody>
                    <tr className='table_row'>
                      <td>Name:</td>
                      <td className='user-info'> {report?.patientId?.name}</td>
                    </tr>
                    <tr className='table_row'>
                      <td>Age:</td>
                      <td className='user-info'> {report?.patientAge}</td>
                    </tr>
                    <tr className='table_row'>
                      <td>Patient ID:</td>
                      <td className='user-info'> {report?.patientId?.patient_id}</td>
                    </tr>
                    <tr className='table_row'>
                      <td>Gender:</td>
                      <td className='user-info'>
                        {report?.patientId?.sex && report?.patientId?.sex === 'M'
                          ? 'Male'
                          : report?.patientId?.sex === 'F'
                          ? 'Female'
                          : 'Other'}
                      </td>
                    </tr>
                    <tr className='table_row'>
                      <td>Weight:</td>
                      <td className='user-info'>{`${report?.patientWeight} kg`}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='column'>
                <table>
                  <tbody>
                    <tr className='table_row'>
                      <td>Date:</td>
                      <td className='user-info'>{formatedDate}</td>
                    </tr>
                    <tr className='table_row'>
                      <td>Breed</td>
                      <td className='user-info'>{report?.patientId?.breed}</td>
                    </tr>
                    <tr className='table_row'>
                      <td>Sonographer:</td>
                      <td className='user-info'>{report?.doctorId?.fullName}</td>
                    </tr>
                    <tr className='table_row'>
                      <td>Ref. Doc.</td>
                      <td className='user-info'>
                        {report?.patientDoctorRef && report?.patientDoctorRef !== 'undefined'
                          ? report?.patientDoctorRef
                          : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className='section'>
            <div className='report-container'>
              <table className='column custom-gap' key='1'>
                <thead>
                  <tr className='d-flex text-start table_header_title'>
                    <th className='table_heading'>Investigations</th>
                    <th className='table_heading'>Result</th>
                    <th className='table_heading heading_custom_width '>Ref. values</th>
                  </tr>
                </thead>
                <tbody>
                  {report?.sections?.map((section) => (
                    <React.Fragment key={section._id}>
                      {section.fields.some((field) => field.value !== '') && (
                        <tr className='w-100 d-flex section_title_div'>
                          <th className='w-80 section_title_top'>
                            <strong>{section?.title}</strong>
                          </th>
                        </tr>
                      )}

                      {section.fields
                        .filter((field) => field.value !== '')
                        .map((field) => (
                          <tr key={field._id} className='table_row_gap'>
                            <td className='investigation_setting'>{field.title}</td>
                            <td className='result_setting'>
                              <strong>
                                {/^\d+$/.test(field.value)
                                  ? field.value
                                  : /^\d+(\.\d+)?$/.test(field.value)
                                  ? parseFloat(field.value).toFixed(2)
                                  : field.value}
                              </strong>
                            </td>

                            <td className='d-flex ref_value_width'>
                              <table className='ranges-table '>
                                <tbody>
                                  {field.ranges.map((range, index) => (
                                    <tr key={index}>
                                      <td className='ranges_setting'>{range?.title}</td>
                                      <td className=''>{range?.range}</td>
                                      <td className='condition_setting '>
                                        <strong>{range?.condition}</strong>
                                        <span
                                          className={
                                            /^\d+(\.\d+)?$/.test(range.value) ? 'range_vange' : ''
                                          }
                                        >
                                          {/^\d+$/.test(range.value)
                                            ? range.value
                                            : /^\d+(\.\d+)?$/.test(range.value)
                                            ? parseFloat(range.value).toFixed(1)
                                            : '' + range.value}
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Section 4: Images Grid */}
          {report?.reportImages.length !== 0 && (
            <div className='section'>
              <h5>Images</h5>
              <div className='img-grid'>
                {report?.reportImages?.map((image) => (
                  <img key={image?._id} src={image?.url} alt='scan' style={{ height: '200px' }} />
                ))}
              </div>
            </div>
          )}

          {/* Section 6: Findings Grid */}
          {report?.findings.length !== 0 && (
            <div className='section findings_sections'>
              <h5>Findings</h5>
              {report?.findings?.map((finding) => (
                <>
                  <h6>{finding?.title}</h6>
                  {finding?.descriptions?.map((desc, index) => (
                    <React.Fragment key={desc._id}>
                      <span>{desc.description}</span>
                      <b>{index !== finding?.descriptions?.length - 1 ? ', ' : '.'}</b>
                    </React.Fragment>
                  ))}
                </>
              ))}
            </div>
          )}

          {/* Section 7: Conclusions */}
          {report?.conclusionTemplate && (
            <div className='section'>
              <h5>Conclusions</h5>
              <div dangerouslySetInnerHTML={{ __html: report?.conclusionTemplate }} />
            </div>
          )}
          <FloatButton className='float_button' onClick={printPage} />
        </div>
      </>
    )
  );
};

export default EmailTemplate;
