import {
  ADD_FORMS,
  AddFormsAction,
  DELETE_FORMS,
  DeleteFormsAction,
  EDIT_FORMS,
  EditFormsAction,
  SET_FORMS,
  SetFormsAction
} from './types';
import { message } from 'antd';

import { Apidata, IForms } from '../../interfaces/forms';
import axios from 'axios';
import { clearLocalStorage, logout } from '../auth/action';

export const setForms = (forms: IForms[]): SetFormsAction => ({
  type: SET_FORMS,
  payload: forms
});

export const addForm = (form: IForms): AddFormsAction => ({
  type: ADD_FORMS,
  payload: form
});

export const deleteForm = (id: string): DeleteFormsAction => ({
  type: DELETE_FORMS,
  id
});

export const editForm = (form: IForms): EditFormsAction => ({
  type: EDIT_FORMS,
  payload: form
});

export const fetchForms: any = (url: string, headers: any) => {
  return (dispatch) => {
    axios
      .get<{ data: Apidata }>(url, { headers })
      .then((res) => {
        dispatch(setForms(res.data?.data?.records));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          message.error('Token Expired: Login Again');
          dispatch(logout());
          clearLocalStorage();
          return;
        }
        message.error('Server connections error');
      });
  };
};
