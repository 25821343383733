import ConfigProvider from 'antd/es/config-provider';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import { useHideLoader } from './hooks/useHideLoader';
import VerticalLayout from './layout/vertical/Vertical';
import DashboardPage from './pages/dashboards/dashboard/Dashboard';
import SignIn from './pages/sessions/Sign-in';
import { defaultRoutes, doctorRoute, organization, sessionRoutes } from './routing';

const LayoutRoutes = ({ routes, layout = '' }) => (
  <Routes>
    {routes.map((route, index) => (
      <Route key={index} path={`/${route.path}`} element={<route.component />} />
    ))}

    <Route path='*' element={<DashboardPage />} />
  </Routes>
);

const LayoutRoutesAuth = ({ routes, layout = 'public' }) => (
  <Routes>
    {routes.map((route, index) => (
      <Route key={index} path={`/${route.path}`} element={<route.component />} />
    ))}

    <Route path='*' element={<SignIn />} />
  </Routes>
);

const DefaultRoutes = ({ layout }) => {
  const role = localStorage.getItem('role');

  if (role === 'hospital') {
    return <LayoutRoutes routes={organization} layout={layout} />;
  } else if (role === 'doctor') {
    return <LayoutRoutes routes={doctorRoute} layout={layout} />;
  } else if (role === 'admin') {
    return <LayoutRoutes routes={defaultRoutes} layout={layout} />;
  }
  return <LayoutRoutes routes={sessionRoutes} layout={layout} />;
};

const SessionRoutes = () => <LayoutRoutesAuth routes={sessionRoutes} layout='public' />;

const App = () => {
  const isAuthenticated = useSelector(
    (state: { auth: { authenticated: boolean } }) => state.auth?.authenticated
  );

  useHideLoader();

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "'Lato', sans-serif"
        }
      }}
    >
      <Routes>
        {isAuthenticated ? (
          <Route
            path='/*'
            element={
              <VerticalLayout>
                <DefaultRoutes layout='vertical' />
              </VerticalLayout>
            }
          />
        ) : (
          <Route path='/*' element={<SessionRoutes />} />
        )}
      </Routes>
    </ConfigProvider>
  );
};

export default App;
