import React, { useState } from 'react';
import { Button, Modal } from 'antd';

import HeadingForm from './HeadingForm';
import { useSpecies } from '../../../../hooks/useSpecie';

import { ISpecie } from '../../../../interfaces/specie';
import { IHeading } from '../../../../interfaces/heading';
import { useHeading } from '../../../../hooks/useHeading';

const AddSpecie = () => {
  const { addHeading } = useHeading();
  const [visible, setVisible] = useState(false);

  const handleClick = () => setVisible(!visible);

  const closeModal = () => setVisible(false);

  const handleAddHeading = (heading: IHeading) => {
    addHeading(heading);
    closeModal();
  };

  return (
    <div className='add-specie'>
      <Button type='primary' onClick={handleClick}>
        <span className='icofont icofont-plus mr-2' style={{ fontSize: '1.3em' }} />
        Add Heading
      </Button>
      <Modal
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<h3 className='title'>Add Heading</h3>}
      >
        <HeadingForm onCancel={closeModal} onSubmit={handleAddHeading} />
      </Modal>
    </div>
  );
};

export default AddSpecie;
