import { Button, Input, Select, Spin, message } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { hasErrorFactory } from '../../../../utils/hasError';

import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getAllDiseaseURL, getOrganBySpecieURL } from '../../../../constants/url';
import { useSpecies } from '../../../../hooks/useSpecie';
import { IDisease } from '../../../../interfaces/disease';
import { editDisease } from '../../../../redux/disease/actions';
import { getOrganBySpecie } from '../../form/api';
import { addData, updateData } from './api';

import { LoadingOutlined } from '@ant-design/icons';

type Props = {
  onSubmit: (Disease: any) => void;
  onCancel: () => void;
  diseases?: IDisease;
  submitText?: string;
};

  const modules = {
      toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        ["blockquote"],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['link'],
        ['clean'],
      ],
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    }
const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'color', 'background', 'clean'
];
const defaultSubmitText = 'Add Disease';
const emptyDisease = {
  name: null,
  organ: {
    _id: null,
    name: null,
    specie: {
      _id: null,
      name: null
    }
  },
  _id: null,
  conclusionTemplate: null
};

const DiseaseScheme = Yup.object({
  name: Yup.string().required(),
  organ: Yup.mixed()
});

const DiseaseForm = ({
  submitText = defaultSubmitText,
  diseases = emptyDisease,
  onSubmit,
  onCancel
}: Props) => {
  const [loading, setLoading] = useState(false);

  const { setFieldValue, handleSubmit, setValues, handleBlur, touched, values, errors, isValid } =
    useFormik<IDisease>({
      validationSchema: DiseaseScheme,
      initialValues: diseases,
      onSubmit: async (values: any) => {
        try {
          setLoading(true);

          if (submitText === 'Update Disease') {
            let updatedDisease = {
              ...values,
              organ: values.organ._id ? values.organ._id : values.organ
            };
            let res = await updateData(getAllDiseaseURL + `/${values._id}`, updatedDisease);
            editDisease(res?.data?.data);
            if (res?.status === 201) {
              message.success('Disease updated successfully');
            }
            onSubmit(res?.data?.data);
          } else {
            let newDisease = {
              ...values,
              organ: values?.organ?._id ? values?.organ?._id : ''
            };
            let res = await addData(getAllDiseaseURL, newDisease);
            message.success('Disease created successfully');
            onSubmit(res?.data?.data);
          }
          onCancel();
        } catch (error) {
          console.error('An error occurred:', error);
          message.error('An error occurred while processing your request');
        } finally {
          setLoading(false);
        }
      }
    });
  useEffect(() => {
    setValues(diseases);
  }, [diseases]);

  const hasError = hasErrorFactory(touched, errors);

  const handleCancel = () => {
    onCancel();
  };
  interface Species {
    id: string;
    name: string;
  }
  interface Organ {
    id: string;
    name: string;
  }
  const [selectedSpecies, setSelectedSpecies] = useState<Species | null>(null);
  const [selectedOrgan, setSelectedOrgan] = useState<Organ | null>(null);

  const [organList, setOrganList] = useState([]);
  const { species } = useSpecies();
  const handleSpeciesChange = async (value, option) => {
    setSelectedSpecies(option ? { id: value, name: option?.children } : null);

    if (option) {
      const ids = [value];
      try {
        const response = await getOrganBySpecie(getOrganBySpecieURL + '', { ids });
        setOrganList(response?.data?.data[0]);
      } catch (error) {
        console.error('Error fetching organs:', error);
      }
      setFieldValue('organ.specie._id', value);
      setFieldValue('organ.specie.name', option?.children);
    }
  };
  const handleOrganChange = async (value, option) => {
    setSelectedOrgan(option ? { id: value, name: option?.children } : null);
    setFieldValue('organ._id', value);
    setFieldValue('organ.name', option?.children);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <label htmlFor='name' style={{ fontWeight: 'bold' }}>
            <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i> Name:
          </label>
          <Input
            id='name'
            placeholder='Name'
            name='name'
            type='text'
            onBlur={handleBlur}
            onChange={(value) => setFieldValue('name', value.target.value)}
            value={values.name}
            className={hasError('name')}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='organSelect' style={{ fontWeight: 'bold' }}>
            <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>{' '}
            Specie:
          </label>

          <Select
            id='speciesInput'
            value={selectedSpecies?.name ? selectedSpecies?.name : values?.organ?.specie?.name}
            onChange={handleSpeciesChange}
            placeholder='Species'
          >
            {species.map((item) => (
              <Select.Option key={item?._id} value={item._id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className='form-group'>
          <label htmlFor='organInput' style={{ fontWeight: 'bold' }}>
            <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>Organ:
          </label>
          <Select
            id='organInput'
            value={selectedOrgan?.name ? selectedOrgan?.name : values?.organ?.name}
            onChange={handleOrganChange}
            placeholder='Organ'
          >
            {organList.map((item) => (
              <Select.Option key={item?._id} value={item._id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className='form-group'>
          <label htmlFor='conclusionTemplate' style={{ fontWeight: 'bold' }}>
            Conclusion:
          </label>
          <ReactQuill
            value={values?.conclusionTemplate}
            onChange={(content) => {
              setFieldValue('conclusionTemplate', content);
            }}
            placeholder='Compose your Findings...'
            modules={modules}
            formats={formats}
            id='conclusionTemplate'
            style={{ marginTop: '0.5rem' }}
          />
        </div>
        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>

          <Button disabled={!isValid || loading} type='primary' htmlType='submit'>
            {loading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: 'white' }} spin />} />
            ) : (
              submitText
            )}
          </Button>
        </div>
      </form>
    </>
  );
};

export default DiseaseForm;
