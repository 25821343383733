import { Provider } from 'react-redux';
import { store } from './redux/store';

import './index.scss';
import App from './App';

import * as serviceWorker from './serviceWorker';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
);

serviceWorker.unregister();
