import axios, { AxiosResponse } from 'axios';

interface UpdateData {
  id: string;
  name: string;
}

export const addData = async (url: string, newData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(url, newData);
    return response;
  } catch (error) {
    console.error('Error adding data:', error);
    throw error;
  }
};

export const getOrganBySpecie = async (url: string, newData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(url, newData);
    return response;
  } catch (error) {
    console.error('Error adding data:', error);
    throw error;
  }
};

export const getAllSpecies = async (url: string, headers: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(url, { headers });
    return response;
  } catch (error) {
    console.error('Error adding data:', error);
    throw error;
  }
};

export const deleteData = async (url: string, deleteData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(`${url}`);
    return response;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};

export const updateData = async (url: string, updateData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(url, updateData);
    return response;
  } catch (error) {
    // Handle error
    console.error('Error updating data:', error);
    throw error;
  }
};
export const copyData = async (url: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    // Handle error
    console.error('Error updating data:', error);
    throw error;
  }
};

export const updateFormData = async (url: string, updateData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(url, updateData);
    return response;
  } catch (error) {
    // Handle error
    console.error('Error updating data:', error);
    throw error;
  }
};

export const deleteFindingData = async (url: string, updateData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(url, updateData);
    return response;
  } catch (error) {
    // Handle error
    console.error('Error updating data:', error);
    throw error;
  }
};

export const updateFindingData = async (url: string, updateData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(url, updateData);
    return response;
  } catch (error) {
    // Handle error
    console.error('Error updating data:', error);
    throw error;
  }
};

export const getForm = async (url: string, newData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(url + newData);
    return response;
  } catch (error) {
    console.error('Error adding data:', error);
    throw error;
  }
};

export const addForm = async (url: string, formData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(url, {
      title: formData.title,
      organId: formData.organId,
      specieId: formData.specieId,
      sections: formData.sections
    });
    return response;
  } catch (error) {
    console.error('Error adding form:', error);
    throw error;
  }
};

export const addSection = async (url: string, sectionData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(url, {
      title: sectionData.title,
      formId: sectionData.formId,
      fields: sectionData.fields,
      serial: sectionData.serial
    });
    return response;
  } catch (error) {
    console.error('Error adding section:', error);
    throw error;
  }
};

export const deleteSection = async (url: string, id: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(url + '/' + id);
    return response;
  } catch (error) {
    console.error('Error delete Section:', error);
    throw error;
  }
};

export const addField = async (url: string, fieldData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(url, {
      serial: fieldData.serial,
      title: fieldData.title,
      heading: fieldData.heading,
      sectionId: fieldData.sectionId,
      ranges: fieldData.ranges,
      dropdown: fieldData.dropdown,
      formula: fieldData.formula,
      condition: fieldData.condition,
      description: fieldData.description,
      suggestion: fieldData.suggestion
    });

    return response;
  } catch (error) {
    console.error('Error adding field:', error);
    throw error;
  }
};

export const deleteField = async (url: string, id: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(url + '/' + id);
    return response;
  } catch (error) {
    console.error('Error delete field:', error);
    throw error;
  }
};

export const addRange = async (url: string, rangeData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(url, {
      title: rangeData.title,
      fieldId: rangeData.fieldId,
      formula: rangeData.formula,
      rangeValue: rangeData.rangeValue,
      rangeType: rangeData.rangeType,
      condition: rangeData.condition,
      description: rangeData.description,
      suggestion: rangeData.suggestion
    });
    return response;
  } catch (error) {
    console.error('Error adding range:', error);
    throw error;
  }
};

export const deleteRange = async (url: string, id: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(url + '/' + id);
    return response;
  } catch (error) {
    console.error('Error delete range:', error);
    throw error;
  }
};

export const addFinding = async (url: string, data: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(url, {
      title: data.title,
      formId: data.formId,
      findingCategoryId: data.findingCategoryId,
      details: data.details
    });
    return response;
  } catch (error) {
    console.error('Error adding field:', error);
    throw error;
  }
};

export const deleteFinding = async (url: string, id: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(url + '/' + id);
    return response;
  } catch (error) {
    console.error('Error delete finding:', error);
    throw error;
  }
};
