import {
  ADD_TEST_PARAMETER,
  AddTestParameterAction,
  DELETE_TEST_PARAMETER,
  DeleteTestParameterAction,
  EDIT_TEST_PARAMETER,
  EditTestParameterAction,
  SET_TEST_PARAMETERS,
  SetTestParametersAction
} from './types';

import { ITestParameter } from '../../interfaces/testParameter';
import axios from 'axios';

export const setTestParameters = (testParameters: ITestParameter[]): SetTestParametersAction => ({
  type: SET_TEST_PARAMETERS,
  payload: testParameters
});

export const addTestParameter = (testParameter: ITestParameter): AddTestParameterAction => ({
  type: ADD_TEST_PARAMETER,
  payload: testParameter
});

export const deleteTestParameter = (id: string): DeleteTestParameterAction => ({
  type: DELETE_TEST_PARAMETER,
  id
});

export const editTestParameter = (testParameter: ITestParameter): EditTestParameterAction => ({
  type: EDIT_TEST_PARAMETER,
  payload: testParameter
});

export const fetchTestParameters: any = (url: string) => {
  return (dispatch) => {
    axios
      .get<ITestParameter[]>(url)
      .then((res) => res.data)
      .then((data) => {
        dispatch(setTestParameters(data));
      })
      .catch((err) => console.error('Server connections error'));
  };
};
