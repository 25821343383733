import { usePageData } from '../../../hooks/usePage';
import { useHospitals } from '../../../hooks/useHospitals';

import HospitalsTable from './HospitalsTable';

import { IPageData } from '../../../interfaces/page';
import { usePatients } from '../../../hooks/usePatients';
import AddHospital from './AddHospital/AddHospital';
import { useEffect } from 'react';
import { fetchHospitals } from '../../../redux/hospitals/actions';
import { useDispatch } from 'react-redux';

const pageData: IPageData = {
  title: 'Hospitals',
  fulFilled: true
};

const HospitalsPage = () => {
  const { hospitals, editHospital, deleteHospital } = useHospitals();

  const dispatch = useDispatch();
  useEffect(() => {
    if (hospitals.length === 0) {
      // dispatch(fetchHospitals('https://radiology-dev-myxac.ondigitalocean.app/api/v1/hospitals'));
      dispatch(fetchHospitals('/data/hospitals.json'));
    }
  }, []);

  usePageData(pageData);

  return (
    <>
      <header className='page-header'>
        <AddHospital />
      </header>
      <HospitalsTable
        onDeleteHospital={deleteHospital}
        onEditHospital={editHospital}
        hospitals={hospitals}
      />
    </>
  );
};

export default HospitalsPage;
