import { IHeading } from '../../interfaces/heading';

export const SET_HEADINGS = '[heading] Set';
export const ADD_HEADING = '[heading] Add';
export const EDIT_HEADING = '[heading] Edit';
export const DELETE_HEADING = '[heading] Delete';

export interface SetHeadingAction {
  type: typeof SET_HEADINGS;
  payload: IHeading[];
}

export interface AddHeadingAction {
  type: typeof ADD_HEADING;
  payload: IHeading;
}

export interface EditHeadingAction {
  type: typeof EDIT_HEADING;
  payload: IHeading;
}

export interface DeleteHeadingAction {
  type: typeof DELETE_HEADING;
  id: string;
}

export type HeadingsActions =
  | SetHeadingAction
  | AddHeadingAction
  | DeleteHeadingAction
  | EditHeadingAction;

