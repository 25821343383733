import { useState } from 'react';
import { Button, Select, Input, Checkbox } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { hasErrorFactory } from '../../../../utils/hasError';

import { IFormula } from '../../../../interfaces/formula';
import { useHeading } from '../../../../hooks/useHeading';

const { TextArea } = Input;
type Props = {
  onSubmit: (formula: IFormula) => void;
  onCancel: () => void;
  formula?: IFormula;
  submitText?: string;
};

const defaultSubmitText = 'Add Formula';
const emptyFormula: IFormula = {
  formulaTitle: '',
  formula01Heading: '',
  formula1: '',
  setRange: false,
  formula2Heading: '',
  heading: '',
  formula3: '',
  formula2: ''
};

const FormulaScheme = Yup.object({
  formulaTitle: Yup.string().required(),
  formula01Heading: Yup.string().required(),
  formula1: Yup.string().required(),
  setRange: Yup.boolean(),
  formula2Heading: Yup.string(),
  formula2: Yup.string(),
  formula3: Yup.string(),
  heading: Yup.string()
});

const FormulaForm = ({
  submitText = defaultSubmitText,
  formula = emptyFormula,
  onSubmit,
  onCancel
}: Props) => {
  const {
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleSubmit,
    setValues,
    handleBlur,
    resetForm,
    touched,
    values,
    errors,
    isValid
  } = useFormik<IFormula>({
    validationSchema: FormulaScheme,
    initialValues: formula,
    onSubmit: (values) => {
      onSubmit(values);
      onCancel();
    }
  });
  const [showFormula2, setShowFormula2] = useState(false);
  const { headings } = useHeading();
  const hasError = hasErrorFactory(touched, errors);

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    setFieldValue('setRange', checked);
    setShowFormula2(checked);

    if (!checked) {
      setValues({
        ...values,
        formula2Heading: '',
        formula2: ''
      });
    }
  };

  const handleHeadingSelect = (e) => {
    // alert(e);
    setValues({
      ...values,
      heading: e
    });
  };
  const handleStatusSelect = (e) => {
    // alert(e);
    setValues({
      ...values,
      status: e
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <Input
            placeholder='Formula Title'
            name='formulaTitle'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.formulaTitle}
            className={hasError('formulaTitle')}
          />
        </div>

        {/* <div className='form-group'>
          <Input
            placeholder='Formula 01 Heading'
            name='formula01Heading'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.formula01Heading}
            className={hasError('formula01Heading')}
          />
        </div> */}

        {/* <div className='row'> */}
        {/* <div className='col-sm-6 col-12'> */}
        <div className='form-group'>
          <Select
            placeholder='Select Heading'
            defaultValue={values.heading}
            onChange={handleHeadingSelect}
            className={hasError('heading')}
            onBlur={() => setFieldTouched('heading')}
          >
            {headings.map((item) => {
              return <Select.Option value={item.name}>{item.name}</Select.Option>;
            })}

            {/* <Select.Option value='Female'>Female</Select.Option> */}
          </Select>
        </div>
        {/* </div> */}
        {/* </div> */}
        <>
          {/* <div className='form-group'>
            <Input
              placeholder='Low range Formula'
              name='formula2Heading'
              type='text'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.formula2Heading}
              className={hasError('formula2Heading')}
            />
          </div> */}
          <div className='form-group'>
            <Input
              placeholder='Low range Formula'
              name='formula1'
              type='text'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.formula1}
              className={hasError('formula1')}
            />
          </div>
          <div className='form-group'>
            <Input
              placeholder='High Range Formula'
              name='formula2'
              type='text'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.formula2}
              className={hasError('formula2')}
            />
          </div>
          <div className='form-group'>
            <Input
              placeholder='Mid Range Formula'
              name='formula3'
              type='text'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.formula2}
              className={hasError('formula3')}
            />
          </div>
        </>
        {/* )} */}
        <div className='form-group'>
          <Select
            placeholder='Status'
            defaultValue={values.status}
            onChange={handleStatusSelect}
            className={hasError('status')}
            onBlur={() => setFieldTouched('status')}
          >
            {/* {headings.map((item) => {
              return <Select.Option value={item.name}>{item.name}</Select.Option>;
            })} */}

            <Select.Option value='Active'>Active</Select.Option>
            <Select.Option value='In Active'>InActive</Select.Option>
          </Select>
        </div>

        {/* <div className='form-group'>
          <TextArea
            rows={3}
            placeholder='Description'
            name='description'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.address}
            className={hasError('address')}
          />
        </div> */}

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>

          <Button disabled={!isValid} type='primary' htmlType='submit'>
            {submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default FormulaForm;
