import axios, { AxiosResponse } from 'axios';

interface UpdateData {
  title: string;
  description: string;
}

export const addData = async (url: string, newData: any, headers: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(url, newData, { headers });
    return response;
  } catch (error) {
    console.error('Error adding data:', error);
    throw error;
  }
};

export const deleteData = async (url: string, headers: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(url, { headers });
    return response;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};

export const updateData = async (
  url: string,
  updateData: UpdateData,
  headers: any
): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(url, updateData, { headers });
    return response;
  } catch (error) {
    console.error('Error updating data:', error);
    throw error;
  }
};
