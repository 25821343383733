import { usePageData } from '../../../hooks/usePage';
import { useDisease } from '../../../hooks/useDisease';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import DiseaseTable from './DiseaseTable';
import { IPageData } from '../../../interfaces/page';
import AddDisease from './AddDisease/AddDisease';
import { fetchDisease } from '../../../redux/disease/actions';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getAllDiseaseURL, getAllSpecieURL } from '../../../constants/url';
import 'react-quill/dist/quill.snow.css';
import { fetchSpecie } from '../../../redux/specie/actions';

const pageData: IPageData = {
  title: 'Diseases',
  fulFilled: true
};

const DiseasePage = () => {
  const { diseases, editDisease, deleteDisease } = useDisease();
  const [loading, setLoading] = useState(true);
  const role = localStorage.getItem('role');

  usePageData(pageData);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        await dispatch(fetchDisease(getAllDiseaseURL, headers));
        await dispatch(fetchSpecie(getAllSpecieURL, headers));
      } catch (error) {
        message.error('Failed to fetch disease data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <>
      <Spin
        spinning={loading}
        size='large'
        indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
      >
        {role === 'admin' ? (
          <header className='page-header'>
            <AddDisease />
          </header>
        ) : (
          ''
        )}
        <DiseaseTable
          onDeleteDisease={deleteDisease}
          onEditDisease={editDisease}
          diseases={diseases}
        />
      </Spin>
    </>
  );
};

export default DiseasePage;
