import { Button, Input, Select, Spin, message } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { hasErrorFactory } from '../../../../utils/hasError';

import { useEffect, useState } from 'react';
import { getAllOrganURL } from '../../../../constants/url';
import { useSpecies } from '../../../../hooks/useSpecie';
import { IOrgan } from '../../../../interfaces/organ';
import { editOrgan } from '../../../../redux/organ/actions';
import { addData, updateData } from './api';

import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;
type Props = {
  onSubmit: (Organ: IOrgan) => void;
  onCancel: () => void;
  Organ?: IOrgan;
  submitText?: string;
};

const defaultSubmitText = 'Add Organ';
const emptyOrgan = {
  name: null,
  address: null,
  status: null,
  specie: {}
};

const OrganScheme = Yup.object({
  name: Yup.string().required(),
  specie: Yup.string()
});

const OrganForm = ({
  submitText = defaultSubmitText,
  Organ = emptyOrgan,
  onSubmit,
  onCancel
}: Props) => {
  const [loading, setLoading] = useState(false);
  const {
    setFieldTouched,
    setFieldValue,
    handleSubmit,
    setValues,
    handleBlur,
    touched,
    values,
    errors,
    isValid
  } = useFormik<IOrgan>({
    validationSchema: OrganScheme,
    initialValues: Organ,
    onSubmit: async (values: any) => {
      try {
        setLoading(true);

        if (submitText === 'Update Organ') {
          let res = await updateData(getAllOrganURL + `/${values._id}`, values);
          editOrgan(res?.data?.data);
          onSubmit(res?.data?.data);
          if (res?.status === 201) {
            message.success(`${res?.data?.message}`);
            setLoading(false);
          }
        } else {
          let res = await addData(getAllOrganURL, values);
          if (res?.status === 201) {
            message.success(`${res?.data?.message}`);
            setLoading(false);
          }
          onSubmit(res?.data?.data);
        }
        onCancel();
      } catch (error) {
        console.error('An error occurred:', error);
        message.error('An error occurred while processing your request');
      } finally {
        setLoading(false);
      }
    }
  });

  const { species } = useSpecies();
  const hasError = hasErrorFactory(touched, errors);

  const handleCancel = () => {
    onCancel();
  };

  useEffect(() => {
    setValues({ ...Organ, specie: Organ.specie._id });
  }, [Organ]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <label htmlFor='organTitle' style={{ fontWeight: 'bold' }}>
            <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>Title:
          </label>
          <Input
            id='organTitle'
            placeholder='Title'
            name='name'
            type='text'
            onBlur={handleBlur}
            onChange={(value) => setFieldValue('name', value.target.value)}
            value={values.name}
            className={hasError('name')}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='specieSelect' style={{ fontWeight: 'bold' }}>
            <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>{' '}
            Specie:
          </label>
          <Select
            id='specieSelect'
            placeholder='Select Specie'
            value={values?.specie?._id ? values?.specie?._id : values?.specie}
            onChange={(value) => {
              setFieldValue('specie', value);
            }}
            className={values?.specie?._id ? '' : hasError('specie')}
            onBlur={() => setFieldTouched('specie')}
          >
            {species.map((item) => {
              return (
                <Option key={item?._id} value={item._id}>
                  {item?.name}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>

          <Button type='primary' htmlType='submit' disabled={!isValid || loading}>
            {loading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: 'white' }} spin />} />
            ) : (
              submitText
            )}
          </Button>
        </div>
      </form>
    </>
  );
};

export default OrganForm;
