import {
  ADD_DISEASE,
  AddDiseaseAction,
  DELETE_DISEASE,
  DeleteDiseaseAction,
  EDIT_DISEASE,
  EditDiseaseAction,
  SET_DISEASES,
  SetDiseaseAction
} from './types';
import { message } from 'antd';

import { IDisease } from '../../interfaces/disease';
import axios from 'axios';
import { clearLocalStorage, logout } from '../auth/action';

export const setDiseases = (diseases: IDisease[]): SetDiseaseAction => ({
  type: SET_DISEASES,
  payload: diseases
});

export const addDisease = (disease: IDisease): AddDiseaseAction => ({
  type: ADD_DISEASE,
  payload: disease
});

export const deleteDisease = (_id: string): DeleteDiseaseAction => ({
  type: DELETE_DISEASE,
  _id
});

export const editDisease = (disease: IDisease): EditDiseaseAction => ({
  type: EDIT_DISEASE,
  payload: disease
});
export const fetchDisease: any = (url: string, headers: any) => {
  return (dispatch) => {
    axios
      .get<{ data: IDisease[] }>(url, { headers })
      .then((res) => res.data)
      .then((data) => {
        dispatch(setDiseases(data.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          message.error('Token Expired: Login Again');
          dispatch(logout());
          clearLocalStorage();
          return;
        }
        message.error('Server connections error');
      });
  };
};
