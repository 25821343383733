import { IFormGroups } from '../../interfaces/formGroup';

export const SET_FORMS_GROUP = '[formGroups] Set';
export const ADD_FORMS_GROUP = '[formGroups] Add';
export const EDIT_FORMS_GROUP = '[formGroups] Edit';
export const DELETE_FORMS_GROUP = '[formGroups] Delete';

export interface SetFormGroupsAction {
  type: typeof SET_FORMS_GROUP;
  payload: IFormGroups[];
}

export interface AddFormGroupsAction {
  type: typeof ADD_FORMS_GROUP;
  payload: IFormGroups;
}

export interface EditFormGroupsAction {
  type: typeof EDIT_FORMS_GROUP;
  payload: IFormGroups;
}

export interface DeleteFormGroupsAction {
  type: typeof DELETE_FORMS_GROUP;
  id: string;
}

export type FormsActions =
  | SetFormGroupsAction
  | AddFormGroupsAction
  | EditFormGroupsAction
  | DeleteFormGroupsAction;
