import { Avatar, Button } from 'antd';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

import anonymousImg from '../../../assets/img/No_avatar.png';

type Props = {
  src?: string;
  size?: number;
  onLoad?: (img: string | File) => void;
  onSelectImage?: (image: any) => void;
};

const ImageLoader = ({ src, size = 40, onLoad = () => null, onSelectImage }: Props) => {
  const input = useRef<HTMLInputElement>(null);
  const [img, setImg] = useState<string>(anonymousImg);

  useEffect(() => {
    if (src) {
      setImg(src);
    }
  }, [src]);

  const handleClick = () => input.current.click();

  const handleLoad = (e: ChangeEvent<HTMLInputElement>) => {
    const file: File = e.target.files[0];
    const reader: FileReader = new FileReader();

    reader.onloadend = () => {
      const result = reader.result as string;
      if (onSelectImage) {
        onSelectImage(file);
      }
      setImg(result);
    };

    reader.readAsDataURL(file);
  };

  const icon = <span className='icofont icofont-ui-user ml-2' />;

  return (
    <>
      <input ref={input} onChange={handleLoad} type='file' style={{ display: 'none' }} />
      <div className='d-flex align-items-center'>
        <Avatar
          src={img}
          size={size}
          className='mr-4 object-fit-contain'
          style={{ objectFit: 'contain' }}
        />
        <Button type={'primary'} className='btn-outline' onClick={handleClick}>
          Select image {icon}
        </Button>
      </div>
    </>
  );
};

export default ImageLoader;
