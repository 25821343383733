import { IDoctor } from '../interfaces/doctor';
import { useDispatch, useSelector } from 'react-redux';

import * as doctorActions from '../redux/doctors/actions';
import { IAppState } from '../interfaces/app-state';

export function useDoctors() {
  const dispatch = useDispatch();
  const doctors = useSelector<IAppState, IDoctor[]>((state) => state.doctors);

  const editDoctor = (doctor: IDoctor) => {
    return dispatch(doctorActions.editDoctor(doctor));
  };

  const addDoctor = (doctor: IDoctor) => {
    return dispatch(doctorActions.addDoctor(doctor));
  };

  const deleteDoctor = (id: string) => {
    return dispatch(doctorActions.deleteDoctor(id));
  };

  return { doctors, addDoctor, editDoctor, deleteDoctor };
}
