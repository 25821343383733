import { ADD_DOCTOR, DELETE_DOCTOR, EDIT_DOCTOR, DoctorsActions, SET_DOCTORS } from './types';
import { IDoctor } from '../../interfaces/doctor';

const initialState: IDoctor[] = [];

export function doctorsReducer(state: IDoctor[] = initialState, action: DoctorsActions): IDoctor[] {
  switch (action.type) {
    case SET_DOCTORS: {
      return [...action.payload];
    }

    case EDIT_DOCTOR: {
      const editedDoctors = state.map((el) =>
        el._id !== action.payload._id ? el : action.payload
      );

      return [...editedDoctors];
    }

    case ADD_DOCTOR: {
      return [{ ...action.payload }, ...state];
    }

    case DELETE_DOCTOR: {
      const doctors = state.filter((el) => el._id !== action.id);
      return [...doctors];
    }

    default: {
      return state;
    }
  }
}
