import { IDisease } from '../interfaces/disease';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../redux/disease/actions';

import { IAppState } from '../interfaces/app-state';

export function useDisease() {
  const dispatch = useDispatch();
  const diseases = useSelector<IAppState, IDisease[]>((state) => state.diseases);

  const editDisease = (disease: IDisease) => {
    return dispatch(actions.editDisease(disease));
  };

  const addDisease = (disease: IDisease) => {
    return dispatch(actions.addDisease(disease));
  };

  const deleteDisease = (id: string) => {
    return dispatch(actions.deleteDisease(id));
  };

  return { diseases, addDisease, editDisease, deleteDisease };
}
