import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
interface UpdateData {
  id: string;
  name: string;
}
export const updateData = async (
  url: string,
  updateData: UpdateData,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(url, updateData, config);
    return response;
  } catch (error) {
    console.error('Error updating data:', error);
    throw error;
  }
};

export const addData = async (
  url: string,
  newData: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(url, newData, config);
    return response;
  } catch (error) {
    console.error('Error adding data:', error);
    throw error;
  }
};

export const deleteData = async (
  url: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};
export const activeDoctor = async (
  url: string,
  updateData: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(url, updateData, config);
    return response;
  } catch (error) {
    console.error('Error Updating data:', error);
    throw error;
  }
};
