import React, { useState } from 'react';
import { Button, Modal } from 'antd';

import HospitalForm from './HospitalForm';
import { useHospitals } from '../../../../hooks/useHospitals';

import { IHospital } from '../../../../interfaces/hospital';

const AddHospital = () => {
  const { addHospital } = useHospitals();
  const [visible, setVisible] = useState(false);

  const handleClick = () => setVisible(!visible);

  const closeModal = () => setVisible(false);

  const handleAddHospital = (hospital: IHospital) => {
    addHospital(hospital);
    closeModal();
  };

  return (
    <div className='add-hospital'>
      <Button type='primary' onClick={handleClick}>
        <span className='icofont icofont-plus mr-2' style={{ fontSize: '1.3em' }} />
        Add Hospital
      </Button>
      <Modal
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<h3 className='title'>Add Hospital</h3>}
      >
        <HospitalForm onCancel={closeModal} onSubmit={handleAddHospital} />
      </Modal>
    </div>
  );
};

export default AddHospital;
