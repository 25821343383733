import { useEffect, useState } from 'react';
import { usePageData } from '../../../hooks/usePage';
import { usePatients } from '../../../hooks/usePatients';
import PatientsTable from './PatientsTable';
import { IPageData } from '../../../interfaces/page';
import AddPatient from '../../../layout/components/patients/AddPatient';
import { fetchPatients } from '../../../redux/patients/actions';
import { useDispatch } from 'react-redux';
import { getAllSpecieURL, getPatientByIdURL } from '../../../constants/url';
import { Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { fetchSpecie } from '../../../redux/specie/actions';

const pageData: IPageData = {
  title: 'Patients',
  fulFilled: true
};

const PatientsPage = () => {
  const { patients, editPatient, deletePatient } = usePatients();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  let doctor_id = localStorage.getItem('doctor_id');
  usePageData(pageData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        await dispatch(fetchPatients(`${getPatientByIdURL}/${doctor_id}`, headers));
        await dispatch(fetchSpecie(getAllSpecieURL, headers));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error('Failed to fetch patient data. Please try again.');
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <>
      <Spin
        spinning={loading}
        size='large'
        indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
      >
        <header className='page-header'>
          <AddPatient />
        </header>
        <PatientsTable
          onDeletePatient={deletePatient}
          onEditPatient={editPatient}
          patients={patients}
        />
      </Spin>
    </>
  );
};

export default PatientsPage;
