import { Card, message, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { usePageData } from '../../../hooks/usePage';
import { IPageData } from '../../../interfaces/page';
import './Dashboard.scss';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDashboardCountURL } from '../../../constants/url';
import { clearLocalStorage, logout } from '../../../redux/auth/action';

const pageData: IPageData = {
  title: 'Dashboard',
  fulFilled: true
};

const DashboardPage = () => {
  const navigate = useNavigate();
  const handleSpecie = () => {
    navigate('/specie');
  };

  const handleOrgan = () => {
    navigate('/organ');
  };

  const handelPatient = () => {
    navigate('/patients');
  };

  const handelDoctor = () => {
    navigate('/doctors');
  };

  const handelForm = () => {
    navigate('/form');
  };

  const handelReport = () => {
    navigate('/reports');
  };

  const role = localStorage.getItem('role');
  const name = localStorage.getItem('name');
  const dispatch = useDispatch();
  usePageData(pageData);
  const [dashboardCount, setDashboardCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const response = await axios.get(`${getDashboardCountURL}`, { headers });
      if (response.status === 201) {
        setDashboardCount(response?.data.data);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        message.error('Token Expired: Login Again');
        dispatch(logout());
        clearLocalStorage();
        return;
      }
      message.error('Token Expired: Login Again');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const CardWithSpinner = ({ title, icon, count }) =>
    loading ? (
      <Skeleton active paragraph={{ rows: 3 }} />
    ) : (
      <Card style={{ cursor: 'pointer' }} className='animated with-shadow dash_cards_bg '>
        <div className='row'>
          <div className='col-5'>
            <span className={`icofont icofont-${icon} card-icon-color`} />
          </div>
          <div className='col-7 card-sub-heading'>
            <h6 className='mt-0 mb-1'>{title}</h6>
            <div className='count '>{count}</div>
          </div>
        </div>
      </Card>
    );

  return (
    <>
      <div className='row'>
        <h6 className='ml-3 mt-0 admin_title'>Hi {name},</h6>
      </div>
      <div className='row'>
        <div className={`col-12 col-md-6 col-xl-3 ${(role === 'admin' ? '' :'d-none')}`}  onClick={role === 'admin' ? handleSpecie : null}>
          <CardWithSpinner title='Species' icon='cat-dog' count={dashboardCount?.specie} />
        </div>
        <div className={`col-12 col-md-6 col-xl-3 ${(role === 'admin' ? '' :'d-none')}`} onClick={role === 'admin' ? handleOrgan : null}>
          <CardWithSpinner title='Organs' icon='heart-beat' count={dashboardCount?.organ} />
        </div>
        <div
          className={`col-12 col-md-6 ${(role === 'admin' ? 'col-xl-3' :'col-xl-6')}`}
          onClick={role === 'admin' ? handelDoctor : handelPatient}
        >
          <CardWithSpinner
            title={role === 'admin' ? 'Doctors' : 'Patients'}
            icon={role === 'admin' ? 'doctor' : 'wheelchair'}
            count={role === 'admin' ? dashboardCount?.doctors : dashboardCount?.patient}
          />
        </div>
        <div className={`col-12 col-md-6 ${(role === 'admin' ? 'col-xl-3' :'col-xl-6')}`} onClick={role === 'admin' ? handelForm : handelReport}>
          {/*<CardWithSpinner title='Lab Forms' icon='page' count={dashboardCount?.form} />*/}

          <CardWithSpinner
            title={role === 'admin' ? 'Lab Forms' : 'Reports'}
            icon={role === 'admin' ? 'page' : 'list'}
            count={role === 'admin' ? dashboardCount?.form : dashboardCount?.reports}
          />
        </div>
      </div>

      {/* {loading ? (
        <Skeleton active paragraph={{ rows: 5 }} />
      ) : (
        <Card title='Patient Survey'>
          <ReactEcharts className='chart-container container-h-400' option={hospitalOptions} />
        </Card>
      )} */}
    </>
  );
};

export default DashboardPage;
