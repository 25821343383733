import { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';

import { IAppState } from '../../interfaces/app-state';
import { IPageData } from '../../interfaces/page';
import { IAppSettings } from '../../interfaces/settings';
import className from '../../utils/class-names';
import './BaseLayout.scss';

type Props = {
  nav: ReactNode;
  sideNav?: ReactNode;
  topNav?: ReactNode;
  children: ReactNode;
  orientation: 'vertical' | 'horizontal';
};

const BaseLayout = ({ nav, topNav, sideNav, orientation, children }: Props) => {
  const [scrolled, setScrolled] = useState(false);

  const settings = useSelector<IAppState, IAppSettings>((state) => state.settings);
  const pageData = useSelector<IAppState, IPageData>((state) => state.pageData);

  const handleScroll = (event) => {
    setScrolled(event.target.scrollTop > 0);
  };

  const mainContentClasses = className({
    'main-content': true,
    loaded: pageData.loaded,
    fulfilled: pageData.fulFilled
  });

  const mainContentWrapClasses = className({
    'main-content-wrap': true
  });

  return (
    <div className={`layout ${orientation}`}>
      <div className={`app-container ${settings.boxed && 'boxed'} ${scrolled && 'scrolled'}`}>
        {nav}

        {topNav}

        {sideNav}

        <main onScroll={handleScroll} className={mainContentClasses}>
          <div className='app-loader'>
            <i className='icofont-spinner-alt-4 rotate' />
          </div>

          <div className={mainContentWrapClasses}>
            {pageData && !!pageData.title && <header className='page-header'></header>}
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default BaseLayout;
