import { IOrgan } from '../interfaces/organ';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../redux/organ/actions';

import { IAppState } from '../interfaces/app-state';
import { useEffect } from 'react';
import axios from 'axios';

export function useOrgan() {
  const dispatch = useDispatch();
  const organs = useSelector<IAppState, IOrgan[]>((state) => state.organs);

  const editOrgan = (organ: IOrgan) => {
    return dispatch(actions.editOrgan(organ));
  };

  const addOrgan = (organ: IOrgan) => {
    return dispatch(actions.addOrgan(organ));
  };

  const deleteOrgan = (id: string) => {
    return dispatch(actions.deleteOrgan(id));
  };

  return { organs, addOrgan, editOrgan, deleteOrgan };
}
