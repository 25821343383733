import { Modal } from 'antd';
import { useState } from 'react';

import { FloatButton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDoctors } from '../../../../hooks/useDoctor';
import { IDoctor } from '../../../../interfaces/doctor';
import '../Doctors.scss';
import DoctorForm from './DoctorForm';
const AddDoctor = () => {
  const { addDoctor } = useDoctors();
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(true);
  const handleClick = () => setVisible(!visible);

  const closeModal = () => setVisible(false);

  const handleAddDoctor = (doctor: IDoctor) => {
    addDoctor(doctor);
    closeModal();
  };
  let navigate = useNavigate();

  return (
    <div className='add-patient'>
      <FloatButton.Group
        open={open}
        trigger='click'
        className='doctors_float_button_toggle'
        onClick={() => setOpen(!open)}
        icon={<i className='icofont-ui-add'></i>}
      >
        <FloatButton
          className='float_button '
          onClick={handleClick}
          icon={<i className=' icofont-ui-add'></i>}
        />
        <FloatButton
          className='doctors_archive_float_button'
          onClick={() => navigate('/archived')}
          icon={<i className='icofont-archive'></i>}
          type='default'
        />
      </FloatButton.Group>
      <Modal
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<h3 className='title'>Add Doctor</h3>}
      >
        <DoctorForm onCancel={closeModal} onSubmit={handleAddDoctor} />
      </Modal>
    </div>
  );
};

export default AddDoctor;
