import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, FloatButton, Popconfirm, Spin, Table, Progress, message, Modal } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosProgressEvent } from 'axios';
import io from 'socket.io-client';

import { useDispatch } from 'react-redux';
import { duplicateFormUrl, getAllFormURL, socketIoUrl } from '../../../constants/url';
import { useForms } from '../../../hooks/useForms';
import { usePageData } from '../../../hooks/usePage';
import { IForms } from '../../../interfaces/forms';
import { IPageData } from '../../../interfaces/page';
import { fetchForms } from '../../../redux/forms/actions';
import { copyData, deleteData } from './api';

const pageData: IPageData = {
  title: 'Form',
  fulFilled: true
};

const FormTable = () => {
  const navigate = useNavigate();
  usePageData(pageData);
  const [loading, setLoading] = useState(true);
  const [visibleProgress, setVisibleProgress] = useState(false);
  const [progress, setProgress] = useState({
    message: '',
    progress: 0
  });
  const dispatch = useDispatch();
  const { forms, addForm, deleteForm } = useForms();

  const socketRef = useRef(null);

  const handleFormEdit = (recordId: string) => {
    navigate(`/edit-form/${recordId}`);
  };
  const isAdmin = localStorage.getItem('role') === 'admin';
  const handleDeleteForm = async (id) => {
    try {
      await deleteData(getAllFormURL + `/${id}`, id);
      deleteForm(id);
      message.success('Form deleted successfully');
    } catch (error) {
      message.error('Failed to delete form. Please try again.');
    }
  };
  const handleCopyForm = async (id) => {
    try {
      setVisibleProgress(true);

      socketRef.current = io(socketIoUrl, {
        reconnectionAttempts: 5,
        reconnectionDelay: 2000
      });

      socketRef.current.on('connect', () => {
        console.log('WebSocket connection established');
      });

      socketRef.current.on('progress', (data) => {
        setProgress(data.progress);

        // if (data.progress.progress === 100) {
        //   console.log('hello :>> ', data.progress.data);
        // }

        if (data.progress.status && data.progress.status === 200) {
          addForm(data.progress.data);
          message.success('Form Copied successfully');
          setVisibleProgress(false);
          socketRef.current.off('progress');
          socketRef.current.disconnect();
          setProgress({
            message: 'Duplicating',
            progress: 0
          });
        }

        if (data.progress.status && data.progress.status === 400) {
          message.error(data.progress.data.message);
          setVisibleProgress(false);
          socketRef.current.off('progress');
          socketRef.current.disconnect();
          setProgress({
            message: 'Duplicating',
            progress: 0
          });
        }
        console.log(
          `Progress: ${data.progress.progress} - ${data.progress.message} - ${data.progress.status}`
        );
      });

      socketRef.current.on('connect_error', (error) => {
        console.error('WebSocket connection error:', error);
        message.error('WebSocket connection error. Please try again.');
      });

      socketRef.current.on('disconnect', (reason) => {
        console.warn('WebSocket disconnected:', reason);
        if (reason === 'io server disconnect') {
          // The disconnection was initiated by the server, you need to reconnect manually
          socketRef.current.connect();
        }
      });

      let copFormRes = await copyData(duplicateFormUrl + `/${id}`);
      // addForm(copFormRes?.data?.data);
      // message.success('Form Copied successfully');
    } catch (error) {
      message.error('Failed to Copy form. Please try again.');
    } finally {
      // setVisibleProgress(false);
      // socketRef.current.off('progress');
      // socketRef.current.disconnect();
      // setProgress({
      //   message: 'Duplicating',
      //   progress: 0
      // });
    }
  };
  const handleShowInfo = (id) => navigate(`/view-form/${id}`);
  const actions = (record: IForms) => (
    <div className='buttons-list nowrap justify-content-center'>
      <Button shape='circle' onClick={() => handleCopyForm(record._id)} type='dashed'>
        <span className='icofont icofont-copy-invert' />
      </Button>
      <Button shape='circle' onClick={() => handleShowInfo(record._id)}>
        <span className='icofont icofont-external-link' />
      </Button>
      {isAdmin && (
        <>
          <Button onClick={() => handleFormEdit(record._id)} shape='circle' type='primary'>
            <span className='icofont icofont-edit-alt' />
          </Button>
          <Popconfirm
            title='Are you sure to delete this row?'
            okText='Yes'
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() => handleDeleteForm(record._id)}
          >
            <Button shape='circle' danger>
              <span className='icofont icofont-ui-delete' />
            </Button>
          </Popconfirm>
        </>
      )}
    </div>
  );
  const uniqueCategoryTitles = Array.from(
    new Set(forms.map((form) => form.category?.title || '--'))
  );
  const uniqueSpecieNames = Array.from(
    new Set(forms.map((form) => form.specieId?.name?.toLowerCase()))
  );
  const uniqueOrganNames = Array.from(
    new Set(forms.map((form) => form.organId?.name?.toLowerCase()))
  );

  const columns: ColumnProps<IForms>[] = [
    {
      key: 'title',
      dataIndex: 'title',
      align: 'center',
      title: <div style={{ textAlign: 'center' }}>Form Title</div>,

      sorter: (a, b) => (a.title > b.title ? 1 : -1),
      render: (title) => <strong>{title}</strong>
    },
    {
      key: 'category',
      dataIndex: 'category',
      align: 'center',
      title: 'Group',
      filters: uniqueCategoryTitles.map((title) => ({ text: title, value: title })),
      onFilter: (value, record) => record.category?.title === (value === '--' ? undefined : value),
      render: (category) => <strong>{!category ? '--' : category?.title}</strong>
    },
    {
      key: 'specieId',
      dataIndex: ['specieId', 'name'],
      align: 'center',
      title: 'Specie',
      filters: uniqueSpecieNames.map((name) => ({ text: name, value: name })),
      onFilter: (value, record) => record.specieId?.name?.toLowerCase() === value,
      render: (specieName) => <strong>{specieName}</strong>
    },
    {
      key: 'organId',
      dataIndex: ['organId', 'name'],
      align: 'center',
      title: 'Organ',
      filters: uniqueOrganNames.map((name) => ({ text: name, value: name })),
      onFilter: (value, record) => record.organId?.name?.toLowerCase() === value,
      render: (organName) => <strong>{organName}</strong>
    },
    {
      key: 'actions',
      align: 'center',
      title: 'Action',
      render: (_, record) => actions(record)
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        setLoading(true);
        await dispatch(fetchForms(getAllFormURL, headers));
      } catch (error) {
        message.error('Failed to fetch form data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const pagination = forms.length <= 10 ? false : { showSizeChanger: false };

  return (
    <>
      <Spin
        spinning={loading}
        size='large'
        indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
      >
        {isAdmin ? (
          <header className='page-header'>
            <FloatButton
              className='float_button'
              type='primary'
              onClick={() => navigate('/add-Form')}
              icon={<i className='icofont-ui-add'></i>}
            />
          </header>
        ) : (
          ''
        )}
        <Table
          pagination={pagination}
          className='accent-header'
          rowKey='id'
          dataSource={forms}
          columns={columns}
        />
      </Spin>

      <Modal
        open={visibleProgress}
        footer={null}
        closable={false}
        centered
        style={{ textAlign: 'center', padding: '1rem' }}
      >
        <div className='p-4'>
          <Progress percent={progress?.progress} status='active' size={['100%', 20]} />
          <p>{progress?.message ? progress?.message : 'Duplicating Form'}</p>
        </div>
      </Modal>
    </>
  );
};

export default FormTable;
