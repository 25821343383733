import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { FloatButton } from 'antd';
import OrganForm from './OrganForm';
import { useOrgan } from '../../../../hooks/useOrgan';
import { IOrgan } from '../../../../interfaces/organ';

const AddOrgan = () => {
  const { addOrgan } = useOrgan();
  const [visible, setVisible] = useState(false);

  const handleClick = () => setVisible(!visible);

  const closeModal = () => setVisible(false);

  const handleAddOrgan = (organ: IOrgan) => {
    addOrgan(organ);
    closeModal();
  };

  return (
    <div className='add-organ'>
      <FloatButton
        className='float_button'
        onClick={handleClick}
        icon={<i className='icofont-ui-add'></i>}
      />
      <Modal
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<h3 className='title'>Add Organ</h3>}
      >
        <OrganForm onCancel={closeModal} onSubmit={handleAddOrgan} />
      </Modal>
    </div>
  );
};

export default AddOrgan;
