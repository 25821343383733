import React from 'react';

const Terms: React.FC<any> = () => {
  return (
    <div className='terms_div'>
      <h3>Terms and Conditions</h3>

      <ol id='sectionscontentlist'>
        <li className=''>
          <strong>Introduction: </strong>document governs website use; consent to website document:
          implied; consent to website terms and conditions: express; website user minimum age.
        </li>
        <li className=''>
          <strong>Copyright notice: </strong>UCC copyright notice; ownership of rights in website.
        </li>
        <li className=''>
          <strong>Permission to use website: </strong>licence to use website; no downloading;
          website use: permitted purposes; no modification of website content; limitations on
          licence to use website; redistributable content; suspension or restriction of access to
          website.
        </li>
        <li className=''>
          <strong>Feeds: </strong>access to feeds; accessing feeds and acceptance of document; feeds
          licence; feeds licence condition; revocation of feeds licence.
        </li>
        <li className=''>
          <strong>Misuse of website: </strong>acceptable use: prohibitions; using contact details
          prohibited; veracity of information supplied.
        </li>
        <li className=''>
          <strong>Registration and accounts: </strong>account eligibility; account registration
          process; no other person permitted to use account; notify on misuse of account; use of
          another's account.
        </li>
        <li className=''>
          <strong>User login details: </strong>provision of login details; user ID rules and
          impersonation; password to be kept confidential; notify on disclosure of password;
          responsibility for password loss.
        </li>
        <li className=''>
          <strong>Cancellation and suspension of account: </strong>rights of operator over free
          website account; cancellation policy for unused accounts; cancellation of free website
          account by user.
        </li>
        <li className=''>
          <strong>Our rights to use your content: </strong>definition of user content; licence of
          user content; sub-licensing of user content; right to bring proceedings in respect of user
          content; waiver of moral rights in user content; user may edit own content; delete etc
          user content on breach.
        </li>
        <li className=''>
          <strong>Rules about your content: </strong>user content warranty; no unlawful user
          content; user content rules; civility and user content; hyperlinking and user content;
          previous complaints and user content.
        </li>
        <li className=''>
          <strong>Report abuse: </strong>request to report abuse; how to report abuse.
        </li>
        <li className=''>
          <strong>Medical information: </strong>no medical advice; information is not an alternative
          to medical advice; seeking medical advice for specific questions; immediate medical
          attention; never delay etc medical treatment.
        </li>
        <li className=''>
          <strong>Limited warranties: </strong>no warranties for information; right to discontinue
          website publication; no implied warranties or representations relating to website.
        </li>
        <li className=''>
          <strong>Limitations and exclusions of liability: </strong>caveats to limits of liability;
          interpretation of limits of liability; no liability for free information or services; no
          liability for force majeure; no liability for business losses; no liability for loss of
          data or software; no liability for consequential loss; no personal liability.
        </li>
        <li className=''>
          <strong>Indemnity: </strong>indemnity from website users.
        </li>
        <li className=''>
          <strong>Breaches of these terms and conditions: </strong>consequences of breach; non
          circumvention of measures upon breach.
        </li>
        <li className=''>
          <strong>Third party websites: </strong>third party websites: hyperlinks not
          recommendations; third party websites: no control or liability.
        </li>
        <li className=''>
          <strong>Trade marks: </strong>trade mark ownership; third party trade marks on website.
        </li>
        <li className=''>
          <strong>Variation: </strong>document may be revised; variation of website document:
          unregistered users; variation of website document: registered users.
        </li>
        <li className=''>
          <strong>Assignment: </strong>assignment by first party; assignment by second party.
        </li>
        <li className=''>
          <strong>Severability: </strong>severability of whole; severability of parts.
        </li>
        <li className=''>
          <strong>Third party rights: </strong>third party rights: benefit; third party rights:
          exercise of rights.
        </li>
        <li className=''>
          <strong>Entire agreement: </strong>entire agreement - use of website.
        </li>
        <li className=''>
          <strong>Law and jurisdiction: </strong>governing law; jurisdiction.
        </li>
        <li className=''>
          <strong>Statutory and regulatory disclosures: </strong>ecommerce regulations: trade
          register; ecommerce regulations: authorisation scheme; ecommerce regulations:
          professionals; ecommerce regulations: code of conduct; value added tax number.
        </li>
        <li className=''>
          <strong>Our details: </strong>website operator name; company registration details; place
          of business; contact information.
        </li>
      </ol>
    </div>
  );
};

export default Terms;
