import React, { useState } from 'react';
import { Button, Modal } from 'antd';

import FormulaForm from './FormulaForm';
import { useFormula } from '../../../../hooks/useFormula';

import { IFormula } from '../../../../interfaces/formula';

const AddFormula = () => {
  const { addFormula } = useFormula();
  const [visible, setVisible] = useState(false); 

  const handleClick = () => setVisible(!visible);

  const closeModal = () => setVisible(false);

  const handleAddFormula = (formula: IFormula) => {
    addFormula(formula);
    closeModal();
  };

  return (
    <div className='add-formula'>
      <Button type='primary' onClick={handleClick}>
        <span className='icofont icofont-plus mr-2' style={{ fontSize: '1.3em' }} />
        Add Formula
      </Button>
      <Modal
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<h3 className='title'>Add Formula</h3>}
      >
        <FormulaForm onCancel={closeModal} onSubmit={handleAddFormula} />
      </Modal>
    </div>
  );
};

export default AddFormula;
