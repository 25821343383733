import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { Avatar, Table, Button, Modal, Tag, Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ITestParameter } from '../../../interfaces/testParameter';
import TestParameterForm from './AddTestParameter/TestParameterForm';

type TestParametersProps = {
  testParameters: ITestParameter[];
  onEditTestParameter?: (testParameter: ITestParameter) => void;
  onDeleteTestParameter?: (id: string) => void;
};

type TestParameterImgProps = {
  img: string;
};

const TestParameterImg = ({ img }: TestParameterImgProps) => (
  <Avatar
    size={40}
    src={
      img.startsWith('data:image') || img.startsWith('http')
        ? img
        : `${window.location.origin}/${img}`
    }
  />
);

const TestParameterTable = ({
  testParameters,
  onEditTestParameter = () => null,
  onDeleteTestParameter = () => null
}: TestParametersProps) => {
  const navigate = useNavigate();

  const [testParameter, setTestParameter] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const closeModal = () => setVisibility(false);

  const handleShowInfo = () => navigate('/test-parameter-profile');
  const handleDeleteTestParameter = (id) => onDeleteTestParameter(id);
  const handleEditTestParameter = (testParameter: ITestParameter) => {
    setTestParameter(testParameter);
    setVisibility(true);
  };

  const actions = (testParameter: ITestParameter) => (
    <div className='buttons-list nowrap'>
      <Button
        onClick={handleEditTestParameter.bind({}, testParameter)}
        shape='circle'
        type='primary'
      >
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Popconfirm
        title='Are you sure to delete this row?'
        okText='Yes'
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={() => handleDeleteTestParameter(testParameter.id)}
      >
        <Button shape='circle' danger>
          <span className='icofont icofont-ui-delete' />
        </Button>
      </Popconfirm>
      {/* <Button onClick={handleDeleteTestParameter.bind({}, testParameter.id)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button> */}
    </div>
  );

  const columns: ColumnProps<ITestParameter>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (id) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {id}
        </span>
      )
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      render: (status) => (
        <Tag style={{ borderRadius: 20 }} color={status === 'Approved' ? '#b7ce63' : '#cec759'}>
          {status}
        </Tag>
      ),
      sorter: (a, b) => (a.status > b.status ? 1 : -1)
    },
    {
      key: 'actions',
      title: 'Actions',
      render: actions
    }
  ];

  const pagination = testParameters.length <= 10 ? false : {};

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={testParameters}
        columns={columns}
      />

      <Modal
        open={visibility}
        footer={null}
        onCancel={closeModal}
        title={<h3 className='title'>Add Test parameter</h3>}
      >
        <TestParameterForm
          submitText='Update Test parameter'
          onCancel={closeModal}
          onSubmit={onEditTestParameter}
          testParameter={testParameter}
        />
      </Modal>
    </>
  );
};

export default TestParameterTable;
