import { IForms } from '../../interfaces/forms';

export const SET_FORMS = '[forms] Set';
export const ADD_FORMS = '[forms] Add';
export const EDIT_FORMS = '[forms] Edit';
export const DELETE_FORMS = '[forms] Delete';

export interface SetFormsAction {
  type: typeof SET_FORMS;
  payload: IForms[];
}

export interface AddFormsAction {
  type: typeof ADD_FORMS;
  payload: IForms;
}

export interface EditFormsAction {
  type: typeof EDIT_FORMS;
  payload: IForms;
}

export interface DeleteFormsAction {
  type: typeof DELETE_FORMS;
  id: string;
}


export type FormsActions = SetFormsAction | AddFormsAction | DeleteFormsAction | EditFormsAction;
