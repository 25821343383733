import { IOrgan } from '../../interfaces/organ';

export const SET_ORGANS = '[organ] Set';
export const ADD_ORGAN = '[organ] Add';
export const EDIT_ORGAN = '[organ] Edit';
export const DELETE_ORGAN = '[organ] Delete';

export interface SetOrganAction {
  type: typeof SET_ORGANS;
  payload: IOrgan[];
}

export interface AddOrganAction {
  type: typeof ADD_ORGAN;
  payload: IOrgan;
}

export interface EditOrganAction {
  type: typeof EDIT_ORGAN;
  payload: IOrgan;
}

export interface DeleteOrganAction {
  type: typeof DELETE_ORGAN;
  _id: string;
}

export type OrgansActions = SetOrganAction | AddOrganAction | DeleteOrganAction | EditOrganAction;
