import React, { useEffect, useState } from 'react';
import { Table, Input, Collapse, Select, Button, Popconfirm, message, Dropdown, Menu } from 'antd';
import { IFinding } from '../../../interfaces/finding';
import { getAllFindingCategoriesUrl, getAllFindingsUrl } from '../../../constants/url';
import { deleteFinding } from './api';
import {
  CloseOutlined,
  MinusCircleOutlined,
  QuestionCircleOutlined,
  SettingOutlined
} from '@ant-design/icons';

const { TextArea } = Input;
const { Panel } = Collapse;

interface MyComponentProps {
  findingData: IFinding[];
  setFindingData: React.Dispatch<React.SetStateAction<IFinding[]>>;
}

const EditFinding: React.FC<MyComponentProps> = ({ findingData, setFindingData }) => {
  const [hasEffectRun, setHasEffectRun] = useState(false);

  useEffect(() => {
    if (findingData !== null && findingData.length > 0 && !hasEffectRun) {
      const updatedFindingData = findingData.map((category) => {
        const updatedCategory = {
          ...category,
          findings: category.findings.map((finding) => ({
            ...finding,
            multiple: finding.details && finding.details.length > 1
          }))
        };
        return updatedCategory;
      });

      setFindingData(updatedFindingData);
      setHasEffectRun(true);
    }
  }, [findingData, setFindingData, hasEffectRun]);

  const genExtra = (categoryId, categoryIndex) => {
    const menu = (
      <Menu
        onClick={(e) => {
          e.domEvent.stopPropagation();
        }}
      >
        <Menu.Item key='delete' className='menuItem'>
          <Popconfirm
            title='Are you sure you want to delete this Finding category along with all findings?'
            okText='Yes'
            onConfirm={() => handleCategoryDelete(categoryId, categoryIndex)}
            cancelText='No'
          >
            <span style={{ cursor: 'pointer' }}>Delete Section</span>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        <Dropdown overlay={menu} trigger={['click']}>
          <Button
            type='link'
            style={{ fontSize: '1.3em' }}
            className='settingButton'
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <SettingOutlined />
          </Button>
        </Dropdown>
      </>
    );
  };

  const handleCategoryDelete = async (categoryId, categoryIndex) => {
    if (categoryId) {
      try {
        const delCategorynRes = await deleteFinding(getAllFindingCategoriesUrl, categoryId);
        if (delCategorynRes.status === 200) {
          const updatedFindingData = [...findingData];
          updatedFindingData.splice(categoryIndex, 1);
          setFindingData(updatedFindingData);
          message.success('Category deleted successfully');
        }
      } catch (error) {
        console.error('Failed to fetch data');
      }
    } else {
      const updatedFindingData = [...findingData];
      updatedFindingData.splice(categoryIndex, 1);
      setFindingData(updatedFindingData);
      message.success('Category deleted successfully');
    }
  };

  const handleFindingDataChange = (categoryIndex, index, property, value) => {
    const updatedFindingData = findingData.map((finding, i) => {
      if (i === categoryIndex) {
        const updatedFindings = finding.findings.map((item, j) => {
          if (j === index) {
            if (property === 'multiple' && !value) {
              return {
                ...item,
                [property]: value,
                details: item.details.slice(0, 1)
              };
            }
            return { ...item, [property]: value, updated: true };
          }
          return item;
        });
        return { ...finding, findings: updatedFindings };
      }
      return finding;
    });

    setFindingData(updatedFindingData);
  };

  const handleDetailChange = (categoryIndex, findingIndex, detailIndex, property, value) => {
    const updatedFindingData = findingData.map((category, i) => {
      if (i === categoryIndex) {
        const updatedFindings = category.findings.map((finding, j) => {
          if (j === findingIndex) {
            const updatedDetails = finding.details.map((detail, k) => {
              if (k === detailIndex) {
                return { ...detail, [property]: value };
              }
              return detail;
            });
            return { ...finding, details: updatedDetails, updated: true };
          }
          return finding;
        });
        return { ...category, findings: updatedFindings };
      }
      return category;
    });

    setFindingData(updatedFindingData);
  };

  const handleAddDetail = (categoryIndex, findingIndex) => {
    const updatedFindingData = findingData.map((category, i) => {
      if (i === categoryIndex) {
        const updatedFindings = category.findings.map((finding, j) => {
          if (j === findingIndex) {
            const updatedDetails = [...finding.details, { heading: '', description: '' }];
            return { ...finding, details: updatedDetails, updated: true };
          }
          return finding;
        });
        return { ...category, findings: updatedFindings };
      }
      return category;
    });

    setFindingData(updatedFindingData);
  };

  const handleRemoveFinding = async (categoryIndex, index, id) => {
    try {
      if (id) {
        const delFindingRes = await deleteFinding(getAllFindingsUrl, id);
      }

      const updatedFindingData = [...findingData];
      updatedFindingData[categoryIndex].findings.splice(index, 1);

      setFindingData(updatedFindingData);
      message.success('Finding deleted successfully');
    } catch (error) {
      console.error('Failed to delete finding:', error);
      message.error('Failed to delete finding');
    }
  };

  const handleRemoveDetail = (categoryIndex, findingIndex, detailIndex) => {
    const updatedFindingData = findingData.map((category, i) => {
      if (i === categoryIndex) {
        const updatedFindings = category.findings.map((finding, j) => {
          if (j === findingIndex) {
            const updatedDetails = finding.details.filter((detail, k) => k !== detailIndex);
            return { ...finding, details: updatedDetails, updated: true };
          }
          return finding;
        });
        return { ...category, findings: updatedFindings };
      }
      return category;
    });

    setFindingData(updatedFindingData);
  };

  const handleAddFinding = (categoryIndex) => {
    const newFinding = {
      _id: '',
      title: 'New Finding',
      findingSectionId: '',
      findingCategoryId: '',
      details: [{ heading: '', description: '' }],
      formId: '',
      deleted: false,
      multiple: false
    };

    const updatedFindingData = findingData.map((category, index) => {
      if (categoryIndex === index) {
        return {
          ...category,
          findings: [...category.findings, newFinding]
        };
      }
      return category;
    });

    setFindingData(updatedFindingData);
  };

  const handleCategoryTitleChange = (value, categoryIndex) => {
    const updatedFindingData = [...findingData];

    updatedFindingData[categoryIndex].title = value;
    updatedFindingData[categoryIndex].updated = true;

    setFindingData(updatedFindingData);
  };

  const handleAddCategory = () => {
    setFindingData((prevFindingData: IFinding[]) => [
      ...prevFindingData,
      {
        _id: '',
        title: 'New Category',
        findings: [],
        formId: '65c661522dd70f26b53e9b98',
        updated: true
      }
    ]);
  };

  return (
    <>
      <Collapse accordion style={{ marginTop: '1rem' }}>
        {findingData?.map((category, categoryIndex) => (
          <Panel
            header={category?.title}
            key={category?._id}
            extra={genExtra(category._id, categoryIndex)}
          >
            <div className='d-flex align-items-baseline'>
              <label htmlFor='categoryTitle'>Category title</label>
              <Input
                type='text'
                id='categoryTitle'
                className='ml-2'
                value={category?.title}
                onChange={(e) => handleCategoryTitleChange(e.target.value, categoryIndex)}
              />
            </div>
            <div className='mt-2'>
              {findingData.length > 0 && (
                <table className='table table_field'>
                  <thead className='thead-dark'>
                    <tr>
                      <th scope='col'>Heading</th>
                      <th scope='col'>Multiple</th>
                      <th scope='col'>Label</th>
                      <th scope='col'>Description</th>
                      <th scope='col'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {category.findings.map((finding, findingIndex) => (
                      <tr key={findingIndex}>
                        <td>
                          <Input
                            value={finding.title}
                            onChange={(e) =>
                              handleFindingDataChange(
                                categoryIndex,
                                findingIndex,
                                'title',
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type='checkbox'
                            checked={finding.multiple}
                            onChange={() =>
                              handleFindingDataChange(
                                categoryIndex,
                                findingIndex,
                                'multiple',
                                !finding.multiple
                              )
                            }
                          />
                        </td>
                        {finding.multiple && (
                          <td>
                            {finding.details.map((detail, detailIndex) => (
                              <div style={{ position: 'relative' }}>
                                <Input
                                  key={detailIndex}
                                  style={{ marginBottom: '1rem' }}
                                  value={detail.heading}
                                  disabled={!finding.multiple}
                                  onChange={(e) =>
                                    handleDetailChange(
                                      categoryIndex,
                                      findingIndex,
                                      detailIndex,
                                      'heading',
                                      e.target.value
                                    )
                                  }
                                />
                                {finding.multiple && (
                                  <Button
                                    type='link'
                                    style={{ background: '#635f5f !important' }}
                                    className='deleteButton'
                                    onClick={() =>
                                      handleRemoveDetail(categoryIndex, findingIndex, detailIndex)
                                    }
                                  >
                                    <CloseOutlined style={{ height: '14px' }} />
                                  </Button>
                                )}
                              </div>
                            ))}

                            <Button
                              type='link'
                              onClick={() => handleAddDetail(categoryIndex, findingIndex)}
                            >
                              Add Label
                            </Button>
                          </td>
                        )}
                        <td colSpan={finding.multiple ? 1 : 2}>
                          {finding.details.map((detail, detailIndex) => (
                            <>
                              <TextArea
                                key={detailIndex}
                                style={{ marginBottom: '1rem' }}
                                rows={2}
                                value={detail.description}
                                onChange={(e) =>
                                  handleDetailChange(
                                    categoryIndex,
                                    findingIndex,
                                    detailIndex,
                                    'description',
                                    e.target.value
                                  )
                                }
                              />
                              {/* <Button
                              type='link'
                              onClick={() =>
                                handleRemoveDetail(categoryIndex, findingIndex, detailIndex)
                              }
                            >
                              Remove Label
                            </Button> */}
                            </>
                          ))}
                        </td>
                        <td>
                          <Popconfirm
                            title='Are you sure to delete this finding?'
                            okText='Yes'
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={() =>
                              handleRemoveFinding(categoryIndex, findingIndex, finding._id)
                            }
                          >
                            <Button
                              type='primary'
                              style={{
                                background: 'transparent',
                                border: 'none',
                                color: 'red',
                                boxShadow: 'none',
                                fontSize: '1.5em'
                              }}
                            >
                              <i className='icofont-ui-delete'></i>
                            </Button>
                          </Popconfirm>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <Button
                type='primary'
                onClick={(e) => handleAddFinding(categoryIndex)}
                style={{ marginTop: '1rem' }}
              >
                Add New Finding
              </Button>
            </div>
          </Panel>
        ))}
      </Collapse>
      <Button type='primary' onClick={() => handleAddCategory()} style={{ marginTop: '1em' }}>
        Add Category
      </Button>
    </>
  );
};

export default EditFinding;
