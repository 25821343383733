import { Button, DatePicker, Input, Select, message } from 'antd';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { calculateBSA } from '../../../constants/functions';
import {
  getAllSpecieURL,
  getFormByOrganURL,
  getOrganBySpecieURL,
  getPatientByIdURL
} from '../../../constants/url';
import { usePageData } from '../../../hooks/usePage';
import { usePatients } from '../../../hooks/usePatients';
import { IPageData } from '../../../interfaces/page';
import AddPatient from '../../../layout/components/patients/AddPatient';
import { fetchPatients } from '../../../redux/patients/actions';
import { fetchSpecie } from '../../../redux/specie/actions';
import './PatientReportForm.scss';
import { getFormByOrgan, getOrganBySpecie } from './api';
import DataTable from './form';

const { Option } = Select;

const pageData: IPageData = {
  title: 'Create Report',
  fulFilled: true
};
interface FormValues {
  name: string;
  dob: any;
  age: any;
  patientId: string;
  gender: string;
  weight: any;
  bsa: any;
  date: any;
  sonographer: string;
  ref_doctor: string;
  breed: any;
  caseNo: any;
  forms: any;
  specie: any;
  email: string;
}

const initialValues: FormValues = {
  name: '',
  dob: '',
  age: '',
  patientId: '',
  gender: '',
  weight: '',
  bsa: '',
  date: '',
  sonographer: '',
  ref_doctor: '',
  breed: '',
  caseNo: '',
  forms: '',
  specie: '',
  email: ''
};

const PatientReportForm: React.FC = () => {
  const [selectedPatient, setSelectedPatient] = useState<any | null>(null);
  const [organList, setOrganList] = useState([]);
  const [formList, setFormList] = useState([]);

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [showDataTable, setShowDataTable] = useState(false);
  const [formValuesForAPI, setFormValuesForAPI] = useState<FormValues>(initialValues);
  const [formCreated, setFormCreated] = useState(false);
  const [isCreateClicked, setIsCreateClicked] = useState(false);
  const [reset, setReset] = useState(false);

  const handleSelectPatient = (value: string) => {
    const selectedPatientObj = patients.find((patient) => patient?._id === value) || null;
    setSelectedPatient(selectedPatientObj);
    setSelectedOrgan('');
  };

  const { patients } = usePatients();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  let doctor_id = localStorage.getItem('doctor_id');
  const [selectedOrgan, setSelectedOrgan] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        await dispatch(fetchPatients(`${getPatientByIdURL}/${doctor_id}`, headers));
        await dispatch(fetchSpecie(getAllSpecieURL, headers));
      } catch (error) {
        message.error('Failed to fetch patient data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    setShowAdditionalFields(!!selectedPatient);
  }, [selectedPatient]);

  useEffect(() => {
    if (selectedPatient) {
      const updatedFormValues: FormValues = {
        ...initialValues,
        email: selectedPatient.email || '',
        name: selectedPatient.name || '',
        dob: selectedPatient.dob || null,
        age: selectedPatient.age || null,
        patientId: selectedPatient.patient_id || '',
        gender: selectedPatient.sex || '',
        bsa: selectedPatient.bsa || '',
        weight: selectedPatient.weight || 0,
        date: selectedPatient.registration_date || null,
        sonographer: selectedPatient.doctor_id?.fullName || '',
        ref_doctor: selectedPatient.ref_doctor || '',
        breed: selectedPatient.breed || '',
        caseNo: selectedPatient.case_number || '',
        forms: selectedPatient.forms || '',
        specie: selectedPatient.specie?.name || ''
      };
      setFormValues(updatedFormValues);

      const getOrganList = async () => {
        const ids = [selectedPatient?.specie?._id];
        try {
          const response = await getOrganBySpecie(getOrganBySpecieURL + '', { ids });
          setOrganList(response?.data?.data[0]);
        } catch (error) {
          console.error('Error fetching organs:', error);
        }
      };
      getOrganList();
    }
  }, [selectedPatient]);

  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleSubmit = (values: FormValues) => {
    if (values.forms === '' || values.forms === null) {
      message.error('Please select form first');
    } else {
      setIsCreateClicked(true);
      if (selectedPatient) {
        const updatedPatient = {
          ...selectedPatient,
          case_number: values?.caseNo,
          weight: values?.weight,
          bsa: calculateBSA(values?.weight).toString(),
          age: values?.age,
          ref_doctor: values?.ref_doctor
        };
        setSelectedPatient(updatedPatient);
      }
      setFormValuesForAPI(values);
      setIsCreateClicked(false);
      setFormCreated(true);
      setShowDataTable(true);
    }
  };
  const handleSelctOrgan = async (value, obj, setFieldValue) => {
    const ids = [selectedPatient?.specie?._id];
    setFieldValue('forms', null);
    setSelectedOrgan(obj?.name);
    try {
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const response = await getFormByOrgan(getFormByOrganURL + value, headers);

      setFormList(response?.data?.data);
      // setOrganList(response?.data?.data[0]);
    } catch (error) {
      console.error('Error fetching organs:', error);
    }
  };
  usePageData(pageData);

  const handleCalculateBSA = () => {
    if (selectedPatient) {
      const updatedPatient = {
        ...selectedPatient,
        bsa: calculateBSA(selectedPatient.weight).toString()
      };
      setSelectedPatient(updatedPatient);
    }
  };

  useEffect(() => {
    if (selectedPatient?.bsa === '') {
      handleCalculateBSA();
    }
  }, [selectedPatient]);

  useEffect(() => {
    if (reset) {
      setFormCreated(false);
      setSelectedPatient(null);
      setShowDataTable(false);
      setReset(false);
    }
  }, [reset]);

  return (
    <>
      <Formik initialValues={formValues} enableReinitialize onSubmit={handleSubmit}>
        {(formikProps) => {
          const { values, setFieldValue } = formikProps;

          return (
            <div className='patient_report_form_parent'>
              <Form className='patient-report-form'>
                <div className='form-group form_label_div'>
                  <label htmlFor='selectPatient'>Patient</label>
                  <Select
                    id='selectPatient'
                    showSearch
                    className='mb-lg-0 mb-4'
                    placeholder='Select Patient'
                    optionFilterProp='children'
                    filterOption={optionFilter}
                    onChange={handleSelectPatient}
                    disabled={formCreated}
                  >
                    {patients.map((item) => (
                      <Option key={item?._id} value={item?._id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                {showAdditionalFields && (
                  <div className='additional-fields'>
                    <div className='row'>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='breed'>Breed</label>
                          <Input id='breed' placeholder='Breed' value={values.breed} disabled />
                        </div>
                      </div>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='dob'>DOB</label>
                          <Input
                            id='dob'
                            placeholder='Dob'
                            value={values.dob ? values.dob : null}
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='email'>Email</label>
                          <Input
                            id='email'
                            placeholder='Email'
                            value={values.email ? values.email : null}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='gender'>Sex</label>
                          <Input
                            id='gender'
                            placeholder='gender'
                            value={values.gender ? values.gender : null}
                            style={{ width: '5rem' }}
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='age'>AGE</label>
                          <Input
                            id='age'
                            min={0}
                            placeholder='age'
                            value={values?.age}
                            onChange={(e) => setFieldValue('age', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='patientId'>Patient ID</label>
                          <Input
                            id='patientId'
                            placeholder='Patient ID'
                            value={values.patientId}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='sonographer'>Doc.</label>
                          <Input
                            id='sonographer'
                            placeholder='Sonographer'
                            value={values.sonographer}
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='refDoc'>Ref. Doc</label>
                          <Input
                            id='refDoc'
                            placeholder='Ref. Doc'
                            value={values.ref_doctor}
                            onChange={(e) => setFieldValue('ref_doctor', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='date'>Date</label>
                          <Field
                            id='date'
                            name='date'
                            as={DatePicker}
                            className='your-input-class'
                            format='YYYY-MM-DD'
                            value={values.date ? moment(values.date) : null}
                            onChange={(date: any | null, dateString: string) =>
                              setFieldValue('date', dateString)
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='caseNo'>Case No.</label>
                          <Input
                            type='number'
                            id='caseNo'
                            min={0}
                            placeholder='Case Number'
                            value={values.caseNo}
                            onChange={(e) => setFieldValue('caseNo', e.target.value)}
                            disabled={formCreated}
                          />
                        </div>
                      </div>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='weight'>Weight</label>
                          <Input
                            type='text'
                            id='weight'
                            placeholder='kg'
                            value={values.weight}
                            onChange={(value) => {
                              const isValidNumber = /^\d*\.?\d*$/.test(value.target.value);
                              if (
                                isValidNumber ||
                                value.target.value === '' ||
                                value.target.value === '0'
                              ) {
                                setFieldValue('weight', value.target.value);
                              }
                            }}
                            disabled={formCreated}
                          />
                        </div>
                      </div>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='bsa'>BSA</label>
                          <Input
                            id='bsa'
                            type='number'
                            placeholder='bsa'
                            value={calculateBSA(values.weight)}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='breed'>Specie</label>
                          <Input
                            id='specie'
                            placeholder='Specie'
                            value={values?.specie?._id ? values?.specie?._id : values?.specie}
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='organ'>Organ</label>
                          <Select
                            id='organ'
                            className='select_input'
                            placeholder='Select Organ'
                            value={selectedOrgan}
                            onChange={(value: string, obj) =>
                              handleSelctOrgan(value, obj, setFieldValue)
                            }
                            disabled={formCreated}
                            // onChange={(value: string) => setFieldValue('organ', value)}
                          >
                            {organList.map((item) => (
                              <Option key={item?._id} value={item?._id} name={item?.name}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className='col-sm-4 col-12'>
                        <div className='form-group form_label_div'>
                          <label htmlFor='forms'>Forms</label>
                          <Select
                            id='forms'
                            placeholder='forms'
                            className='select_input'
                            value={values.forms}
                            onChange={(value: string) => setFieldValue('forms', value)}
                            disabled={formCreated}
                          >
                            {formList.map((item) => (
                              <Option key={item?._id} value={item?._id}>
                                {item?.title}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {selectedPatient && !formCreated && (
                  <div className='form-actions d-flex justify-content-end'>
                    <Button loading={isCreateClicked} type='primary' htmlType='submit'>
                      {!isCreateClicked && <i className='icofont-plus-circle'></i>} Create Report
                    </Button>
                  </div>
                )}
              </Form>
              {!selectedPatient && (
                <div className='create_report_add_patient'>
                  <AddPatient />
                </div>
              )}
            </div>
          );
        }}
      </Formik>
      {showDataTable && (
        <DataTable formApi={formValuesForAPI.forms} patient={selectedPatient} reset={setReset} />
      )}
    </>
  );
};

export default PatientReportForm;
