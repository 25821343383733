import { ISpecie } from '../interfaces/specie';
import { useDispatch, useSelector } from 'react-redux';

import * as specieActions from '../redux/specie/actions';

import { IAppState } from '../interfaces/app-state';

export function useSpecies() {
  const dispatch = useDispatch();
  const species = useSelector<IAppState, ISpecie[]>((state) => state.species);

  const editSpecie = (specie: ISpecie) => {
    return dispatch(specieActions.editSpecie(specie));
  };

  const addSpecie = (specie: ISpecie) => {
    return dispatch(specieActions.addSpecie(specie));
  };

  const deleteSpecie = (id: string) => {
    return dispatch(specieActions.deleteSpecie(id));
  };

  return { species, addSpecie, editSpecie, deleteSpecie };
}
