import { LoadingOutlined, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';

import { Button, Collapse, Dropdown, Input, Menu, Popconfirm, Select, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';
import {
  formGroupUrl,
  getAllFieldURL,
  getAllFindingCategoriesUrl,
  getAllFindingsUrl,
  getAllFormURL,
  getAllRangeURL,
  getAllSectionURL,
  getAllSpecieURL,
  getOrganBySpecieURL,
  getSingleFormURL
} from '../../../constants/url';
import { useFormGroup } from '../../../hooks/useFormGroup';
import { usePageData } from '../../../hooks/usePage';
import { useSpecies } from '../../../hooks/useSpecie';
import { IFinding } from '../../../interfaces/finding';
import { IField, IForms, IRange, ISection } from '../../../interfaces/forms';
import { IPageData } from '../../../interfaces/page';
import { fetchFormGroups } from '../../../redux/formGroup/actions';
import { fetchSpecie } from '../../../redux/specie/actions';
import EditFinding from './EditFindings';

import {
  addField,
  addSection,
  deleteField,
  deleteRange,
  deleteSection,
  getForm,
  getOrganBySpecie,
  updateFindingData,
  deleteFindingData,
  updateFormData
} from './api';
import './form.scss';

const { Option } = Select;
const { Panel } = Collapse;

const EditForm = () => {
  const [formData, setFormData] = useState<IForms | null>(null);
  const [findingData, setFindingData] = useState<IFinding[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [updatedFieldIds, setUpdatedFieldIds] = useState([]);
  const [updatedRangeIds, setUpdatedRangeIds] = useState([]);
  const [updatedSectionTitles, setUpdatedSectionTitles] = useState([]);
  const [formTitle, setFormTitle] = useState('');
  const [organList, setOrganList] = useState([]);
  const [loadings, setLoadings] = useState(true);

  const [pageData, setPageData] = useState<IPageData>({
    title: 'Edit Form',
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Form',
        route: 'form'
      },
      {
        title: 'Edit Form'
      }
    ]
  });
  const { id } = useParams();
  const navigate = useNavigate();
  usePageData(pageData);
  const dispatch = useDispatch();

  const { species } = useSpecies();
  const { formGroups } = useFormGroup();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        const [formRes, newRes] = await Promise.all([
          getForm(getSingleFormURL, id),
          getForm(`${getAllFindingCategoriesUrl}/getbyform/`, id),
          dispatch(fetchSpecie(getAllSpecieURL, headers)),
          dispatch(fetchFormGroups(formGroupUrl, headers))
        ]);
        setPageData((prevData) => ({
          ...prevData,
          breadcrumbs: [
            ...prevData.breadcrumbs.slice(0, -1),
            {
              title:
                formRes.data.data?.title ||
                prevData.breadcrumbs[prevData.breadcrumbs.length - 1].title
            }
          ]
        }));

        setFormData(formRes.data.data);
        setFormTitle(formRes.data.data.title);
        setFindingData(newRes?.data?.data);
        setLoadings(false);
      } catch (error) {
        setError('Failed to fetch data');
      }
    };

    fetchData();
  }, [id]);

  const handleDeleteSection = async (sectionIndex, sectionId) => {
    if (sectionId) {
      try {
        const delSectionRes = await deleteSection(getAllSectionURL, sectionId);
        if (delSectionRes.status === 200) {
          message.success('Section deleted successfuly');
        }

        setFormData((prevFormData) => {
          const updatedSections = prevFormData.sections.filter(
            (section) => section._id !== sectionId
          );
          return {
            ...prevFormData,
            sections: updatedSections
          };
        });
      } catch (error) {
        setError('Failed to fetch data');
      }
    } else {
      const updatedSections = formData.sections.slice();
      updatedSections.splice(sectionIndex, 1);
      setFormData({ ...formData, sections: updatedSections });
      message.success('Section deleted successfuly');
    }
  };

  const genExtra = (sectionId, sectionIndex) => {
    const menu = (
      <Menu
        onClick={(e) => {
          e.domEvent.stopPropagation();
        }}
      >
        <Menu.Item key='delete' className='menuItem'>
          <Popconfirm
            title='Are you sure you want to delete this section?'
            onConfirm={() => handleDeleteSection(sectionIndex, sectionId)}
            okText='Yes'
            cancelText='No'
          >
            <span style={{ cursor: 'pointer' }}>Delete Section</span>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        <Dropdown overlay={menu} trigger={['click']}>
          <Button
            type='link'
            style={{ fontSize: '1.3em' }}
            className='settingButton'
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <SettingOutlined />
          </Button>
        </Dropdown>
      </>
    );
  };

  const handleFormTitleChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      title: value,
      updated: true
    }));
  };

  const handleSerialNumberChange = (
    value: any,
    sectionIndex: number,
    fieldIndex: number,
    property: string
  ) => {
    setFormData((prevFormData) => {
      const updatedSections = [...prevFormData.sections];
      updatedSections[sectionIndex].fields[fieldIndex].serial = value;

      updatedSections[sectionIndex].fields[fieldIndex][property] = value;
      updatedSections[sectionIndex].fields[fieldIndex].updated = true;
      return { ...prevFormData, sections: updatedSections };
    });
  };

  const handleFieldChange = (value, sectionIndex, fieldIndex, property) => {
    setFormData((prevFormData) => {
      const updatedSections = [...prevFormData.sections];
      const fieldId = updatedSections[sectionIndex].fields[fieldIndex]._id;

      if (!updatedFieldIds.includes(fieldId)) {
        setUpdatedFieldIds((prevIds) => [...prevIds, fieldId]);
      }

      updatedSections[sectionIndex].fields[fieldIndex][property] = value;
      updatedSections[sectionIndex].fields[fieldIndex].updated = true;
      return { ...prevFormData, sections: updatedSections };
    });
  };

  const handleRangeChange = (value, sectionIndex, fieldIndex, rangeIndex, property) => {
    setFormData((prevFormData) => {
      const updatedSections = [...prevFormData.sections];
      const rangeId = updatedSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex]._id;

      if (!updatedRangeIds.includes(rangeId)) {
        setUpdatedRangeIds((prevIds) => [...prevIds, rangeId]);
      }

      updatedSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex][property] = value;
      updatedSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].updated = true;
      return { ...prevFormData, sections: updatedSections };
    });
  };

  const handleSectionSerialChange = (serialNumber, sectionIndex) => {
    setUpdatedSectionTitles((prevSerial) => {
      if (!prevSerial.includes(sectionIndex)) {
        return [...prevSerial, sectionIndex];
      }
      return prevSerial;
    });

    setFormData((prevFormData) => {
      const updatedSections = [...prevFormData.sections];
      updatedSections[sectionIndex].serial = serialNumber;
      updatedSections[sectionIndex].updated = true;
      return { ...prevFormData, sections: updatedSections };
    });
  };
  const handleSectionTitleChange = (value, sectionIndex) => {
    setUpdatedSectionTitles((prevTitles) => {
      if (!prevTitles.includes(sectionIndex)) {
        return [...prevTitles, sectionIndex];
      }
      return prevTitles;
    });

    setFormData((prevFormData) => {
      const updatedSections = [...prevFormData.sections];
      updatedSections[sectionIndex].title = value;
      updatedSections[sectionIndex].updated = true;
      return { ...prevFormData, sections: updatedSections };
    });
  };

  const onFieldDelete = async (fieldId, sectionIndex, fieldIndex) => {
    if (fieldId) {
      try {
        const delFieldRes = await deleteField(getAllFieldURL, fieldId);
        if (delFieldRes.status === 200) {
          message.success('Field deleted successfuly');
        }

        setFormData((prevFormData) => {
          const updatedSections = prevFormData.sections.map((section) => {
            const updatedFields = section.fields.filter((field) => field._id !== fieldId);
            return {
              ...section,
              fields: updatedFields
            };
          });

          return {
            ...prevFormData,
            sections: updatedSections
          };
        });
      } catch (error) {
        setError('Failed to fetch data');
      }
    } else {
      const updatedFields = [...formData.sections[sectionIndex].fields];
      updatedFields.splice(fieldIndex, 1);

      setFormData((prevFormData) => {
        const updatedSections = [...prevFormData.sections];
        updatedSections[sectionIndex] = {
          ...updatedSections[sectionIndex],
          fields: updatedFields
        };

        return {
          ...prevFormData,
          sections: updatedSections
        };
      });
      message.success('Field deleted successfuly');
    }
  };
  const onFieldSave = async (sectionIndex, fieldIndex) => {
    if (formData?.sections[sectionIndex].fields[fieldIndex].title === '') {
      message.error('The Field Title is required');
      return;
    }
    if (formData?.sections[sectionIndex].fields[fieldIndex].heading === '') {
      message.error('The Field Input Type are mandatory');
      return;
    }
    if (
      formData?.sections[sectionIndex].fields[fieldIndex].heading === 'dropdown' &&
      formData?.sections[sectionIndex].fields[fieldIndex].dropdown.length === 0
    ) {
      message.error('Atleast one option is required');
      return;
    }
    if (
      formData?.sections[sectionIndex].fields[fieldIndex].heading === 'formula' &&
      formData?.sections[sectionIndex].fields[fieldIndex].formula === ''
    ) {
      message.error('The Field Formula is required');
      return;
    }
    const newFieldData = {
      title: formData?.sections[sectionIndex].fields[fieldIndex].title,
      heading: formData?.sections[sectionIndex].fields[fieldIndex].heading,
      sectionId: formData?.sections[sectionIndex]?._id,
      serial: formData?.sections[sectionIndex].fields[fieldIndex].serial,
      ranges: [],
      dropdown:
        formData?.sections[sectionIndex].fields[fieldIndex].heading === 'dropdown'
          ? formData?.sections[sectionIndex].fields[fieldIndex].dropdown
          : [],
      formula:
        formData?.sections[sectionIndex].fields[fieldIndex].heading === 'formula'
          ? formData?.sections[sectionIndex].fields[fieldIndex].formula
          : '',
      updated: 1
    };
    try {
      const fieldRes = await addField(getAllFieldURL + '', newFieldData);
      setFormData((prevFormData) => {
        const newSections = [...prevFormData.sections];
        const updatedFields = [...newSections[sectionIndex].fields];

        updatedFields[fieldIndex] = {
          ...updatedFields[fieldIndex],
          _id: fieldRes?.data?.data?.field._id,
          fieldId: fieldRes?.data?.data?.field.fieldId,
          title: fieldRes?.data?.data?.field.title,
          serial: fieldRes?.data?.data?.field.serial
        };
        newSections[sectionIndex] = {
          ...newSections[sectionIndex],
          fields: updatedFields
        };
        return {
          ...prevFormData,
          sections: newSections
        };
      });
      message.success('Field Created successfully');
    } catch (error) {
      setError('Failed to fetch data');
    }
  };
  const onSectionSave = async (sectionIndex) => {
    if (formData?.sections[sectionIndex].title === '') {
      message.error('The Section Title is required');
      return;
    }

    const newSectionData = {
      serial: formData?.sections[sectionIndex].serial,
      title: formData?.sections[sectionIndex].title,
      formId: formData?._id,
      fields: []
    };

    try {
      const sectionRes = await addSection(getAllSectionURL + '', newSectionData);
      setFormData((prevFormData) => {
        const newResponses = [...prevFormData.sections];
        newResponses[sectionIndex] = sectionRes?.data?.data?.section;

        const updatedFormSections = [...newResponses];
        updatedFormSections[sectionIndex] = {
          ...updatedFormSections[sectionIndex],
          _id: sectionRes?.data?.data?.section._id,
          sectionId: sectionRes?.data?.data?.section.sectionId,
          title: sectionRes?.data?.data?.section.title,
          serial: sectionRes?.data?.data?.section.serial
        };

        return {
          ...formData,
          sections: updatedFormSections
        };
      });
      message.success('Section Created successfully');
    } catch (error) {
      setError('Failed to fetch data');
    }
  };

  const onRangeDelete = async (range, sectionIndex, fieldIndex, rangeIndex) => {
    if (range) {
      try {
        const delRangeRes = await deleteRange(getAllRangeURL, range);

        setFormData((prevFormData) => {
          const updatedSections = prevFormData.sections.map((section) => {
            const updatedFields = section.fields.map((field) => {
              const updatedRanges = field.ranges.filter((rag) => rag._id !== range);
              return {
                ...field,
                ranges: updatedRanges
              };
            });
            return {
              ...section,
              fields: updatedFields
            };
          });

          return {
            ...prevFormData,
            sections: updatedSections
          };
        });
        if (delRangeRes.status === 200) {
          message.success('Range deleted successfully');
        }
      } catch (error) {
        setError('Failed to fetch data');
      }
    } else {
      const updatedRanges = [...formData.sections[sectionIndex].fields[fieldIndex].ranges];
      updatedRanges.splice(rangeIndex, 1);

      setFormData((prevFormData) => {
        const updatedSections = [...prevFormData.sections];
        const updatedFields = [...updatedSections[sectionIndex].fields];
        updatedFields[fieldIndex] = {
          ...updatedFields[fieldIndex],
          ranges: updatedRanges
        };

        updatedSections[sectionIndex] = {
          ...updatedSections[sectionIndex],
          fields: updatedFields
        };

        return {
          ...prevFormData,
          sections: updatedSections
        };
      });
      message.success('Range deleted successfuly');
    }
  };

  const handleAddSection = () => {
    setFormData((prevFormData: IForms) => ({
      ...prevFormData,
      sections: [
        ...prevFormData.sections,
        {
          title: '',
          fields: [] as IField[]
        } as ISection
      ]
    }));
  };

  const handleAddField = (sectionIndex: number) => {
    setFormData((prevFormData: IForms) => {
      const updatedSections = [...prevFormData.sections];
      updatedSections[sectionIndex].fields.push({
        title: '',
        heading: '',
        ranges: [] as IRange[],
        dropdown: [],
        formula: ''
      } as IField);
      return { ...prevFormData, sections: updatedSections };
    });
  };

  const handleAddRange = (sectionIndex: number, fieldIndex: number) => {
    setFormData((prevFormData: IForms) => {
      const updatedSections = [...prevFormData.sections];
      updatedSections[sectionIndex].fields[fieldIndex].ranges.push({
        title: '',
        formula: '',
        rangeValue: ''
      } as IRange);
      return { ...prevFormData, sections: updatedSections };
    });
  };

  const onSaveForm = async () => {
    setLoadings(true);
    try {
      if (formData?.sections?.some((section) => section.title === '')) {
        message.error('Section Title Required');
        return;
      }

      if (
        formData?.sections?.some((section) => section.fields.some((field) => field.title === '')) ||
        formData?.sections?.some((section) => section.fields.some((field) => field?.heading === ''))
      ) {
        message.error('Field Title and Type Required');
        return;
      }

      if (
        formData?.sections?.some((section) =>
          section.fields.some((field) => field.ranges.some((range) => range.title === ''))
        )
      ) {
        message.error('Range Title Required');
        return;
      }

      const formDataRes = await updateFormData(`${getAllFormURL}/${id}`, formData);
     // const findingDataRes = await updateFindingData(
     //   `${getAllFindingsUrl}/form/${id}`,
     //   findingData
     // );
      const formDeleteDataRes = await deleteFindingData(
        `${getAllFindingsUrl}/deltebyform/${id}`,
        findingData
      );

      let findingDataRes = { status: 404 }

      if (formDeleteDataRes.status === 200 && formDeleteDataRes.status === 200) {
        const findingDataChunk = 5;
        const findingsDataLength = findingData.length;
        const findingsLength = Math.ceil(findingData.length/findingDataChunk);
        console.log(findingsLength,"findingsLength");

        if(findingsLength > 1){
          // Step 3: Combine into an array
          const arrayOfJsonObjects = [];
          for (let i = 0; i <= (findingData.length+(findingDataChunk-1)); i += findingDataChunk) {

            if(findingData.slice(i, i + findingDataChunk).length > 0){
              arrayOfJsonObjects.push(findingData.slice(i, i + findingDataChunk));

              const jsonString = JSON.stringify(arrayOfJsonObjects[i]);

              // Step 2: Calculate string size
              const stringSizeInBytes = new Blob([jsonString]).size;

              // Step 3: Convert to kilobytes
              const sizeInKilobytes = stringSizeInBytes / 1024;

              console.log('Size of findingData:', sizeInKilobytes.toFixed(2), 'KB');

            }

          }

          // Printing the result
          console.log(arrayOfJsonObjects);

          for(let i = 0; i <= arrayOfJsonObjects.length; i++){

            console.log(typeof arrayOfJsonObjects[i], "object type"+i);

            if(arrayOfJsonObjects[i] !== undefined){

              if(typeof arrayOfJsonObjects[i] === "object"){
                console.log(Object.keys(arrayOfJsonObjects[i]).length, "Obj length");
                if(Object.keys(arrayOfJsonObjects[i]).length > 0){
                  findingDataRes = await updateFindingData(
                    `${getAllFindingsUrl}/form/${id}`,
                    arrayOfJsonObjects[i]
                  );
                }
              }
            }



          }
        }else{

          findingDataRes = await updateFindingData(
            `${getAllFindingsUrl}/form/${id}`,
            findingData
          );
        }

      }

// console.log(findingDataRes, "findingDataRes");


        if (formDataRes.status === 200) {
          message.success('Form data updated successfully!');
          setLoadings(false);
          navigate('/form');
        } else {
          message.error('Failed to update form data!');
        }


      // if (formDataRes.status === 200 && findingDataRes.status === 200) {
      //   message.success('Form data updated successfully!');
      //   setLoadings(false);
      //   navigate('/form');
      // } else {
      //   message.error('Failed to update form data!');
      // }
    } catch (error) {
      console.error('Failed to update form data:', error);
      message.error('Failed to update form data!');
    } finally {
      setLoadings(false);
    }
  };

  const handleSpeciesChange = async (value, option) => {
    if (option) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        specieId: {
          _id: value,
          name: option.children
        },
        organId: {
          _id: '',
          name: ''
        }
      }));

      const ids = [value];
      try {
        const response = await getOrganBySpecie(getOrganBySpecieURL + '', { ids });
        setOrganList(response?.data?.data[0]);
      } catch (error) {
        console.error('Error fetching organs:', error);
      }
    }
  };

  const handleOrganChange = async (value, option) => {
    if (option) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        organId: {
          _id: value,
          name: option.children
        },
        updated: true
      }));
    }
  };
  const handleFormGroupChange = async (value, option) => {
    if (option) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        category: {
          _id: value,
          title: option.children
        },
        updated: true
      }));
    }
  };

  return (
    <>
      <Spin
        spinning={loadings}
        size='large'
        indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
      >
        <div className='form-header flex-column'>
          <div className='row'>
            <div className='col-sm-6 col-12'>
              <div className='form-group form_label_div'>
                <label htmlFor='speciesInput'>Species:</label>
                <Select
                  id='organInput'
                  placeholder='Species'
                  value={formData?.specieId.name}
                  onChange={handleSpeciesChange}
                >
                  {species.map((item) => (
                    <Select.Option key={item?._id} value={item._id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className='col-sm-6 col-12'>
              <div className='form-group form_label_div'>
                <label htmlFor='organInput'>Organ:</label>
                <Select
                  id='organInput'
                  placeholder='Organ'
                  value={formData?.organId.name}
                  onChange={handleOrganChange}
                >
                  {organList.map((item) => (
                    <Select.Option key={item?._id} value={item._id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6 col-12'>
              <div className='form-group form_label_div'>
                <label htmlFor='form-formGroup'>Group:</label>
                <Select
                  id='form-formGroup'
                  placeholder='Select Form Group'
                  value={
                    formData?.category?._id ? formData?.category?._id : formData?.category?.title
                  }
                  onChange={handleFormGroupChange}
                >
                  {formGroups.map((item) => {
                    return (
                      <Option key={item?._id} value={item._id}>
                        {item?.title}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className='col-sm-6 col-12'>
              <div className='form-group form_label_div'>
                <label htmlFor='formNameInput'>Name:</label>
                <Input
                  type='text'
                  id='formTitle'
                  // className='ml-2'
                  value={formData?.title}
                  onChange={(e) => handleFormTitleChange(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        {!loadings && (
          <>
            <div className='sectionStyle'>
              <span className='floatHeading'>Sections</span>
              <Collapse accordion style={{ marginTop: '1rem' }} className='overflow-auto'>
                {formData?.sections.map((section, mainIndex) => {
                  return (
                    <Panel
                      header={`${section?.title}`}
                      key={section._id}
                      extra={genExtra(section._id, mainIndex)}
                      className='overflow-auto'
                    >
                      <div className='row'>
                        <div className='col-sm-2 col-2'>
                          <div className='d-flex align-items-baseline'>
                            <label htmlFor='formSerial'>Serial</label>
                            <Input
                              type='text'
                              id='formSerial'
                              className='ml-2'
                              value={section?.serial}
                              onChange={(e) => handleSectionSerialChange(e.target.value, mainIndex)}
                              onKeyDown={(e) => {
                                    // Allow: backspace, delete, tab, escape, enter
                                      if (
                                        [46, 8, 9, 27, 13].includes(e.keyCode) ||
                                        // Allow: home, end, left, right
                                        (e.keyCode >= 35 && e.keyCode <= 39)
                                      ) {
                                        // Let it happen, don't do anything
                                        return;
                                      }
                                      // Ensure that it is a number and stop the keypress
                                      if (
                                        (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
                                        (e.keyCode < 96 || e.keyCode > 105)
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    style={{ 
                                    maxWidth: '70px',
                                    minWidth: '40px',
                                    padding: '0px'
                                    }}
                            />
                          </div>
                        </div>
                        <div className='col-sm-10 col-10'>
                          <div className='d-flex align-items-baseline'>
                            <label htmlFor='formTitle'>Title</label>
                            <Input
                              type='text'
                              id='formTitle'
                              className='ml-2'
                              value={section?.title}
                              onChange={(e) => handleSectionTitleChange(e.target.value, mainIndex)}
                            />
                            {!section?._id && (
                                <Button
                                  type='primary'
                                  shape='circle'
                                  style={{
                                    boxShadow: 'none',
                                    marginLeft: '1rem'
                                  }}
                                  onClick={() => onSectionSave(mainIndex)}
                                >
                                  <i className='icofont-save'></i>
                                </Button>
                              )}
                          </div>
                        </div>
                      </div>
                      
                      {section?.fields.map((field, fieldIndex) => {
                        return (
                          <div
                            key={field._id}
                            className='mt-4  '
                            style={{ width: '100%', overflowY: 'scroll' }}
                          >
                            <table className='table table_field  mb-0'>
                              <thead className='thead-dark'>
                                <tr>
                                  <th scope='col' style={{ width: '10%' }}>
                                    Serial No.
                                  </th>
                                  <th scope='col' style={{ width: '20%' }}>
                                    Title - <span style={{ color: 'red' }}>{field?.fieldId}</span>
                                  </th>
                                  <th scope='col' style={{ width: '20%' }}>
                                    Type
                                  </th>
                                  {field?.heading === 'dropdown' && (
                                    <th scope='col' style={{ width: '20%' }}>
                                      Options
                                    </th>
                                  )}
                                  {field?.heading === 'formula' && (
                                    <th scope='col' style={{ width: '25%' }}>
                                      Formula
                                    </th>
                                  )}
                                  <th scope='col' style={{ width: '25%' }} className='text-center'>
                                    Actions
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td>
                                  <Input
                                    type='text'
                                    value={field?.serial}
                                    pattern="[0-9]*" 
                                    inputMode="numeric"
                                    onChange={(e) =>
                                      handleSerialNumberChange(
                                        e.target.value,
                                        mainIndex,
                                        fieldIndex,
                                        'serial'
                                      )
                                    }
                                    onKeyDown={(e) => {
                                    // Allow: backspace, delete, tab, escape, enter
                                      if (
                                        [46, 8, 9, 27, 13].includes(e.keyCode) ||
                                        // Allow: home, end, left, right
                                        (e.keyCode >= 35 && e.keyCode <= 39)
                                      ) {
                                        // Let it happen, don't do anything
                                        return;
                                      }
                                      // Ensure that it is a number and stop the keypress
                                      if (
                                        (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
                                        (e.keyCode < 96 || e.keyCode > 105)
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    style={{ 
                                    maxWidth: '70px',
                                    minWidth: '40px',
                                    padding: '0px'
                                    }}
                                  />
                                  </td>
                                  <td>
                                    <Input
                                      value={field?.title}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          e.target.value,
                                          mainIndex,
                                          fieldIndex,
                                          'title'
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Select
                                      id='field-type'
                                      value={field?.heading}
                                      onChange={(value) =>
                                        handleFieldChange(value, mainIndex, fieldIndex, 'heading')
                                      }
                                      placeholder='Input Type'
                                    >
                                      <Option key='select' value={'dropdown'}>
                                        {'DropDown'}
                                      </Option>
                                      <Option key='input' value={'input'}>
                                        {'input'}
                                      </Option>
                                      <Option key='text' value={'text'}>
                                        {'text'}
                                      </Option>
                                      <Option key='formula' value={'formula'}>
                                        {'formula'}
                                      </Option>
                                    </Select>
                                  </td>
                                  {field?.heading === 'dropdown' && (
                                    <td>
                                      <Select
                                        id='test-parameter'
                                        mode='tags'
                                        placeholder='Please select'
                                        defaultValue={field?.dropdown}
                                        style={{ width: '100%' }}
                                        onChange={(value) =>
                                          handleFieldChange(
                                            value,
                                            mainIndex,
                                            fieldIndex,
                                            'dropdown'
                                          )
                                        }
                                      />
                                    </td>
                                  )}
                                  {field?.heading === 'formula' && (
                                    <td>
                                      <Input
                                        value={field?.formula}
                                        type='text'
                                        onChange={(e) =>
                                          handleFieldChange(
                                            e.target.value,
                                            mainIndex,
                                            fieldIndex,
                                            'formula'
                                          )
                                        }
                                      />
                                    </td>
                                  )}
                                  <td
                                    className='d-flex flex-wrap align-items-center justify-content-end'
                                    style={{ gap: '0.5rem' }}
                                  >
                                    {!field?._id && (
                                      <Button
                                        type='primary'
                                        shape='circle'
                                        style={{
                                          boxShadow: 'none'
                                        }}
                                        onClick={() => onFieldSave(mainIndex, fieldIndex)}
                                      >
                                        <i className='icofont-save'></i>
                                      </Button>
                                    )}
                                    <Popconfirm
                                      title='Are you sure to delete this field?'
                                      okText='Yes'
                                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                      onConfirm={() =>
                                        onFieldDelete(field._id, mainIndex, fieldIndex)
                                      }
                                    >
                                      <Button
                                        danger
                                        shape='circle'
                                        style={{
                                          boxShadow: 'none'
                                        }}
                                      >
                                        <i className='icofont-ui-delete'></i>
                                      </Button>
                                    </Popconfirm>

                                    {(field?.heading === 'input' ||
                                      field?.heading === 'formula') && (
                                        <Button
                                          type='primary'
                                          style={{
                                            boxShadow: 'none',
                                            padding: '0.5rem 0.75rem',
                                            height: '35px'
                                          }}
                                          onClick={() => handleAddRange(mainIndex, fieldIndex)}
                                        >
                                          <i className='icofont-plus'></i>
                                          Range
                                        </Button>
                                      )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {field?.ranges.length > 0 && field?.heading !== 'dropdown' && (
                              <Collapse
                                accordion
                                style={{
                                  marginTop: '0rem',
                                  borderRadius: '0 0 1em 1em ',
                                  borderTop: '0'
                                }}
                                className='overflow-auto'
                              >
                                <Panel header='Ranges' key='1' className='overflow-auto'>
                                  <div
                                    key={field._id}
                                    className='mt-4 overflow-auto  '
                                    style={{ width: '100%', overflowY: 'scroll' }}
                                  >
                                    <table className='table table_field overflow-auto '>
                                      <tbody>
                                        {field?.ranges.map((range, rangeIndex) => (
                                          <React.Fragment key={range?._id}>
                                            <th scope='col' style={{ width: '22%' }}>
                                              Title -{' '}
                                              <span style={{ color: 'red' }}>
                                                {range?.rangeId ? range?.rangeId : ''}
                                              </span>
                                            </th>

                                            <th scope='col' style={{ width: '22%' }}>
                                              Condition
                                            </th>
                                            <th scope='col' style={{ width: '22%' }}>
                                              Type
                                            </th>
                                            {range.rangeType === 'formula' && (
                                              <th scope='col' style={{ width: '22%' }}>
                                                Formula
                                              </th>
                                            )}
                                            {range.rangeType === 'value' && (
                                              <th scope='col' style={{ width: '22%' }}>
                                                Value
                                              </th>
                                            )}
                                            <th scope='col' style={{ width: '12%' }}>
                                              Action
                                            </th>
                                            <tr>
                                              <td>
                                                <Input
                                                  value={range.title}
                                                  onChange={(e) =>
                                                    handleRangeChange(
                                                      e.target.value,
                                                      mainIndex,
                                                      fieldIndex,
                                                      rangeIndex,
                                                      'title'
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Input
                                                  value={range.condition}
                                                  onChange={(e) =>
                                                    handleRangeChange(
                                                      e.target.value,
                                                      mainIndex,
                                                      fieldIndex,
                                                      rangeIndex,
                                                      'condition'
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Select
                                                  id='field-type'
                                                  value={range.rangeType}
                                                  onChange={(value) =>
                                                    handleRangeChange(
                                                      value,
                                                      mainIndex,
                                                      fieldIndex,
                                                      rangeIndex,
                                                      'rangeType'
                                                    )
                                                  }
                                                  placeholder='Input Type'
                                                >
                                                  <Option key='input' value={'value'}>
                                                    {'value'}
                                                  </Option>
                                                  <Option key='formula' value={'formula'}>
                                                    {'formula'}
                                                  </Option>
                                                </Select>
                                              </td>
                                              {range.rangeType === 'formula' && (
                                                <td>
                                                  <Input
                                                    value={range.formula}
                                                    onChange={(e) =>
                                                      handleRangeChange(
                                                        e.target.value,
                                                        mainIndex,
                                                        fieldIndex,
                                                        rangeIndex,
                                                        'formula'
                                                      )
                                                    }
                                                  />
                                                </td>
                                              )}
                                              {range.rangeType === 'value' && (
                                                <td>
                                                  <Input
                                                    value={range.rangeValue}
                                                    onChange={(e) =>
                                                      handleRangeChange(
                                                        e.target.value,
                                                        mainIndex,
                                                        fieldIndex,
                                                        rangeIndex,
                                                        'rangeValue'
                                                      )
                                                    }
                                                  />
                                                </td>
                                              )}

                                              <td>
                                                <Popconfirm
                                                  title='Are you sure to delete this range?'
                                                  okText='Yes'
                                                  icon={
                                                    <QuestionCircleOutlined
                                                      style={{ color: 'red' }}
                                                    />
                                                  }
                                                  onConfirm={() =>
                                                    onRangeDelete(
                                                      range._id,
                                                      mainIndex,
                                                      fieldIndex,
                                                      rangeIndex
                                                    )
                                                  }
                                                >
                                                  <Button
                                                    type='primary'
                                                    style={{
                                                      background: 'transparent',
                                                      border: 'none',
                                                      color: 'red',
                                                      boxShadow: 'none',
                                                      fontSize: '1.5em'
                                                    }}
                                                  >
                                                    <i className='icofont-ui-delete'></i>
                                                  </Button>
                                                </Popconfirm>
                                              </td>
                                            </tr>
                                          </React.Fragment>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </Panel>
                              </Collapse>
                            )}
                          </div>
                        );
                      })}

                      <Button
                        type='primary'
                        style={{ marginTop: '1rem' }}
                        onClick={() => handleAddField(mainIndex)}
                      >
                        Add New Field
                      </Button>
                    </Panel>
                  );
                })}
              </Collapse>
              <Button
                type='primary'
                onClick={() => handleAddSection()}
                style={{ marginTop: '1em' }}
              >
                Add Section
              </Button>
            </div>

            <div className='sectionStyle'>
              <span className='floatHeading'>Findings</span>
              <EditFinding findingData={findingData} setFindingData={setFindingData} />
            </div>

            <div className='d-flex mt-3 justify-content-center '>
              <Button type='primary' loading={loadings} onClick={onSaveForm}>
                {!loadings && <i className='icofont-save'></i>}Save Form
              </Button>
            </div>
          </>
        )}
      </Spin>
    </>
  );
};

export default EditForm;