import React from 'react';

import { NavLink } from 'react-router-dom';

import { IBreadcrumb } from '../../../interfaces/page';

type Props = {
  breadcrumbs: IBreadcrumb[];
};

const Breadcrumb = ({ route, title }) => {
  return (
    <li className='item'>
      {route ? (
        <NavLink className='link' replace to={`/${route}`}>
          {title}
        </NavLink>
      ) : (
        <span>{title}</span>
      )}
    </li>
  );
};

const Breadcrumbs = ({ breadcrumbs = [] }: Props) => {
  const breadcrumbList = breadcrumbs.map(({ title, route }: IBreadcrumb, index) => (
    <React.Fragment key={index}>
      <Breadcrumb title={title} route={route} />
      {index < breadcrumbs.length - 1 && (
        <li className='d-flex justify-content-center align-items-center'>
          <i className='separator icofont icofont-thin-right' style={{ fontWeight: '800' }} />
        </li>
      )}
    </React.Fragment>
  ));

  return <ul className='page-breadcrumbs'>{breadcrumbList}</ul>;
};

export default Breadcrumbs;
