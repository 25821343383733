import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { formGroupUrl, getAllDoctorURL, getAllSpecieURL } from '../../../constants/url';
import { useDoctors } from '../../../hooks/useDoctor';
import { usePageData } from '../../../hooks/usePage';
import { IPageData } from '../../../interfaces/page';
import { fetchDoctors } from '../../../redux/doctors/actions';
import { fetchFormGroups } from '../../../redux/formGroup/actions';
import AddDoctor from './AddDoctor/AddDoctor';
import DoctorsTable from './DoctorsTable';
import { fetchSpecie } from '../../../redux/specie/actions';
const pageData: IPageData = {
  title: 'Doctors',
  fulFilled: true
};

const DoctorsPage = () => {
  const { doctors, editDoctor, deleteDoctor } = useDoctors();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  usePageData(pageData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };

        await dispatch(fetchDoctors(getAllDoctorURL, headers));
        await dispatch(fetchFormGroups(formGroupUrl, headers));
        await dispatch(fetchSpecie(getAllSpecieURL, headers));
      } catch (error) {
        message.error('Failed to fetch doctor data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <>
      <Spin
        spinning={loading}
        size='large'
        indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
      >
        <header className='page-header'>
          <AddDoctor />
        </header>
        <DoctorsTable onDeleteDoctor={deleteDoctor} onEditDoctor={editDoctor} doctors={doctors} />
      </Spin>
    </>
  );
};

export default DoctorsPage;
