import { ISpecie } from '../../interfaces/specie';

export const SET_SPECIES = '[species] Set';
export const ADD_SPECIE = '[species] Add';
export const EDIT_SPECIE = '[species] Edit';
export const DELETE_SPECIE = '[species] Delete';

export interface SetSpecieAction {
  type: typeof SET_SPECIES;
  payload: ISpecie[];
}

export interface AddSpecieAction {
  type: typeof ADD_SPECIE;
  payload: ISpecie;
}

export interface EditSpecieAction {
  type: typeof EDIT_SPECIE;
  payload: ISpecie;
}

export interface DeleteSpecieAction {
  type: typeof DELETE_SPECIE;
  _id: string;
}

export type SpeciesActions =
  | SetSpecieAction
  | AddSpecieAction
  | DeleteSpecieAction
  | EditSpecieAction;

