import { useEffect, useState } from 'react';
import { ColumnProps } from 'antd/es/table';
import { Table, Button, Tag, message, Popconfirm, Spin } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { DocResponse, IDoctor } from '../../../interfaces/doctor';
import { LoadingOutlined } from '@ant-design/icons';

import { activeDoctor } from './api';
import { activateDoctorUrl, getInActiveDoctorsUrl } from '../../../constants/url';
import { IPageData } from '../../../interfaces/page';
import { usePageData } from '../../../hooks/usePage';
import axios from 'axios';

const pageData: IPageData = {
  title: 'Archived Doctors',
  fulFilled: true
};
const InActiveDoctorsTable = () => {
  const [inActiveDoctor, setInActiveDoctor] = useState(null);
  const [loading, setLoading] = useState(true);

  usePageData(pageData);
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  };
  const handleActiveDoctor = async (id: string) => {
    try {
      let res = await activeDoctor(activateDoctorUrl + `/${id}`, {}, { headers });
      let activeDoctorId = res?.data?.data?._id;
      const activeDoctors = inActiveDoctor.filter((el) => el._id !== activeDoctorId);
      setInActiveDoctor([...activeDoctors]);
      message.success('Doctor Activated successfully');
    } catch (error) {
      message.error('Failed to Activate Doctor. Please try again.');
    }
  };

  const actions = (currentDoctor: IDoctor) => (
    <div className='buttons-list nowrap'>
      <Popconfirm
        title='Are you sure to Activate this Doctor?'
        okText='Yes'
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={() => handleActiveDoctor(currentDoctor._id)}
      >
        <Button shape='circle' type='primary'>
          <span className='icofont icofont-undo' />
        </Button>
      </Popconfirm>
    </div>
  );

  const fetchInActiveDoctors = () => {
    axios
      .get<DocResponse>(getInActiveDoctorsUrl, { headers })
      .then((res) => {
        const doctorsData = res.data?.data?.data?.doctors;
        setInActiveDoctor(doctorsData || []);
        setLoading(false);
      })
      .catch(() => console.error('Server connections error'));
  };
  useEffect(() => {
    fetchInActiveDoctors();
  }, []);
  const columns: ColumnProps<IDoctor>[] = [
    {
      key: 'license',
      dataIndex: 'license',
      title: 'Licence Number',
      render: (license) => <span>{license?.number}</span>
    },
    {
      key: 'fullName',
      dataIndex: 'fullName',
      title: 'Name',
      sorter: (a, b) => (a.fullName > b.fullName ? 1 : -1),
      render: (fullName) => <span>{fullName ? fullName : 'shahab'}</span>
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
      render: (email) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {email}
        </span>
      )
    },
    {
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      title: 'Number',
      render: (phoneNumber) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#a5a5a5' }}>
          {phoneNumber}
        </span>
      )
    },
    {
      key: 'deleted',
      align: 'center',
      dataIndex: 'deleted',
      title: 'Account Status',
      render: (deleted) => (
        <Tag style={{ borderRadius: 20 }} color={deleted ? '#FF9843' : '#5F8770'}>
          {deleted ? 'InActive' : 'Active'}
        </Tag>
      ),
      sorter: (a, b) => (a.accountStatus > b.accountStatus ? 1 : -1)
    },
    {
      key: 'actions',
      align: 'center',
      title: 'Actions',
      render: actions
    }
  ];
  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70vh'
        }}
      >
        <Spin
          spinning={loading}
          size='large'
          indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
        />
      </div>
    );
  }
  const pagination = inActiveDoctor?.length <= 10 ? false : {};

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        key='id'
        dataSource={inActiveDoctor}
        columns={columns}
      />
    </>
  );
};

export default InActiveDoctorsTable;
