import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, FloatButton, Popconfirm, Spin, Table, message } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllReportURL } from '../../../constants/url';
import { usePageData } from '../../../hooks/usePage';
import { useReports } from '../../../hooks/useReport';
import { IPageData } from '../../../interfaces/page';
import { IReport } from '../../../interfaces/report';
import { fetchReports } from '../../../redux/reports/actions';
import { deleteData } from './api';

const pageData: IPageData = {
  title: 'Reports',
  fulFilled: true
};

const ReportTable = () => {
  const navigate = useNavigate();
  usePageData(pageData);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { reports, deleteReport } = useReports();

  const handleDeleteReport = async (id) => {
    try {
      await deleteData(getAllReportURL + `/${id}`, id);
      deleteReport(id);
      message.success('Report deleted successfully');
    } catch (error) {
      message.error('Failed to delete report. Please try again.');
    }
  };

  const handleShowInfo = (id) => navigate(`/report/${id}`);
  const actions = (record: IReport) => (
    <div className='buttons-list nowrap justify-content-center'>
      <Button shape='circle' onClick={() => handleShowInfo(record._id)}>
        <span className='icofont icofont-external-link' />
      </Button>
      <Popconfirm
        title='Are you sure to delete this row?'
        okText='Yes'
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={() => handleDeleteReport(record._id)}
      >
        <Button shape='circle' danger>
          <span className='icofont icofont-ui-delete' />
        </Button>
      </Popconfirm>
    </div>
  );

  const columns: ColumnProps<IReport>[] = [
    {
      key: 'caseNumber',
      dataIndex: 'caseNumber',
      title: <div style={{ textAlign: 'center' }}>Case No.</div>,
      align: 'center',

      sorter: (a, b) => (a.caseNumber > b.caseNumber ? 1 : -1),
      render: (caseNumber) => <strong>{caseNumber}</strong>
    },
    {
      key: 'formTitle',
      dataIndex: 'formTitle',
      align: 'center',
      title: 'Form Title',
      render: (formTitle) => <strong>{formTitle}</strong>
    },
    {
      key: 'patientId',
      dataIndex: ['patientId', 'name'],
      align: 'center',
      title: 'Patient',
      render: (patientName) => <strong>{patientName}</strong>
    },
    {
      key: 'doctorId',
      dataIndex: ['doctorId', 'fullName'],
      align: 'center',
      title: 'Doctor',
      render: (doctorName) => <strong>{doctorName}</strong>
    },
    {
      key: 'specieId',
      dataIndex: ['specieId', 'name'],
      align: 'center',
      title: 'Specie',
      render: (specieName) => <strong>{specieName}</strong>
    },
    {
      key: 'organId',
      dataIndex: ['organId', 'name'],
      align: 'center',
      title: 'Organ',
      render: (organName) => <strong>{organName}</strong>
    },
    {
      key: 'actions',
      align: 'center',
      title: 'Action',
      render: (_, record) => actions(record)
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        await dispatch(fetchReports(getAllReportURL, headers));
      } catch (error) {
        message.error('Failed to fetch report data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);
  const pagination = reports.length <= 10 ? false : { showSizeChanger: false };
  return (
    <>
      <Spin
        spinning={loading}
        size='large'
        indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
      >
        <header className='page-header'>
          <FloatButton
            className='float_button'
            type='primary'
            onClick={() => navigate('/createReport')}
            icon={<i className='icofont-ui-add'></i>}
          />
        </header>
        <Table
          pagination={pagination}
          className='accent-header'
          rowKey='id'
          dataSource={reports}
          columns={columns}
        />
      </Spin>
    </>
  );
};

export default ReportTable;
