import { LoadingOutlined } from '@ant-design/icons';
import { Card, Collapse, Empty, Input, Select, Spin, Table, message } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAllFindingCategoriesUrl, getSingleFormURL } from '../../../constants/url';
import { usePageData } from '../../../hooks/usePage';
import { IFinding } from '../../../interfaces/finding';
import { IForms } from '../../../interfaces/forms';
import { IPageData } from '../../../interfaces/page';
import ViewFindings from './ViewFindings';
import { getForm } from './api';
import './form.scss';
import { Label } from 'recharts';
import { wrap } from 'module';

const { Column } = Table;
const { Panel } = Collapse;

interface DataRow {
  title: string;
  heading: string;
  dropdown: [];
  value: string;
  fieldId: string;
  formula: string;
  ranges: [];
}

const ViewForm = () => {
  const [formData, setFormData] = useState<IForms | null>(null);
  const [findingData, setFindingData] = useState<IFinding[]>([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [pageData, setPageData] = useState<IPageData>({
    title: 'View Form',
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Form',
        route: 'form'
      },
      {
        title: 'View Form'
      }
    ]
  });

  usePageData(pageData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [formRes, findingRes] = await Promise.all([
          getForm(getSingleFormURL, id),
          getForm(`${getAllFindingCategoriesUrl}/getbyform/`, id)
        ]);

        setFormData(formRes.data.data);
        setFindingData(findingRes.data.data);
        setPageData((prevData) => ({
          ...prevData,
          breadcrumbs: [
            ...prevData.breadcrumbs.slice(0, -1),
            {
              title:
                formRes.data.data?.title ||
                prevData.breadcrumbs[prevData.breadcrumbs.length - 1].title
            }
          ]
        }));
        setLoading(false);
      } catch (error) {
        message.error('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <Spin
          spinning={loading}
          size='large'
          indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
        />
      </div>
    );
  }
  return (
    <>
      {formData?.sections.length === 0 && findingData.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          <Card style={{ marginTop: '2rem' }}>
            {formData?.sections.length === 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No sections available'} />
            ) : (
              <>
                <div className='row'>
                  <div className='col-sm-4 col-12'>
                    <div className='form-group form_label_div'>
                      <label>Organ:</label>
                      <Input value={formData?.organId?.name} readOnly />
                    </div>
                  </div>
                  <div className='col-sm-4 col-12'>
                    <div className='form-group form_label_div'>
                      <label>Specie:</label>
                      <Input value={formData?.specieId?.name} readOnly />
                    </div>
                  </div>

                  <div className='col-sm-4 col-12'>
                    <div className='form-group form_label_div'>
                      <label>Group:</label>
                      <Input value={formData?.category?.title} readOnly />
                    </div>
                  </div>
                </div>
                <h3 style={{ borderBottom: '1px solid #000' }}>Sections</h3>
                {formData?.sections.map((section, mainIndex) => {
                  return (
                    <Card title={section?.title} key={section._id}>
                      {section?.fields.map((field, fieldIndex) => {
                        return (
                          <div key={field._id} className='mt-4'>
                            <Table dataSource={[field]} rowKey='id' pagination={false}>
                              <Column
                                title={
                                  <>
                                    <span>Title- </span>
                                    <span style={{ color: 'red' }}>{field?.fieldId}</span>
                                  </>
                                }
                                dataIndex='testParameterOption'
                                key={`test-param-${field._id}`}
                                render={(text, record: DataRow) => <label>{record?.title}</label>}
                                width={'35%'}
                              />

                              <Column
                                title={`Type `}
                                dataIndex='heading'
                                key={`type-${field._id}`}
                                render={(text, record: DataRow) => (
                                  <>
                                    <label>{record.heading}</label>
                                  </>
                                )}
                                width={'20%'}
                              />
                              {field?.heading === 'dropdown' && (
                                <Column
                                  className='option_style'
                                  title={`Options `}
                                  dataIndex='dropdown'
                                  key={`value-${field._id}`}
                                  render={(text, record: DataRow) => (
                                    <>
                                      {record.dropdown.map((item) => (
                                        <span
                                          className=' mr-2 p-1  '
                                          style={{
                                            background: ' rgba(0, 0, 0, 0.1)'
                                          }}
                                        >
                                          {item}
                                        </span>
                                      ))}
                                    </>
                                  )}
                                  width={'45%'}
                                />
                              )}
                              {field?.heading === 'text' && <Column width={'47%'} />}
                              {field?.heading === 'input' && <Column width={'47%'} />}

                              {field?.heading === 'formula' && (
                                <Column
                                  title={`Formula `}
                                  dataIndex='formula'
                                  key={`value-${field._id}`}
                                  render={(text, record: DataRow) => (
                                    <>
                                      <label>{record.formula}</label>
                                    </>
                                  )}
                                  width={'47%'}
                                />
                              )}
                            </Table>
                            {field?.ranges.length > 0 && (
                              <Collapse
                                accordion
                                style={{ marginTop: '0rem', borderRadius: '0 0 1em 1em ' }}
                              >
                                <Panel header='Ranges' key='1'>
                                  <div
                                    key={field._id}
                                    className='mt-4'
                                    style={{ width: '100%', overflowY: 'scroll' }}
                                  >
                                    <table className='table table_field'>
                                      <tbody>
                                        {field?.ranges.map((range, rangeIndex) => (
                                          <>
                                            <th scope='col' style={{ width: '22%' }}>
                                              Title- {''}
                                              <span style={{ color: 'red' }}>
                                                {range?.rangeId ? range?.rangeId : ''}
                                              </span>
                                            </th>
                                            <th scope='col' style={{ width: '14%' }}>
                                              Condition
                                            </th>
                                            <th scope='col' style={{ width: '18%' }}>
                                              Type
                                            </th>
                                            {range.rangeType === 'formula' && (
                                              <th scope='col' style={{ width: '32%' }}>
                                                Formula
                                              </th>
                                            )}
                                            {range.rangeType === 'value' && (
                                              <th scope='col' style={{ width: '32%' }}>
                                                Value
                                              </th>
                                            )}
                                            <tr>
                                              <td>
                                                <label>{range.title}</label>
                                              </td>
                                              <td>
                                                <label>{range.condition}</label>
                                              </td>
                                              <td>
                                                <label>{range.rangeType}</label>
                                              </td>
                                              {range.rangeType === 'formula' && (
                                                <td>
                                                  <label>{range.formula}</label>
                                                </td>
                                              )}
                                              {range.rangeType === 'value' && (
                                                <td>
                                                  <label>{range.rangeValue}</label>
                                                </td>
                                              )}
                                            </tr>
                                          </>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </Panel>
                              </Collapse>
                            )}
                          </div>
                        );
                      })}
                    </Card>
                  );
                })}
              </>
            )}
          </Card>
          {findingData.length !== 0 && <ViewFindings findingData={findingData} />}
        </>
      )}
    </>
  );
};

export default ViewForm;
