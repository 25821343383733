import axios, { AxiosResponse } from 'axios';

export const getReport = async (url: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.error('Error adding data:', error);
    throw error;
  }
};
