import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ColumnProps } from 'antd/es/table';
import { Avatar, Table, Button, Modal, Tag, Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import FormulaForm from './AddFormula/FormulaForm';
import { IFormula } from '../../../interfaces/formula';

type Props = {
  formulas: IFormula[];
  onEditFormulas?: (patient: IFormula) => void;
  onDeleteFormulas?: (id: string) => void;
};

type PatientsImgProps = {
  img: string;
};

const SpecieImg = ({ img }: any) => {
  const isData = img.startsWith('data:image');
  const isWithPath = img.startsWith('http');

  if (isData || isWithPath) {
    return <Avatar size={40} src={img} />;
  }

  return <Avatar size={40} src={`${window.location.origin}/${img}`} />;
};

const FormulaTable = ({
  formulas,
  onDeleteFormulas = () => null,
  onEditFormulas = () => null
}: Props) => {
  const navigate = useNavigate();

  const [formula, setFormula] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const closeModal = () => setVisibility(false);

  const handleShowInfo = () => navigate('/formula-profile');
  const handleDeleteFormula = (id) => onDeleteFormulas(id);
  const handleEditFormula = (formula: IFormula) => {
    setFormula(formula);
    setVisibility(true);
  };

  const actions = (organ: IFormula) => (
    <div className='buttons-list nowrap'>
      {/* <Button shape='circle' onClick={handleShowInfo}>
        <span className='icofont icofont-external-link' />
      </Button> */}
      <Button onClick={handleEditFormula.bind({}, organ)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Popconfirm
        title='Are you sure to delete this row?'
        okText='Yes'
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={() => handleDeleteFormula(organ.id)}
      >
        <Button shape='circle' danger>
          <span className='icofont icofont-ui-delete' />
        </Button>
      </Popconfirm>
      {/* <Button onClick={handleDeleteFormula.bind({}, organ.id)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button> */}
    </div>
  );

  const columns: ColumnProps<IFormula>[] = [
    // {
    //   key: 'img',
    //   title: 'Photo',
    //   dataIndex: 'img',
    //   render: (img) => <PatientImg img={img} />
    // },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (id) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {id}
        </span>
      )
    },
    {
      key: 'name',
      dataIndex: 'heading',
      title: 'Formula',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'formula1',
      dataIndex: 'formula1',
      title: 'Low Range',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'formula2',
      dataIndex: 'formula2',
      title: 'High Range',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'formula3',
      dataIndex: 'formula3',
      title: 'Mid Range',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },

    // {
    //   key: 'age',
    //   dataIndex: 'age',
    //   title: 'Age',
    //   sorter: (a, b) => a.age - b.age,
    //   render: (age) => (
    //     <span className='nowrap' style={{ color: '#a5a5a5' }}>
    //       {age}
    //     </span>
    //   )
    // },
    // {
    //   key: 'visit',
    //   dataIndex: 'lastVisit',
    //   title: 'Last visit',
    //   render: (visit) => (
    //     <span className='nowrap' style={{ color: '#a5a5a5' }}>
    //       {visit.split('T')[0]}
    //     </span>
    //   )
    // },
    // {
    //   key: 'address',
    //   dataIndex: 'address',
    //   title: 'Formula',
    //   render: (address) => <span style={{ minWidth: 200, display: 'block' }}>{address}</span>
    // },
    // {
    //   key: 'number',
    //   dataIndex: 'number',
    //   title: 'Number',
    //   render: (phone) => (
    //     <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
    //       <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
    //       {phone}
    //     </span>
    //   )
    // },

    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      render: (status) => (
        <Tag style={{ borderRadius: 20 }} color={status === 'Approved' ? '#b7ce63' : '#cec759'}>
          {status}
        </Tag>
      ),
      sorter: (a, b) => (a.status > b.status ? 1 : -1)
    },
    {
      key: 'actions',
      title: 'Actions',
      render: actions
    }
  ];

  const pagination = formulas.length <= 10 ? false : {};

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={formulas}
        columns={columns}
      />

      <Modal
        open={visibility}
        footer={null}
        onCancel={closeModal}
        title={<h3 className='title'>Add Formulas</h3>}
      >
        <FormulaForm
          submitText='Update Formulas'
          onCancel={closeModal}
          onSubmit={onEditFormulas}
          formula={formula}
        />
      </Modal>
    </>
  );
};

export default FormulaTable;
