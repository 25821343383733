import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { Avatar, Table, Button, Modal, Tag, Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { IHeading } from '../../../interfaces/heading'; // Adjust the import path

import HeadingForm from './AddHeading/HeadingForm'; // Adjust the import path

type Props = {
  headings: IHeading[];
  onEditHeading?: (heading: IHeading) => void;
  onDeleteHeading?: (id: string) => void;
};

type HeadingImgProps = {
  img: string;
};

const HeadingImg = ({ img }: HeadingImgProps) => {
  const isData = img.startsWith('data:image');
  const isWithPath = img.startsWith('http');

  if (isData || isWithPath) {
    return <Avatar size={40} src={img} />;
  }

  return <Avatar size={40} src={`${window.location.origin}/${img}`} />;
};

const HeadingTable = ({
  headings,
  onEditHeading = () => null,
  onDeleteHeading = () => null
}: Props) => {
  const navigate = useNavigate();

  const [heading, setHeading] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const closeModal = () => setVisibility(false);

  const handleShowInfo = () => navigate('/heading-profile');
  const handleDeleteHeading = (id) => onDeleteHeading(id);
  const handleEditHeading = (heading: IHeading) => {
    setHeading(heading);
    setVisibility(true);
  };

  const actions = (heading: IHeading) => (
    <div className='buttons-list nowrap'>
      <Button onClick={handleEditHeading.bind({}, heading)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Popconfirm
        title='Are you sure to delete this row?'
        okText='Yes'
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={() => handleDeleteHeading(heading.id)}
      >
        <Button shape='circle' danger>
          <span className='icofont icofont-ui-delete' />
        </Button>
      </Popconfirm>
      {/* <Button onClick={handleDeleteHeading.bind({}, heading.id)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button> */}
    </div>
  );

  const columns: ColumnProps<IHeading>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (id) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {id}
        </span>
      )
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      render: (status) => (
        <Tag style={{ borderRadius: 20 }} color={status === 'Approved' ? '#b7ce63' : '#cec759'}>
          {status}
        </Tag>
      ),
      sorter: (a, b) => (a.status > b.status ? 1 : -1)
    },
    {
      key: 'actions',
      title: 'Actions',
      render: actions
    }
  ];

  const pagination = headings.length <= 10 ? false : {};

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={headings}
        columns={columns}
      />

      <Modal
        visible={visibility}
        footer={null}
        onCancel={closeModal}
        title={<h3 className='title'>Edit heading</h3>}
      >
        <HeadingForm
          submitText='Update heading'
          onCancel={closeModal}
          onSubmit={onEditHeading}
          heading={heading}
        />
      </Modal>
    </>
  );
};

export default HeadingTable;
