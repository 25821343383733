import { IFormGroups } from '../../interfaces/formGroup';
import {
  ADD_FORMS_GROUP,
  DELETE_FORMS_GROUP,
  EDIT_FORMS_GROUP,
  FormsActions,
  SET_FORMS_GROUP
} from './types';

const initialState: IFormGroups[] = [];

export function formGroupsReducer(
  state: IFormGroups[] = initialState,
  action: FormsActions
): IFormGroups[] {
  switch (action.type) {
    case SET_FORMS_GROUP: {
      return [...action.payload];
    }

    case EDIT_FORMS_GROUP: {
      const editedFormGroups = state.map((el) =>
        el._id !== action.payload._id ? el : action.payload
      );

      return [...editedFormGroups];
    }

    case ADD_FORMS_GROUP: {
      return [{ ...action.payload }, ...state];
    }

    case DELETE_FORMS_GROUP: {
      const formGroups = state.filter((el) => el._id !== action.id);
      return [...formGroups];
    }

    default: {
      return state;
    }
  }
}
