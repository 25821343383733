import { useState, useEffect } from 'react';

import { ColumnProps } from 'antd/es/table';
import { Table, Button, Modal, Popconfirm, message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { IPatient } from '../../../interfaces/patient';

import PatientForm from '../../../layout/components/patients/PatientForm';
import { deleteData } from './api';
import { getAllPatientURL } from '../../../constants/url';

type Props = {
  patients: IPatient[];
  onEditPatient?: (patient: IPatient) => void;
  onDeletePatient?: (id: string) => void;
};

const PatientsTable = ({
  patients,
  onEditPatient = () => null,
  onDeletePatient = () => null
}: Props) => {
  const [patient, setPatient] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const closeModal = () => setVisibility(false);

  const handleDeletePatient = async (id) => {
    const res = await deleteData(getAllPatientURL + `/${id}`, id);
    if (res?.status === 200) {
      message.success('Patient deleted successfully');
    }
    onDeletePatient(id);
  };

  const handleEditPatient = (patient: IPatient) => {
    setPatient(patient);
    setVisibility(true);
  };

  const actions = (patient: IPatient) => (
    <div className='buttons-list nowrap justify-content-center'>
      <Button onClick={() => handleEditPatient(patient)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Popconfirm
        title='Are you sure to delete this row?'
        okText='Yes'
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={() => handleDeletePatient(patient._id)}
      >
        <Button shape='circle' danger>
          <span className='icofont icofont-ui-delete' />
        </Button>
      </Popconfirm>
    </div>
  );

  const columns: ColumnProps<IPatient>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      align: 'center',
      title: <div style={{ textAlign: 'center' }}>Name</div>,
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'age',
      dataIndex: 'age',
      title: 'Age',
      align: 'center',

      render: (age) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {age}
        </span>
      )
    },
    {
      key: 'ref_doctor',
      dataIndex: 'ref_doctor',
      align: 'center',
      title: 'Ref. Doctor',
      render: (ref_doctor) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {ref_doctor}
        </span>
      )
    },
    {
      key: 'sex',
      dataIndex: 'sex',
      align: 'center',
      title: 'Sex',
      render: (sex) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {sex === 'M' ? 'Male' : sex === 'F' ? 'Female' : 'Other'}
        </span>
      )
    },
    {
      key: 'actions',
      align: 'center',
      title: 'Actions',
      render: actions
    }
  ];

  const pagination = patients.length <= 10 ? false : { showSizeChanger: false };

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={patients}
        columns={columns}
      />

      <Modal
        open={visibility}
        footer={null}
        onCancel={closeModal}
        title={<h3 className='title'>Update Patient</h3>}
      >
        <PatientForm
          submitText='Update Patient'
          onCancel={closeModal}
          onSubmit={onEditPatient}
          patient={patient}
        />
      </Modal>
    </>
  );
};

export default PatientsTable;
