import { Button, Input, message, Spin } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { hasErrorFactory } from '../../../../utils/hasError';

import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { formGroupUrl } from '../../../../constants/url';
import { IFormGroups } from '../../../../interfaces/formGroup';
import { editFormGroup } from '../../../../redux/formGroup/actions';
import { addData, updateData } from './api';

import { LoadingOutlined } from '@ant-design/icons';

type Props = {
  onSubmit: (Disease: any) => void;
  onCancel: () => void;
  formGroups?: IFormGroups;
  submitText?: string;
};

const defaultSubmitText = 'Add Form Group';
const emptyFormGroup = {
  title: null,
  description: null,
  _id: null
};

const formGroupScheme = Yup.object({
  title: Yup.string().required(),
  description: Yup.string().notRequired()
});

const FormGroupForm = ({
  submitText = defaultSubmitText,
  formGroups = emptyFormGroup,
  onSubmit,
  onCancel
}: Props) => {
  const [loading, setLoading] = useState(false);
  const { setFieldValue, handleSubmit, setValues, handleBlur, touched, values, errors, isValid } =
    useFormik<IFormGroups>({
      validationSchema: formGroupScheme,
      initialValues: formGroups,
      onSubmit: async (values: any) => {
        try {
          setLoading(true);

          const token = localStorage.getItem('token');
          let { _id, ...updatedFormData } = values;

          const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          };
          if (submitText === 'Update Form Group') {
            let res = await updateData(formGroupUrl + `/${values._id}`, updatedFormData, headers);
            editFormGroup(res?.data?.data);
            if (res?.status === 200) {
              message.success('Form Group updated successfully');
            }
            onSubmit(res?.data?.data);
          } else {
            let res = await addData(formGroupUrl, updatedFormData, headers);
            if (res?.status === 200) {
              message.success('Form Group created successfully');
            }
            onSubmit(res?.data?.data);
          }
          onCancel();
        } catch (error) {
          message.error('An error occurred while processing your request');
        } finally {
          setLoading(false);
        }
      }
    });

  useEffect(() => {
    setValues(formGroups);
  }, [formGroups]);

  const hasError = hasErrorFactory(touched, errors);

  const handleCancel = () => {
    // resetForm();
    onCancel();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <label htmlFor='group-title' style={{ fontWeight: 'bold' }}>
            <i className='icofont-star-alt-2' style={{ color: 'red', fontSize: '10px' }}></i>Title:
          </label>
          <Input
            id='group-title'
            placeholder='Title'
            name='title'
            type='text'
            onBlur={handleBlur}
            onChange={(value) => setFieldValue('title', value.target.value)}
            value={values.title}
            className={hasError('title')}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='group-description' style={{ fontWeight: 'bold' }}>
            Description:
          </label>
          <Input
            id='group-description'
            placeholder='Description'
            name='description'
            type='text'
            onBlur={handleBlur}
            onChange={(value) => setFieldValue('description', value.target.value)}
            value={values.description}
            className={hasError('description')}
          />
        </div>

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>

          <Button disabled={!isValid || loading} type='primary' htmlType='submit'>
            {loading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: 'white' }} spin />} />
            ) : (
              submitText
            )}
          </Button>
        </div>
      </form>
    </>
  );
};

export default FormGroupForm;
