import { IFormula } from '../interfaces/formula';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../redux/formulas/actions';

import { IAppState } from '../interfaces/app-state';

export function useFormula() {
  const dispatch = useDispatch();
  const formulas = useSelector<IAppState, IFormula[]>((state) => state.formulas);

  const editFormula = (formula: IFormula) => {
    return dispatch(actions.editFormula(formula));
  };

  const addFormula = (formula: IFormula) => {
    return dispatch(actions.addFormula(formula));
  };

  const deleteFormula = (id: string) => {
    return dispatch(actions.deleteFormula(id));
  };

  return { formulas, addFormula, editFormula, deleteFormula };
}
