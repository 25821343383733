import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Input, Switch } from 'antd';
import PublicLayout from '../../layout/public/Public';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ImageLoader from '../../layout/components/patients/ImageLoader';
import './sessions.scss';

const SignUpSchemaStep1 = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required')
});

const SignUpSchemaStep2 = Yup.object().shape({
  licenseNo: Yup.string(),
  hospitalName: Yup.string(),
  state: Yup.string(),
  address: Yup.string(),
  city: Yup.string()
});

const SignUp = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [switchState, setSwitchState] = useState(false);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  return (
    <PublicLayout bgImg={`${window.origin}/content/register-login-bg.png`}>
      <h4 className='mt-0 mb-1'>Registration</h4>
      <p className='text-color-200'>
        Step {currentStep}: Enter your {currentStep === 1 ? 'personal' : 'professional'} information
        to register your Account.
      </p>

      <Formik
        initialValues={{
          name: '',
          email: '',
          password: '',
          licenseNo: '',
          hospitalName: '',
          state: '',
          address: '',
          city: '',
          img: ''
        }}
        validationSchema={currentStep === 1 ? SignUpSchemaStep1 : SignUpSchemaStep2}
        onSubmit={(values) => {
          if (currentStep === 1) {
            setFormData((prevData) => ({ ...prevData, ...values }));
            handleNext();
          } else {
            setFormData((prevData) => ({ ...prevData, ...values }));
            navigate('/thank-you');
          }
        }}
      >
        {({ values, handleSubmit }) => (
          <Form className='mb-4 form_div' onSubmit={handleSubmit}>
            {currentStep === 1 && (
              <>
                {/* Step 1 fields */}
                <Field name='name'>
                  {({ field, meta }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>
                        Name <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Input {...field} placeholder='Name' />
                      <ErrorMessage name='name'>
                        {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                  )}
                </Field>
                <Field name='email'>
                  {({ field, meta }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>
                        Email <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Input {...field} placeholder='Email' />
                      <ErrorMessage name='email'>
                        {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                  )}
                </Field>
                <Field name='password'>
                  {({ field, meta }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>
                        Password <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Input.Password {...field} placeholder='Password' />
                      <ErrorMessage name='password'>
                        {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                  )}
                </Field>
                <div className='d-flex align-items-center mb-4'>
                  <Switch
                    className={`${switchState ? 'active_toggle_color' : 'Inactive_toggle_color'}`}
                    checked={switchState}
                    onChange={(checked) => setSwitchState(checked)}
                  />
                  <span className='ml-2'>
                    I agree to the{' '}
                    <Link to='/terms' className='link_font'>
                      Terms and Privacy.
                    </Link>
                  </span>
                </div>
              </>
            )}

            {currentStep === 2 && (
              <>
                {/* Step 2 fields */}
                <div className='form-group'>
                  <ImageLoader src={values.img as string} />
                </div>
                <Field name='licenseNo'>
                  {({ field }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>License No.</label>
                      <Input {...field} placeholder='Enter your License number' />
                    </div>
                  )}
                </Field>
                <Field name='hospitalName'>
                  {({ field }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>Hospital Name</label>
                      <Input {...field} placeholder='Hospital Name' />
                    </div>
                  )}
                </Field>
                <Field name='state'>
                  {({ field }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>State</label>
                      <Input {...field} placeholder='State' />
                    </div>
                  )}
                </Field>
                <Field name='address'>
                  {({ field }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>Address</label>
                      <Input {...field} placeholder='Address' />
                    </div>
                  )}
                </Field>
                <Field name='city'>
                  {({ field }) => (
                    <div className='form-item'>
                      <label style={{ fontWeight: 'bold' }}>City</label>
                      <Input {...field} placeholder='City' />
                    </div>
                  )}
                </Field>
              </>
            )}

            <Button
              type='primary'
              htmlType='submit'
              className='active_toggle_color'
              style={{
                width: '100%',
                marginTop: '1rem'
              }}
            >
              {currentStep === 1 ? 'Next' : 'Submit'}
            </Button>
          </Form>
        )}
      </Formik>

      {currentStep === 1 && (
        <p>
          Have an account?{' '}
          <Link to='../sign-in' className='link_font'>
            Sign in!
          </Link>
        </p>
      )}
    </PublicLayout>
  );
};

export default SignUp;
