import { CheckCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Input, Menu, Select, SelectProps, message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  formGroupUrl,
  getAllFieldURL,
  getAllFindingCategoriesUrl,
  getAllFindingsUrl,
  getAllFormURL,
  getAllRangeURL,
  getAllSectionURL,
  getAllSpecieURL,
  getOrganBySpecieURL
} from '../../../constants/url';
import { useFormGroup } from '../../../hooks/useFormGroup';
import { usePageData } from '../../../hooks/usePage';
import { IPageData } from '../../../interfaces/page';
import { clearLocalStorage, logout } from '../../../redux/auth/action';
import { fetchFormGroups } from '../../../redux/formGroup/actions';
import {
  addData,
  addField,
  addFinding,
  addRange,
  addSection,
  deleteField,
  deleteFinding,
  deleteRange,
  deleteSection,
  getAllSpecies,
  getOrganBySpecie
} from './api';
import './form.scss';
const { TextArea } = Input;
const { Option } = Select;
const options: SelectProps['options'] = [];

interface Section {
  id: string;
  title: string;
  formId: string;
  organId: string;
  specieId: string;
  fields: Fields_json[];
}

interface Fields_json {
  id: string;
  title: string;
  inputType: string;
  formula: string;
  description: string;
  sectionId: string;
  ranges: Range[];
}

interface Range {
  title: string;
  formula: string;
  rangeType: string;
  condition: string;
  description: string;
  suggestion: string;
}

const pageData: IPageData = {
  title: 'Form',
  fulFilled: true
};

declare global {
  interface Array<T> {
    toSorted(): T[];
  }
}
interface Organ {
  id: string;
  name: string;
}
interface Species {
  id: string;
  name: string;
}
interface apiDataResponse {
  data: any;
}

const DataTable = () => {
  usePageData(pageData);
  const navigate = useNavigate();

  const [species, setSpecies] = useState([]);
  const [organList, setOrganList] = useState([]);
  const [formGroupSelect, setFormGroupSelect] = useState(null);
  const [selectedOrgan, setSelectedOrgan] = useState<Organ | undefined>();
  const [selectedSpecies, setSelectedSpecies] = useState<Species | undefined>();
  const [findingCategories, setFindingCategories] = useState([]);

  const [inputTextFormTitle, setInputTextFormTitle] = useState<string>('');
  const [inputTextSectionTitle, setInputTextSectionTitle] = useState<string>('');

  const [formDataResponse, setFormDataResponse] = useState<apiDataResponse>({ data: {} });
  const [sectionDataResponse, setSectionDataResponse] = useState<apiDataResponse>({ data: {} });
  const [sectionResponse, setSectionResponse] = useState([]);
  const [fieldDataResponse, setFieldDataResponse] = useState<apiDataResponse>({ data: {} });
  const [rangeDataResponse, setRangeDataResponse] = useState<apiDataResponse>({ data: {} });

  const [formVisible, setFormVisible] = useState(false);
  const [findingVisible, setFindingVisible] = useState(false);
  const [categoryVisible, setCategoryVisible] = useState(true);
  const [fieldVisible, setFieldVisible] = useState<Array<boolean>>([]);
  const [rangeVisible, setRangeVisible] = useState<Array<Array<Array<boolean>>>>([]);
  const { formGroups } = useFormGroup();

  const [isFormCreated, setIsFormCreated] = useState(false);
  const [isSectionCreated, setIsSectionCreated] = useState<Array<boolean>>([false]);
  const [isFieldCreated, setIsFieldCreated] = useState<Array<Array<boolean>>>([[]]);
  const [isRangeCreated, setIsRangeCreated] = useState<Array<Array<Array<boolean>>>>([[[]]]);

  const [sectionLoading, setSectionLoading] = useState<Array<boolean>>([false]);
  const [fieldLoading, setFieldLoading] = useState<Array<Array<boolean>>>([[]]);
  const [rangeLoading, setRangeLoading] = useState<Array<Array<Array<boolean>>>>([[[]]]);
  const [findingLoading, setFindingLoading] = useState<Array<boolean>>([false]);
  const [findingCategoryLoading, setFindingCategoryLoading] = useState<boolean>(false);

  const [buttonText, setButtonText] = useState({
    range: '',
    field: '',
    section: '',
    findings: ''
  });

  const [formSections, setFormSections] = useState([
    {
      id: '',
      sectionId: '',
      title: '',
      serial: 1,
      fields: [
        {
          id: '',
          fieldId: '',
          title: '',
          dropdown: [],
          formula: '',
          condition: '',
          description: '',
          suggestion: '',
          inputType: '',
          serial: 1,
          ranges: [
            {
              id: '',
              rangeId: '',
              title: '',
              rangeType: '',
              condition: '',
              description: '',
              suggestion: '',
              value: '',
              rangeValue: '',
              formula: '',
              formulaCheckbox: false,
              serial: 1
            }
          ]
        }
      ]
    }
  ]);

  const [findingsData, setFindingsData] = useState([
    {
      id: '',
      title: '',
      categoryId: '',
      details: [
        {
          heading: '',
          description: ''
        }
      ],
      valueType: '',
      created: false
    }
  ]);
  let dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        const specieRes = await getAllSpecies(getAllSpecieURL, headers);
        setSpecies(specieRes?.data?.data);
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          message.error('Token Expired: Login Again');
          dispatch(logout());
          clearLocalStorage();
          return;
        }
        console.log('Unexpected Error', error);
      }
    };

    fetchData();
  }, []);

  const handleChangeMulti = (
    value: string | string[],
    sectionIndex: number,
    fieldIndex: number
  ) => {
    const updatedFormSections = [...formSections];

    if (Array.isArray(value)) {
      updatedFormSections[sectionIndex].fields[fieldIndex].dropdown = value;
    } else {
      updatedFormSections[sectionIndex].fields[fieldIndex].dropdown = [value];
    }

    setFormSections(updatedFormSections);
  };

  const setInputType = (value: string, sectionIndex: number, fieldIndex: number) => {
    const updatedFormSections = [...formSections];
    updatedFormSections[sectionIndex].fields[fieldIndex].inputType = value;
    setFormSections(updatedFormSections);
  };

  const setFormulaCheckbox = (sectionIndex, fieldIndex, rangeIndex) => {
    setFormSections((prevSections) => {
      const newSections = [...prevSections];
      const updatedRanges = [...newSections[sectionIndex].fields[fieldIndex].ranges];

      // Toggle the formulaCheckbox value
      updatedRanges[rangeIndex] = {
        ...updatedRanges[rangeIndex],
        formulaCheckbox: !updatedRanges[rangeIndex].formulaCheckbox
      };

      // Update the ranges array
      newSections[sectionIndex].fields[fieldIndex].ranges = updatedRanges;

      return newSections;
    });
  };

  //Type Input form values update
  const handleInputChange = (e, fieldName, sectionIndex, fieldIndex) => {
    if (fieldName === 'formula') {
      const formulaValue = e.target.value;
      const matches = formulaValue.match(/FSF_(\w+)/g) || [];
      matches.map((match) => {
        const matchingField = findFieldValue(match);
        if (matchingField === 'text' || matchingField === 'dropdown') {
          message.error('Unable to add text or dropdown fields');
        }
      });
    }
    const value = e.target.value;

    setFormSections((prevSections) => {
      const newSections = [...prevSections];

      // Update the specific field value
      newSections[sectionIndex].fields[fieldIndex][fieldName] = value;

      return newSections;
    });
  };

  // Range form values update
  const handleRangeInputChange = (e, fieldName, sectionIndex, fieldIndex, rangeIndex) => {
    const value = e.target.value;

    setFormSections((prevSections) => {
      const newSections = [...prevSections];

      newSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex][fieldName] = value;

      return newSections;
    });
  };

  const handleFindingChange = (value, dataIndex, field, detailIndex) => {
    setFindingsData((prevFindingsData) => {
      const newFindingsData = [...prevFindingsData];
      const selectedItem = newFindingsData[dataIndex];

      // If the field is within the details array
      if (field === 'heading' || field === 'description') {
        selectedItem.details[detailIndex][field] = value;
      } else {
        // Update the specified field
        selectedItem[field] = value;
      }

      return newFindingsData;
    });
  };

  // Create Form API function
  const handleCreateFormClick = async () => {
    if (!selectedOrgan || !selectedSpecies || inputTextFormTitle === '' || !formGroupSelect) {
      message.error('Please fill in all Fields');
      return;
    }

    const formData = {
      title: inputTextFormTitle,
      specieId: selectedSpecies.id,
      organId: selectedOrgan.id,
      category: formGroupSelect,
      sections: []
    };

    const res = await addData(getAllFormURL + '', formData);
    setFormDataResponse(res?.data);

    if (res.status === 200) {
      message.success(res?.data?.message);
      setIsFormCreated(true);
      setFormVisible(true);
    } else {
      message.error('An error occurred');
    }
  };

  const addSectionContainer = () => {
    setFormSections((prevSections) => [
      ...prevSections,
      {
        id: '',
        sectionId: '',
        title: '',
        fields: [],
        serial: formSections.length + 1
      }
    ]);
    setIsSectionCreated((prevStates) => [...prevStates, false]);
    setSectionLoading((prevStates) => [...prevStates, false]);
    setIsFieldCreated((prevStates) => [...prevStates, []]);
    setFieldLoading((prevStates) => [...prevStates, []]);
    setIsRangeCreated((prevStates) => [...prevStates, [[]]]);
    setRangeLoading((prevStates) => [...prevStates, [[]]]);
  };

  console.log({ formSections })

  const handledeleteSection = async (sectionIndex) => {
    if (formSections[sectionIndex].id) {
      try {
        const delSectionRes = await deleteSection(getAllSectionURL, formSections[sectionIndex].id);
        if (delSectionRes.status === 200) {
          message.success('Section deleted successfuly');
          setFormSections((prevSections) => {
            const newSections = [...prevSections];
            newSections.splice(sectionIndex, 1);
            return newSections;
          });

          setIsSectionCreated((prevStates) => {
            const newStates = [...prevStates];

            // Remove the entire array at the specified sectionIndex
            newStates.splice(sectionIndex, 1);

            return newStates;
          });
          setSectionLoading((prevStates) => {
            const newStates = [...prevStates];

            // Remove the entire array at the specified sectionIndex
            newStates.splice(sectionIndex, 1);

            return newStates;
          });
        }
      } catch (error) {
        message.error('Failed to delete section');
      }
    } else {
      setFormSections((prevSections) => {
        const newSections = [...prevSections];
        newSections.splice(sectionIndex, 1);
        return newSections;
      });

      setIsSectionCreated((prevStates) => {
        const newStates = [...prevStates];

        // Remove the entire array at the specified sectionIndex
        newStates.splice(sectionIndex, 1);

        return newStates;
      });
      setSectionLoading((prevStates) => {
        const newStates = [...prevStates];

        // Remove the entire array at the specified sectionIndex
        newStates.splice(sectionIndex, 1);

        return newStates;
      });
      message.success('Section deleted successfuly');
    }
  };

  // create Section API function
  const handleCreateSection = async (index) => {
    if (formSections[index].title === '') {
      message.error('The Section Title must be provided');
      return;
    }

    setSectionLoading((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = true;
      return newStates;
    });

    const newSectionData = {
      title: formSections[index].title,
      serial: formSections[index].serial,
      formId: formDataResponse?.data?._id,
      fields: []
    };

    console.log({ newSectionData })

    const sectionRes = await addSection(getAllSectionURL + '', newSectionData);
    console.log(sectionRes?.data)
    setSectionDataResponse(sectionRes?.data);

    setSectionResponse((prevResponses) => {
      const newResponses = [...prevResponses];
      newResponses[index] = sectionRes?.data?.data?.section;
      return newResponses;
    });
    const updatedFormSections = [...formSections];
    updatedFormSections[index] = {
      ...updatedFormSections[index],
      id: sectionRes?.data?.data?.section._id,
      sectionId: sectionRes?.data?.data?.section.sectionId,
      title: sectionRes?.data?.data?.section.title
    };

    setFormSections(updatedFormSections);
    if (sectionRes.status === 200) {
      setFieldVisible((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = true;
        return newStates;
      });
      setRangeVisible((prevVisibility) => {
        const newVisibility = [...prevVisibility];
        newVisibility[index] = [];
        return newVisibility;
      });
      setIsSectionCreated((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = true;
        return newStates;
      });
      setSectionLoading((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = false;
        return newStates;
      });
      if (index > 0) {
        addFieldContainer(index);
      }
      message.success(sectionRes?.data?.message);
    } else {
      message.error('An error occurred');
    }
  };

  const addFieldContainer = (sectionIndex) => {
    setFormSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[sectionIndex].fields.push({
        id: '',
        fieldId: '',
        title: '',
        dropdown: [],
        formula: '',
        condition: '',
        description: '',
        suggestion: '',
        inputType: '',
        serial: formSections[sectionIndex]?.fields?.length + 1,
        ranges: []
      });

      return newSections;
    });

    setIsFieldCreated((prevStates) => {
      const newStates = [...prevStates];
      newStates[sectionIndex] = [...newStates[sectionIndex], false];
      return newStates;
    });
    setFieldLoading((prevStates) => {
      const newStates = [...prevStates];
      newStates[sectionIndex] = [...newStates[sectionIndex], false];
      return newStates;
    });
    setIsRangeCreated((prevStates) => {
      const newStates = [...prevStates];
      newStates[sectionIndex] = [...newStates[sectionIndex], []];
      return newStates;
    });
    setRangeLoading((prevStates) => {
      const newStates = [...prevStates];
      newStates[sectionIndex] = [...newStates[sectionIndex], []];
      return newStates;
    });
  };

  const handleDeleteField = async (sectionIndex, fieldIndex) => {
    if (formSections[sectionIndex]?.fields[fieldIndex]?.id) {
      try {
        const delFieldRes = await deleteField(
          getAllFieldURL,
          formSections[sectionIndex]?.fields[fieldIndex]?.id
        );
        if (delFieldRes.status === 200) {
          setFormSections((prevSections) => {
            const newSections = [...prevSections];
            newSections[sectionIndex].fields.splice(fieldIndex, 1);
            return newSections;
          });

          setIsFieldCreated((prevStates) => {
            const newStates = [...prevStates];

            // Check if the section and field indices are valid
            if (newStates[sectionIndex]) {
              // Remove the entire array at the specified fieldIndex
              newStates[sectionIndex] = newStates[sectionIndex].filter(
                (_, idx) => idx !== fieldIndex
              );
            }

            return newStates;
          });
          setFieldLoading((prevStates) => {
            const newStates = [...prevStates];

            // Check if the section and field indices are valid
            if (newStates[sectionIndex]) {
              // Remove the entire array at the specified fieldIndex
              newStates[sectionIndex] = newStates[sectionIndex].filter(
                (_, idx) => idx !== fieldIndex
              );
            }

            return newStates;
          });
          message.success('Field deleted successfuly');
        }
      } catch (error) {
        message.error('Failed to delete field');
      }
    } else {
      setFormSections((prevSections) => {
        const newSections = [...prevSections];
        newSections[sectionIndex].fields.splice(fieldIndex, 1);
        return newSections;
      });

      setIsFieldCreated((prevStates) => {
        const newStates = [...prevStates];

        // Check if the section and field indices are valid
        if (newStates[sectionIndex]) {
          // Remove the entire array at the specified fieldIndex
          newStates[sectionIndex] = newStates[sectionIndex].filter((_, idx) => idx !== fieldIndex);
        }

        return newStates;
      });
      setFieldLoading((prevStates) => {
        const newStates = [...prevStates];

        // Check if the section and field indices are valid
        if (newStates[sectionIndex]) {
          // Remove the entire array at the specified fieldIndex
          newStates[sectionIndex] = newStates[sectionIndex].filter((_, idx) => idx !== fieldIndex);
        }

        return newStates;
      });
      message.success('Field deleted successfuly');
    }
  };

  // Create Field API Function
  const handleCreateField = async (sectionIndex, fieldIndex) => {
    if (formSections[sectionIndex].fields[fieldIndex].title === '') {
      message.error('The Field Title is required');
      return;
    }
    if (formSections[sectionIndex].fields[fieldIndex].inputType === '') {
      message.error('The Field Input Type are mandatory');
      return;
    }
    if (
      formSections[sectionIndex].fields[fieldIndex].inputType === 'dropdown' &&
      formSections[sectionIndex].fields[fieldIndex].dropdown.length === 0
    ) {
      message.error('Atleast one option is required');
      return;
    }
    if (
      formSections[sectionIndex].fields[fieldIndex].inputType === 'formula' &&
      formSections[sectionIndex].fields[fieldIndex].formula === ''
    ) {
      message.error('The Field Formula is required');
      return;
    }

    setFieldLoading((prevStates) => {
      const newStates = [...prevStates];
      newStates[sectionIndex][fieldIndex] = true;
      return newStates;
    });

    const newFieldData = {
      title: formSections[sectionIndex].fields[fieldIndex].title,
      heading: formSections[sectionIndex].fields[fieldIndex].inputType,
      sectionId: formSections[sectionIndex]?.id,
      serial: formSections[sectionIndex]?.fields[fieldIndex]?.serial,
      ranges: [],
      dropdown:
        formSections[sectionIndex].fields[fieldIndex].inputType === 'dropdown'
          ? formSections[sectionIndex].fields[fieldIndex].dropdown
          : [],
      formula:
        formSections[sectionIndex].fields[fieldIndex].inputType === 'formula'
          ? formSections[sectionIndex].fields[fieldIndex].formula
          : '',
      condition:
        formSections[sectionIndex].fields[fieldIndex].inputType === 'formula' ||
          formSections[sectionIndex].fields[fieldIndex].inputType === 'input' ||
          formSections[sectionIndex].fields[fieldIndex].inputType === 'text'
          ? formSections[sectionIndex].fields[fieldIndex].condition
          : '',
      description:
        formSections[sectionIndex].fields[fieldIndex].inputType === 'formula' ||
          formSections[sectionIndex].fields[fieldIndex].inputType === 'input' ||
          formSections[sectionIndex].fields[fieldIndex].inputType === 'text'
          ? formSections[sectionIndex].fields[fieldIndex].description
          : '',
      suggestion:
        formSections[sectionIndex].fields[fieldIndex].inputType === 'formula' ||
          formSections[sectionIndex].fields[fieldIndex].inputType === 'input' ||
          formSections[sectionIndex].fields[fieldIndex].inputType === 'text'
          ? formSections[sectionIndex].fields[fieldIndex].suggestion
          : ''
    };

    const fieldRes = await addField(getAllFieldURL + '', newFieldData);
    setFieldDataResponse(fieldRes?.data);

    setFormSections((prevSections) => {
      const newSections = [...prevSections];
      const updatedFields = [...newSections[sectionIndex].fields];

      updatedFields[fieldIndex] = {
        ...updatedFields[fieldIndex],
        id: fieldRes?.data?.data?.field._id,
        fieldId: fieldRes?.data?.data?.field.fieldId,
        title: fieldRes?.data?.data?.field.title
      };
      newSections[sectionIndex] = {
        ...newSections[sectionIndex],
        fields: updatedFields
      };

      return newSections;
    });

    if (fieldRes.status === 200) {
      setIsFieldCreated((prevStates) => {
        const newStates = [...prevStates];
        newStates[sectionIndex][fieldIndex] = true;
        return newStates;
      });
      setFieldLoading((prevStates) => {
        const newStates = [...prevStates];
        newStates[sectionIndex][fieldIndex] = false;
        return newStates;
      });
      if (
        fieldRes?.data?.data?.field?.heading === 'formula' ||
        fieldRes?.data?.data?.field?.heading === 'input'
      ) {
        setRangeVisible((prevVisibility) => {
          const newVisibility = [...prevVisibility];
          newVisibility[sectionIndex][fieldIndex] = [];
          newVisibility[sectionIndex][fieldIndex].push(true);
          return newVisibility;
        });
        if (fieldIndex > 0 || sectionIndex > 0) {
          addRangeContainer(sectionIndex, fieldIndex);
        }
      }

      message.success(fieldRes?.data?.message);
    } else {
      message.error('An error occurred');
    }
  };

  const addRangeContainer = (sectionIndex, fieldIndex) => {
    setFormSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[sectionIndex].fields[fieldIndex].ranges.push({
        id: '',
        rangeId: '',
        title: '',
        rangeType: '',
        condition: '',
        description: '',
        suggestion: '',
        value: '',
        rangeValue: '',
        formula: '',
        formulaCheckbox: false,
        serial: 1
      });
      return newSections;
    });
  };

  const handleDeleteRange = async (sectionIndex, fieldIndex, rangeIndex) => {
    if (formSections[sectionIndex]?.fields[fieldIndex]?.ranges[rangeIndex]?.id) {
      try {
        const delRangeRes = await deleteRange(
          getAllRangeURL,
          formSections[sectionIndex]?.fields[fieldIndex]?.ranges[rangeIndex]?.id
        );
        if (delRangeRes.status === 200) {
          setFormSections((prevSections) => {
            const newSections = [...prevSections];
            newSections[sectionIndex].fields[fieldIndex].ranges.splice(rangeIndex, 1);
            return newSections;
          });
          setIsRangeCreated((prevStates) => {
            const newStates = [...prevStates];
            if (newStates[sectionIndex] && newStates[sectionIndex][fieldIndex]) {
              newStates[sectionIndex][fieldIndex] = newStates[sectionIndex][fieldIndex].filter(
                (_, idx) => idx !== rangeIndex
              );
            }
            return newStates;
          });
          setRangeLoading((prevStates) => {
            const newStates = [...prevStates];
            if (newStates[sectionIndex] && newStates[sectionIndex][fieldIndex]) {
              newStates[sectionIndex][fieldIndex] = newStates[sectionIndex][fieldIndex].filter(
                (_, idx) => idx !== rangeIndex
              );
            }
            return newStates;
          });
          message.success('Range deleted successfuly');
        }
      } catch (error) {
        message.error('Failed to delete range');
      }
    } else {
      setFormSections((prevSections) => {
        const newSections = [...prevSections];
        newSections[sectionIndex].fields[fieldIndex].ranges.splice(rangeIndex, 1);
        return newSections;
      });
      setIsRangeCreated((prevStates) => {
        const newStates = [...prevStates];
        if (newStates[sectionIndex] && newStates[sectionIndex][fieldIndex]) {
          newStates[sectionIndex][fieldIndex] = newStates[sectionIndex][fieldIndex].filter(
            (_, idx) => idx !== rangeIndex
          );
        }
        return newStates;
      });
      setRangeLoading((prevStates) => {
        const newStates = [...prevStates];
        if (newStates[sectionIndex] && newStates[sectionIndex][fieldIndex]) {
          newStates[sectionIndex][fieldIndex] = newStates[sectionIndex][fieldIndex].filter(
            (_, idx) => idx !== rangeIndex
          );
        }
        return newStates;
      });
      message.success('Range deleted successfuly');
    }
  };

  // Save Range API Function
  const handleRangeFormSubmit = async (sectionIndex, fieldIndex, rangeIndex) => {
    if (
      formSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].title === '' ||
      formSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].condition === ''
    ) {
      message.error('Please ensure that All Fields are filled');
      return;
    }

    const rangeType =
      formSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].formulaCheckbox;

    if (
      rangeType &&
      formSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].formula === ''
    ) {
      message.error('Formula cannot be empty');
      return;
    }

    if (
      !rangeType &&
      formSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].rangeValue === ''
    ) {
      message.error('Value cannot be empty');
      return;
    }

    setRangeLoading((prevStates) => {
      const newStates = [...prevStates];
      newStates[sectionIndex][fieldIndex][rangeIndex] = true;
      return newStates;
    });
    const newRangeData = {
      title: formSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].title,
      fieldId: formSections[sectionIndex]?.fields[fieldIndex]?.id,
      formula: formSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].formulaCheckbox
        ? formSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].formula
        : '',
      rangeValue: formSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].formulaCheckbox
        ? ''
        : formSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].rangeValue,
      rangeType: formSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].formulaCheckbox
        ? 'formula'
        : 'value',
      condition: formSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].condition,
      description: formSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].description,
      suggestion: formSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].suggestion
    };

    const rangeRes = await addRange(getAllRangeURL + '', newRangeData);
    setRangeDataResponse(rangeRes?.data);

    setFormSections((prevSections) => {
      const newSections = [...prevSections];

      newSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].rangeId =
        rangeRes?.data?.data?.response?.rangeId;
      newSections[sectionIndex].fields[fieldIndex].ranges[rangeIndex].id =
        rangeRes?.data?.data?.response?._id;

      return newSections;
    });

    if (rangeRes.status === 200) {
      message.success(rangeRes?.data?.message);
      // setIsRangeCreated(true);
      setIsRangeCreated((prevStates) => {
        const newStates = [...prevStates];
        newStates[sectionIndex][fieldIndex][rangeIndex] = true;
        return newStates;
      });
      setRangeLoading((prevStates) => {
        const newStates = [...prevStates];
        newStates[sectionIndex][fieldIndex][rangeIndex] = false;
        return newStates;
      });
    } else {
      message.error('An error occurred');
    }
  };

  const handleAddFindingContainer = () => {
    setFindingsData((prevFindingsData) => [
      ...prevFindingsData,
      {
        id: '',
        title: '',
        categoryId: '',
        details: [
          {
            heading: '',
            description: ''
          }
        ],
        valueType: '',
        created: false
      }
    ]);
    setFindingLoading((prevStates) => [...prevStates, false]);
  };

  const handleAddDetails = (findingsDataIndex) => {
    setFindingsData((prevFindingsData) => {
      const newFindingsData = [...prevFindingsData];
      const selectedItem = newFindingsData[findingsDataIndex];
      selectedItem.details.push({
        heading: '',
        description: ''
      });

      return newFindingsData;
    });
  };

  const handleAddFinding = async (index) => {
    if (isSectionCreated[0] === true) {
      if (!findingsData[index].title) {
        message.error('Title cannot be empty');
        return;
      } else if (!findingsData[index].valueType) {
        message.error('You should choose the value type');
        return;
      }
      if (findingsData[index].valueType === 'multiple') {
        if (
          !findingsData[index].details.every(
            (detail) => detail.heading !== '' && detail.description !== ''
          )
        ) {
          message.error('Heading and description cannot be empty for multiple value type.');
          return false;
        }
      } else if (findingsData[index].valueType === 'single') {
        if (
          !(
            findingsData[index].details.length > 0 &&
            findingsData[index].details[0].description !== ''
          )
        ) {
          message.error('Description cannot be empty in single value type.');
          return false;
        }
      }
      setFindingLoading((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = true;
        return newStates;
      });
      try {
        let payload;
        if (findingsData[index].valueType === 'single') {
          const findingDetail = [
            {
              heading: '',
              description: findingsData[index].details[0].description
            }
          ];

          payload = {
            title: findingsData[index].title,
            formId: formDataResponse?.data?._id,
            findingCategoryId: findingsData[index].categoryId,
            details: findingDetail
          };
        } else {
          payload = {
            title: findingsData[index].title,
            formId: formDataResponse?.data?._id,
            findingCategoryId: findingsData[index].categoryId,
            details: findingsData[index].details
          };
        }

        const findingRes = await addFinding(getAllFindingsUrl + '', payload);
        if (findingRes?.status === 200) {
          setFindingLoading((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = false;
            return newStates;
          });
          setFindingsData((prevFindingsData) => {
            // Create a new array with the updated value
            const updatedFindingsData = [...prevFindingsData];
            updatedFindingsData[index] = {
              ...updatedFindingsData[index],
              id: findingRes?.data?.data?.formFinding?._id,
              created: true
            };
            return updatedFindingsData;
          });
          message.success(' Finding Added succesfully');
        }
      } catch (error) {
        console.error('Error while submitting findings:', error);
      }
    } else {
      message.error(' Please add atleast one section');
    }
  };

  const handleRemoveDetail = (findingIndex, detailIndex) => {
    const updatedFindingData = findingsData.map((finding, i) => {
      if (i === findingIndex) {
        const updatedDetails = [...finding.details];
        updatedDetails.splice(detailIndex, 1);
        return { ...finding, details: updatedDetails, updated: true };
      }
      return finding;
    });

    setFindingsData(updatedFindingData);
  };

  const handleDeleteFinding = async (index) => {
    if (findingsData[index].created) {
      try {
        const findingRes = await deleteFinding(getAllFindingsUrl, findingsData[index]?.id);
        if (findingRes?.status === 200) {
          message.success(' Finding deleted successfully');
          setFindingsData((prevFindingsData) => prevFindingsData.filter((_, i) => i !== index));
        }
      } catch (error) {
        console.error('Error while deleting finding:', error);
      }
    } else {
      setFindingsData((prevFindingsData) => prevFindingsData.filter((_, i) => i !== index));
    }

    setFindingLoading((prevStates) => {
      const newStates = [...prevStates];

      // Remove the entire array at the specified sectionIndex
      newStates.splice(index, 1);

      return newStates;
    });
  };

  const submitForm = () => {
    message.success(' Form Submitted successfully');
    setTimeout(() => {
      // Route to the dashboard
      navigate('/form');
    }, 2000);
  };

  // Function to render the menu for each card
  const renderCardMenu = (sectionIndex) => (
    <Menu>
      <Menu.Item
        key='delete'
        className='customDelete'
        onClick={() => handledeleteSection(sectionIndex)}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  // Dropdown menu content
  const menuFieldDelete = (sectionIndex, fieldIndex) => (
    <Menu onClick={() => handleDeleteField(sectionIndex, fieldIndex)}>
      <Menu.Item key='delete' className='customDelete'>
        Delete
      </Menu.Item>
    </Menu>
  );

  const handleSpeciesChange = async (value, option) => {
    setSelectedSpecies(option ? { id: value, name: option.label } : null);

    if (option) {
      const ids = [value];
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        await dispatch(fetchFormGroups(formGroupUrl, headers));
        const response = await getOrganBySpecie(getOrganBySpecieURL + '', { ids });
        setOrganList(response?.data?.data[0]);
      } catch (error) {
        console.error('Error fetching organs:', error);
      }
    }
  };

  const handleFindingCategory = async () => {
    setFindingCategoryLoading(true);
    const commaSeparatedString = findingCategories.join(', ');
    try {
      const categoryRes = await addData(getAllFindingCategoriesUrl, {
        title: commaSeparatedString,
        formId: formDataResponse?.data?._id
      });
      setFindingCategories(categoryRes?.data?.data?.formFindingCategory);
      if (categoryRes?.status === 200) {
        setFindingVisible(true);
        setCategoryVisible(false);
        setFindingCategoryLoading(false);
      } else {
        setFindingCategoryLoading(false);
      }
    } catch (error) {
      setFindingCategoryLoading(false);
      console.error('Error fetching organs:', error);
    }
  };

  function findFieldValue(customFieldId) {
    let fieldValue = null;
    for (const section of formSections) {
      for (const field of section.fields) {
        if (field.fieldId === customFieldId) {
          return field.inputType;
        }
      }
    }

    return fieldValue;
  }

  const handleSectionFieldsChange = (e: React.ChangeEvent<HTMLInputElement>, sectionIndex: number) => {
    const { name, value } = e.target;
    const newSections = [...formSections];
    newSections[sectionIndex][name] = value;
    setFormSections(newSections);
  }

  return (
    <>
      <div className='form-header flex-column'>
        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group form_label_div'>
              <label htmlFor='speciesInput'>Species:</label>
              <Select
                id='speciesInput'
                value={selectedSpecies?.id}
                onChange={handleSpeciesChange}
                placeholder='Species'
                disabled={isFormCreated}
              >
                {species.map((item) => (
                  <Select.Option key={item?._id} value={item._id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className='col-sm-6 col-12'>
            <div className='form-group form_label_div'>
              <label htmlFor='organInput'>Organ:</label>
              <Select
                id='organInput'
                value={selectedOrgan?.id}
                onChange={(value: any, option: any) =>
                  setSelectedOrgan(option ? { id: value, name: option.label } : null)
                }
                placeholder='Organ'
                disabled={isFormCreated}
              >
                {organList.map((item) => (
                  <Select.Option key={item?._id} value={item._id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group form_label_div'>
              <label htmlFor='form-formGroup'>Group:</label>
              <Select
                id='form-formGroup'
                placeholder='Select Form Group'
                value={
                  formGroupSelect?.category?._id
                    ? formGroupSelect?.category?._id
                    : formGroupSelect?.category
                }
                onChange={(value) => {
                  setFormGroupSelect(value);
                }}
                disabled={isFormCreated}
              >
                {formGroups.map((item) => {
                  return (
                    <Option key={item?._id} value={item._id}>
                      {item?.title}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className='col-sm-6 col-12'>
            <div className='form-group form_label_div'>
              <label htmlFor='formNameInput'>Name:</label>
              <Input
                id='formNameInput'
                placeholder='Form Name'
                value={inputTextFormTitle}
                onChange={(e) => setInputTextFormTitle(e.target.value)}
                disabled={isFormCreated}
              />
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-end'>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleCreateFormClick}
            disabled={isFormCreated}
            style={isFormCreated && { background: 'green', border: 'none' }}
          >
            {isFormCreated ? (
              <>
                <CheckCircleOutlined style={{ color: 'white', marginLeft: '8px' }} />
                Form Created
              </>
            ) : (
              <>
                <i className='icofont-plus-circle'></i> Create Form
              </>
            )}
          </Button>
        </div>
      </div>
      <div>
        {formVisible && (
          <div className='form-container'>
            {formSections.map((section, sectionIndex) => (
              <div className='card_section' key={section?.id}>
                <Card
                  title={
                    <div className='sub_section_title'>
                      {formSections[sectionIndex]?.title ? (
                        <>
                          {formSections[sectionIndex]?.title}
                          &nbsp;-&nbsp;
                          <span className='card_hint'>
                            [ {formSections[sectionIndex]?.sectionId} ]
                          </span>
                        </>
                      ) : (
                        'Section'
                      )}
                    </div>
                  }
                  extra={
                    <Dropdown overlay={renderCardMenu(sectionIndex)} placement='topRight'>
                      {/* <Button type='text' icon={<MoreOutlined style={{ color: 'black' }} />} /> */}
                      <Button
                        type='text'
                        className='custom-dropdown-button'
                        style={{
                          background: 'white',
                          border: ' none',
                          boxShadow: 'none'
                        }}
                      >
                        <MoreOutlined style={{ color: 'black', fontSize: '20px' }} />
                      </Button>
                    </Dropdown>
                  }
                >
                  {isSectionCreated[sectionIndex] ? null : (
                    <div className='card_section_title'>
                      <div className='form-input form_label_div'>
                        <label htmlFor='section-title'>Title:</label>
                        <Input
                          id='section-title'
                          placeholder='Title'
                          name='title'
                          value={section.title}
                          onChange={(e) => handleSectionFieldsChange(e, sectionIndex)}
                          disabled={isSectionCreated[sectionIndex]}
                        />
                      </div>


                      <Button
                        type='primary'
                        onClick={() => {
                          handleCreateSection(sectionIndex);
                        }}
                        loading={sectionLoading[sectionIndex]}
                        disabled={isSectionCreated[sectionIndex] || sectionLoading[sectionIndex]}
                        style={
                          isSectionCreated[sectionIndex] && { background: 'green', border: 'none' }
                        }
                      >
                        {isSectionCreated[sectionIndex] ? (
                          <>
                            <CheckCircleOutlined style={{ color: 'white', marginLeft: '8px' }} />
                            Section Created
                          </>
                        ) : (
                          <>
                            <i className='icofont-plus-circle'></i> Create Section
                          </>
                        )}
                      </Button>
                    </div>
                  )}

                  {fieldVisible[sectionIndex] && (
                    <div className='field-container'>
                      {section.fields.map((field, fieldIndex) => (
                        <>
                          <div
                            className={`${fieldVisible[sectionIndex] ? 'mt-0' : ''
                              } card_create_section`}
                            style={{ position: 'relative' }}
                          >
                            <Dropdown
                              overlay={menuFieldDelete(sectionIndex, fieldIndex)}
                              placement='bottomRight'
                            >
                              <Button
                                type='text'
                                style={{
                                  position: 'absolute',
                                  top: '8px',
                                  right: '10px',
                                  zIndex: '999',
                                  background: 'white',
                                  border: ' none',
                                  boxShadow: 'none'
                                }}
                              >
                                <MoreOutlined style={{ fontSize: '20px' }} />
                              </Button>
                            </Dropdown>
                            <h4 className='sub_section_title'>
                              {formSections[sectionIndex].fields[fieldIndex].title ? (
                                <>
                                  {formSections[sectionIndex].fields[fieldIndex].title}&nbsp;-&nbsp;
                                  <span className='card_hint'>
                                    [ {formSections[sectionIndex].fields[fieldIndex].fieldId} ]
                                  </span>
                                </>
                              ) : (
                                'Create Field'
                              )}
                            </h4>

                            <div className='card_create_section_header d-flex justify-content-between flex-column flex-sm-row'>
                              <div className='form-input form_label_div'>
                                <label htmlFor='field-title'>Title:</label>
                                <Input
                                  id='field-title'
                                  onChange={(e) =>
                                    handleInputChange(e, 'title', sectionIndex, fieldIndex)
                                  }
                                  value={formSections[sectionIndex].fields[fieldIndex].title}
                                  placeholder='Title'
                                  disabled={isFieldCreated[sectionIndex][fieldIndex]}
                                />
                              </div>
                              <div className='form-input form_label_div'>
                                <label htmlFor='field-type' style={{ width: '8rem' }}>
                                  Input Type:
                                </label>

                                <Select
                                  id='field-type'
                                  value={formSections[sectionIndex].fields[fieldIndex].inputType}
                                  onChange={(value) =>
                                    setInputType(value, sectionIndex, fieldIndex)
                                  }
                                  placeholder='Input Type'
                                  disabled={isFieldCreated[sectionIndex][fieldIndex]}
                                >
                                  <Option key='select' value={'dropdown'}>
                                    {'DropDown'}
                                  </Option>
                                  <Option key='input' value={'input'}>
                                    {'input'}
                                  </Option>
                                  <Option key='text' value={'text'}>
                                    {'text'}
                                  </Option>
                                  <Option key='formula' value={'formula'}>
                                    {'formula'}
                                  </Option>
                                </Select>
                              </div>
                              <div>
                                <Button
                                  type='primary'
                                  onClick={() => {
                                    handleCreateField(sectionIndex, fieldIndex);
                                  }}
                                  loading={fieldLoading[sectionIndex][fieldIndex]}
                                  disabled={
                                    isFieldCreated[sectionIndex][fieldIndex] ||
                                    fieldLoading[sectionIndex][fieldIndex]
                                  }
                                  style={
                                    isFieldCreated[sectionIndex][fieldIndex] && {
                                      background: 'green',
                                      border: 'none'
                                    }
                                  }
                                >
                                  {isFieldCreated[sectionIndex][fieldIndex] ? (
                                    <>
                                      <CheckCircleOutlined
                                        style={{ color: 'white', marginLeft: '8px' }}
                                      />
                                      Field Created
                                    </>
                                  ) : (
                                    <>
                                      <i className='icofont-plus-circle'></i> Create Field
                                    </>
                                  )}
                                </Button>
                              </div>
                            </div>

                            {formSections[sectionIndex].fields[fieldIndex].inputType ===
                              'dropdown' && (
                                <div className='card_create_sub_section'>
                                  <div className='form-input form_label_div'>
                                    <label htmlFor='test-parameter'>Dropdown Options:</label>

                                    <Select
                                      id='test-parameter'
                                      mode='tags'
                                      placeholder='Please select'
                                      defaultValue={
                                        formSections[sectionIndex].fields[fieldIndex].dropdown
                                      }
                                      onChange={(value) =>
                                        handleChangeMulti(value, sectionIndex, fieldIndex)
                                      }
                                      style={{ width: '100%' }}
                                      options={options}
                                      disabled={isFieldCreated[sectionIndex][fieldIndex]}
                                    />
                                  </div>
                                </div>
                              )}
                            <form>
                              {formSections[sectionIndex].fields[fieldIndex].inputType ===
                                'formula' && (
                                  <div className='card_create_sub_section'>
                                    <div className='sub_field_inputs'>
                                      <div className='form-input form_label_div'>
                                        <label htmlFor='type-formula'>Formula:</label>
                                        <Input
                                          id='type-formula'
                                          placeholder='Formula'
                                          value={
                                            formSections[sectionIndex].fields[fieldIndex].formula
                                          }
                                          onChange={(e) => {
                                            handleInputChange(e, 'formula', sectionIndex, fieldIndex);
                                          }}
                                          disabled={isFieldCreated[sectionIndex][fieldIndex]}
                                        />
                                      </div>
                                      <ul className='nodes'>
                                        <li>
                                          Use capital <strong>W</strong> to denote weight in your
                                          formulas.
                                        </li>
                                        <li>
                                          Represent body surface area as <strong>BSA</strong> in your
                                          formulas.
                                        </li>
                                        <li>
                                          To represent an exponent, use <strong>**</strong>. For
                                          example, W**2 represents weight squared.
                                        </li>
                                        <li>
                                          To represent a square root, use <strong>SQRT</strong>. For
                                          example, SQRT(16) represents 4.
                                        </li>
                                        <li>
                                          To represent a log, use <strong>LN</strong>. For example,
                                          LN(10) represents 1.
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                            </form>

                            {rangeVisible[sectionIndex] &&
                              rangeVisible[sectionIndex][fieldIndex] && (
                                <div className='range-container bg-[#f5f5f561] p-15 br-12'>
                                  {field.ranges.map((range, rangeIndex) => (
                                    <>
                                      <div className='sub_section_inputs_parent'>
                                        <div className='range_title_div'>
                                          <h5 className='sub_section_title'>
                                            {isRangeCreated[sectionIndex][fieldIndex][
                                              rangeIndex
                                            ] ? (
                                              <>
                                                {
                                                  formSections[sectionIndex].fields[fieldIndex]
                                                    ?.ranges[rangeIndex].title
                                                }
                                                &nbsp;-&nbsp;
                                                <span className='card_hint'>
                                                  [{' '}
                                                  {
                                                    formSections[sectionIndex].fields[fieldIndex]
                                                      ?.ranges[rangeIndex].rangeId
                                                  }{' '}
                                                  ]
                                                </span>
                                              </>
                                            ) : (
                                              'Range'
                                            )}
                                          </h5>
                                        </div>
                                        <div className='sub_field_inputs'>
                                          <div className='form-input form_label_div'>
                                            <label htmlFor='range-name'>Name:</label>
                                            <Input
                                              id='range-name'
                                              placeholder='Name'
                                              value={
                                                formSections[sectionIndex].fields[fieldIndex]
                                                  .ranges[rangeIndex].title
                                              }
                                              onChange={(e) =>
                                                handleRangeInputChange(
                                                  e,
                                                  'title',
                                                  sectionIndex,
                                                  fieldIndex,
                                                  rangeIndex
                                                )
                                              }
                                              disabled={
                                                isRangeCreated[sectionIndex][fieldIndex][rangeIndex]
                                              }
                                            />
                                          </div>

                                          <div className='form-input form_label_div'>
                                            <label htmlFor='range-addCondition'>
                                              Add Condition:
                                            </label>
                                            <Input
                                              id='range-addCondition'
                                              placeholder='Add Condition'
                                              value={
                                                formSections[sectionIndex].fields[fieldIndex]
                                                  .ranges[rangeIndex].condition
                                              }
                                              onChange={(e) =>
                                                handleRangeInputChange(
                                                  e,
                                                  'condition',
                                                  sectionIndex,
                                                  fieldIndex,
                                                  rangeIndex
                                                )
                                              }
                                              disabled={
                                                isRangeCreated[sectionIndex][fieldIndex][rangeIndex]
                                              }
                                            />
                                          </div>

                                          <div className='form-input form_label_div formula_input_div'>
                                            <div className='checkbox_div'>
                                              <label htmlFor='range-addFormula'>
                                                Add Formula? :
                                              </label>
                                              <Input
                                                id='range-addFormula'
                                                type='checkbox'
                                                style={{ height: '1.5rem' }}
                                                onChange={() =>
                                                  setFormulaCheckbox(
                                                    sectionIndex,
                                                    fieldIndex,
                                                    rangeIndex
                                                  )
                                                }
                                                disabled={
                                                  isRangeCreated[sectionIndex][fieldIndex][
                                                  rangeIndex
                                                  ]
                                                }
                                              />
                                            </div>
                                            {formSections[sectionIndex].fields[fieldIndex].ranges[
                                              rangeIndex
                                            ].formulaCheckbox ? (
                                              <div>
                                                <label htmlFor='range-value'>Formula:</label>
                                                <Input
                                                  id='range-addFormula'
                                                  placeholder='Add Formula'
                                                  className='input_setup'
                                                  value={
                                                    formSections[sectionIndex].fields[fieldIndex]
                                                      .ranges[rangeIndex].formula
                                                  }
                                                  onChange={(e) =>
                                                    handleRangeInputChange(
                                                      e,
                                                      'formula',

                                                      sectionIndex,
                                                      fieldIndex,
                                                      rangeIndex
                                                    )
                                                  }
                                                  disabled={
                                                    isRangeCreated[sectionIndex][fieldIndex][
                                                    rangeIndex
                                                    ]
                                                  }
                                                />
                                                <div className='nodes_style'>
                                                  <ul>
                                                    <li>
                                                      Use capital <strong>W</strong> to denote
                                                      weight in your formulas.
                                                    </li>
                                                    <li>
                                                      Represent body surface area as{' '}
                                                      <strong>BSA</strong> in your formulas.
                                                    </li>
                                                    <li>
                                                      To represent an exponent, use{' '}
                                                      <strong>**</strong>. For example, W**2
                                                      represents weight squared.
                                                    </li>
                                                    <li>
                                                      To represent a square root, use{' '}
                                                      <strong>SQRT</strong>. For example, SQRT(16)
                                                      represents 4.
                                                    </li>
                                                    <li>
                                                      To represent a log, use <strong>LN</strong>.
                                                      For example, LN(10) represents 1.
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                <label htmlFor='range-value'>Value:</label>
                                                <Input
                                                  min={0}
                                                  type='number'
                                                  id='range-value'
                                                  placeholder='Value'
                                                  value={
                                                    formSections[sectionIndex].fields[fieldIndex]
                                                      .ranges[rangeIndex].rangeValue
                                                  }
                                                  onChange={(e) =>
                                                    handleRangeInputChange(
                                                      e,
                                                      'rangeValue',
                                                      sectionIndex,
                                                      fieldIndex,
                                                      rangeIndex
                                                    )
                                                  }
                                                  disabled={
                                                    isRangeCreated[sectionIndex][fieldIndex][
                                                    rangeIndex
                                                    ]
                                                  }
                                                />
                                              </>
                                            )}
                                          </div>
                                        </div>

                                        <div className='range_btns'>
                                          <Button
                                            type='primary'
                                            onClick={() =>
                                              handleRangeFormSubmit(
                                                sectionIndex,
                                                fieldIndex,
                                                rangeIndex
                                              )
                                            }
                                            loading={
                                              rangeLoading[sectionIndex][fieldIndex][rangeIndex]
                                            }
                                            disabled={
                                              isRangeCreated[sectionIndex][fieldIndex][
                                              rangeIndex
                                              ] ||
                                              rangeLoading[sectionIndex][fieldIndex][rangeIndex]
                                            }
                                            style={
                                              isRangeCreated[sectionIndex][fieldIndex][
                                              rangeIndex
                                              ] && {
                                                background: 'green',
                                                border: 'none'
                                              }
                                            }
                                          >
                                            <i className='icofont-save'></i>
                                          </Button>
                                          <Button
                                            type='primary'
                                            style={{ background: 'red', borderColor: 'red' }}
                                            onClick={() =>
                                              handleDeleteRange(
                                                sectionIndex,
                                                fieldIndex,
                                                rangeIndex
                                              )
                                            }
                                          >
                                            <i className='icofont-ui-delete'></i>
                                          </Button>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                  <Button
                                    type='primary'
                                    style={{ marginTop: '20px' }}
                                    className='expandable-button'
                                    onClick={() => addRangeContainer(sectionIndex, fieldIndex)}
                                    onMouseEnter={() =>
                                      setButtonText((prev) => ({ ...prev, range: 'Add Range' }))
                                    }
                                    onMouseLeave={() =>
                                      setButtonText((prev) => ({ ...prev, range: '' }))
                                    }
                                  >
                                    <i className='icofont-ui-add'></i>
                                    {buttonText.range}
                                  </Button>
                                </div>
                              )}
                          </div>
                        </>
                      ))}

                      <Button
                        type='primary'
                        style={{ marginTop: '20px' }}
                        className='expandable-button'
                        onClick={() => addFieldContainer(sectionIndex)}
                        onMouseEnter={() =>
                          setButtonText((prev) => ({ ...prev, field: 'Add Field' }))
                        }
                        onMouseLeave={() => setButtonText((prev) => ({ ...prev, field: '' }))}
                      >
                        <i className='icofont-ui-add'></i>
                        {buttonText.field}
                      </Button>
                    </div>
                  )}
                </Card>
              </div>
            ))}

            <Button
              type='primary'
              style={{ marginTop: '20px' }}
              className='expandable-button'
              onClick={addSectionContainer}
            >
              <i className='icofont-ui-add'></i>
              Add Section
            </Button>
          </div>
        )}
      </div>

      {formVisible && (
        <>
          <div className='card_section findings_section'>
            {categoryVisible && (
              <div className='findingCategory'>
                <div className='form-input form_label_div'>
                  <label htmlFor='findingCategory' style={{ width: '14rem' }}>
                    Finding Categories:
                  </label>

                  <Select
                    id='findingCategory'
                    className='categoryInput'
                    mode='tags'
                    placeholder='Please add Finding categories'
                    defaultValue={findingCategories}
                    onChange={(value) => {
                      setFindingCategories(value);
                    }}
                  />
                  <Button
                    type='primary'
                    onClick={handleFindingCategory}
                    loading={findingCategoryLoading}
                  >
                    <i className='icofont-ui-add'></i>
                    Add Categories
                  </Button>
                </div>
              </div>
            )}
            {findingVisible && (
              <>
                <Card title={'Findings'}>
                  <div className='form-container findings-container'>
                    {findingsData.map((item, index) => (
                      <div className='card_create_section findings_div mb-20'>
                        <div className='sub_field_inputs'>
                          <div className='form-input form_label_div'>
                            <label htmlFor='finding-heading'>Title:</label>
                            <Input
                              id={`finding-heading-${index}`}
                              placeholder='heading'
                              value={item.title}
                              onChange={(e) =>
                                handleFindingChange(e.target.value, index, 'title', null)
                              }
                              disabled={findingsData[index].created || findingLoading[index]}
                            />
                          </div>
                          <div className='form-input form_label_div'>
                            <label htmlFor='category' style={{ width: '6rem' }}>
                              Category:
                            </label>

                            <Select
                              id='category'
                              placeholder='Finding categories'
                              onChange={(value) =>
                                handleFindingChange(value, index, 'categoryId', null)
                              }
                              disabled={findingsData[index].created || findingLoading[index]}
                            >
                              {findingCategories.map((category) => (
                                <Option key={category?._id} value={category?._id}>
                                  {category?.title}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className='form-input form_label_div'>
                            <label htmlFor='value-type' style={{ width: '6rem' }}>
                              Values:
                            </label>

                            <Select
                              id='value-type'
                              value={item.valueType}
                              onChange={(value) =>
                                handleFindingChange(value, index, 'valueType', null)
                              }
                              placeholder='Value Type'
                              disabled={findingsData[index].created || findingLoading[index]}
                            >
                              <Option key='select' value='single'>
                                {'Single'}
                              </Option>
                              <Option key='input' value='multiple'>
                                {'Multiple'}
                              </Option>
                            </Select>
                          </div>
                        </div>
                        {item.valueType === 'single' && (
                          <div className='card_create_sub_section'>
                            <div className='form-input form_label_div'>
                              <label htmlFor='finding-Description'>Description:</label>
                              <TextArea
                                id={`finding-description-${index}`}
                                placeholder='Description'
                                rows={3}
                                value={item.details[0].description}
                                onChange={(e) =>
                                  handleFindingChange(e.target.value, index, 'description', 0)
                                }
                                disabled={findingsData[index].created || findingLoading[index]}
                              />
                            </div>
                          </div>
                        )}
                        {item.valueType === 'multiple' && (
                          <>
                            {item.details.map((values, detailIndex) => (
                              <div className='card_create_sub_section multiple_finding'>
                                <div className='findings_double_value_div position-relative '>
                                  <div className='form-input form_label_div'>
                                    <label htmlFor='finding-lable'>Label:</label>
                                    <Input
                                      id={`finding-label-${index}`}
                                      placeholder='label'
                                      value={values.heading}
                                      onChange={(e) =>
                                        handleFindingChange(
                                          e.target.value,
                                          index,
                                          'heading',
                                          detailIndex
                                        )
                                      }
                                      disabled={
                                        findingsData[index].created || findingLoading[index]
                                      }
                                    />
                                  </div>
                                  <div className='form-input form_label_div findings_double_value_description'>
                                    <label htmlFor='finding-Description' style={{ width: '10rem' }}>
                                      Description:
                                    </label>
                                    <TextArea
                                      id={`finding-description-${index}`}
                                      placeholder='Description'
                                      rows={3}
                                      value={values.description}
                                      onChange={(e) =>
                                        handleFindingChange(
                                          e.target.value,
                                          index,
                                          'description',
                                          detailIndex
                                        )
                                      }
                                      disabled={
                                        findingsData[index].created || findingLoading[index]
                                      }
                                    />
                                  </div>
                                  {!findingsData[index].created && (
                                    <button
                                      className='close_button'
                                      onClick={() => handleRemoveDetail(index, detailIndex)}
                                      disabled={
                                        findingsData[index].created || findingLoading[index]
                                      }
                                    >
                                      &times;
                                    </button>
                                  )}
                                </div>
                              </div>
                            ))}

                            {!findingsData[index].created && (
                              <Button
                                type='primary'
                                style={{ marginTop: '0.5rem', width: '18%' }}
                                className='expandable-button'
                                onClick={() => handleAddDetails(index)}
                                disabled={findingLoading[index]}
                              >
                                <i className='icofont-ui-add'></i>
                                Add Value
                              </Button>
                            )}
                          </>
                        )}

                        <div className='range_btns'>
                          <Button
                            type='primary'
                            onClick={() => handleAddFinding(index)}
                            loading={findingLoading[index]}
                            disabled={findingsData[index].created || findingLoading[index]}
                            style={
                              findingsData[index].created && {
                                background: 'green',
                                border: 'none'
                              }
                            }
                          >
                            <i className='icofont-save'></i>
                          </Button>
                          <Button
                            type='primary'
                            style={{ background: 'red', borderColor: 'red' }}
                            onClick={() => handleDeleteFinding(index)}
                          >
                            <i className='icofont-ui-delete'></i>
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </Card>
                <Button
                  type='primary'
                  style={{ marginTop: '20px' }}
                  className='expandable-button'
                  onClick={handleAddFindingContainer}
                >
                  <i className='icofont-ui-add'></i>
                  Add Finding
                </Button>
              </>
            )}
          </div>
          <div className='submit_button'>
            <Button
              type='primary'
              style={{ marginTop: '20px' }}
              className='expandable-button'
              onClick={() => {
                submitForm();
              }}
            >
              Submit Form
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default DataTable;
