import { LoadingOutlined } from '@ant-design/icons';
import { Button, Collapse, Input, Select, Spin, Table, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  getAllFindingCategoriesUrl,
  getAllReportURL,
  getSingleFormWithRangeValueURL,
  reportMediaURL
} from '../../../constants/url';
import { usePageData } from '../../../hooks/usePage';
import { IFinding } from '../../../interfaces/finding';
import { IForms, IRange } from '../../../interfaces/forms';
import { IPageData } from '../../../interfaces/page';
import ConclusionTemplate from './ConclusionTemplate';
import MultipleImgUpload from './MultipleImgUpload';
import { getForm, postReportData, postReportFindingData, postReportMedia } from './api';
import Finding from './findings';
import './form.scss';

const { Column } = Table;
const { Option } = Select;
const { Panel } = Collapse;
const pageData: IPageData = {
  title: 'Create Report',
  fulFilled: true
};
interface DataRow {
  title: string;
  heading: string;
  dropdown: [];
  value: string;
  fieldId: string;
  formula: string;
  ranges: [];
}

interface DataTableProps {
  formApi: any;
  patient: any;
  reset: any;
}

const extractStandards = (formData) => {
  const sectionStandards = [];

  formData?.sections.forEach((section) => {
    const fieldStandards = [];

    section.fields.forEach((field) => {
      const fieldRanges = [];

      field.ranges.forEach((range) => {
        if (range.title && !fieldRanges.includes(range.title)) {
          fieldRanges.push(range.title);
        }
      });

      fieldStandards.push({
        fieldId: field._id,
        standards: fieldRanges
      });
    });

    sectionStandards.push({
      sectionId: section._id,
      fieldStandards: fieldStandards
    });
  });

  return sectionStandards;
};

const DataTable: React.FC<DataTableProps> = ({ formApi, patient, reset }) => {
  const [checkedFindingIds, setCheckedFindingIds] = useState<
    {
      id: string;
      title: string;
      heading: string;
      descriptions: [{ id: string; description: string }];
    }[]
  >([]);
  const [formData, setFormData] = useState<IForms | null>(null);
  const [findingData, setFindingData] = useState<IFinding[]>([]);
  const [standards, setStandards] = useState([]);
  const [reportSubmit, setReportSubmit] = useState(false);
  const [reportId, setReportId] = useState('');
  const isFirstRun = useRef(true);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [reportRes, setReportRes] = useState(null);
  const [loadings, setLoadings] = useState(true);
  const [sectionLoading, setSectionLoading] = useState([]);
  const [sectionSaved, setSectionSaved] = useState([]);

  const [memo, setMemo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [formRes, findingRes] = await Promise.all([
          getForm(getSingleFormWithRangeValueURL, `${formApi}/${patient.weight}`),
          getForm(`${getAllFindingCategoriesUrl}/getbyform/`, formApi)
        ]);

        const updatedFormData = {
          ...formRes.data.data,
          sections: formRes.data.data.sections.map((section) => ({
            ...section,
            fields: section.fields.map((field) => ({
              ...field,
              value: ''
            }))
          }))
        };

        setFormData(updatedFormData);
        setSectionLoading(Array(formRes?.data?.data?.sections?.length).fill(false));
        setSectionSaved(Array(formRes?.data?.data?.sections?.length).fill(false));

        // setFormData(formRes.data.data);
        setFindingData(findingRes.data.data);
        setLoadings(false);
      } catch (error) {
        message.error('Failed to fetch data');
      }
    };

    fetchData();
  }, [formApi]);

  useEffect(() => {
    if (formData) {
      if (isFirstRun.current) {
        const extractedStandards = extractStandards(formData);
        setStandards(extractedStandards);
        isFirstRun.current = false;
      }
    }
  }, [formData]);

  const renderStandardFields = (standard: string, ranges: IRange[]) => {
    const groupedRanges: { [title: string]: IRange[] } = {};
    ranges.forEach((range) => {
      if (range.title) {
        if (!groupedRanges[range.title]) {
          groupedRanges[range.title] = [];
        }
        groupedRanges[range.title].push(range);
      }
    });

    return (
      <React.Fragment key={standard}>
        {groupedRanges[standard]
          ?.filter((rec) => rec.rangeValue !== undefined && rec.rangeValue !== '')
          .sort((a, b) => parseFloat(a.rangeValue) - parseFloat(b.rangeValue))
          .map((rec, index) => (
            <React.Fragment key={index}>
              {rec?.rangeValue && (
                <div style={{ gap: '0.5rem' }} className='d-flex'>
                  <strong>{rec.title}</strong>
                  <div className=''>
                    <strong>{rec.condition}</strong>
                    <span>{rec.rangeValue}</span>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  };

  function findFieldValue(customFieldId) {
    let fieldValue = null;

    for (const section of formData.sections) {
      for (const field of section.fields) {
        if (field.fieldId === customFieldId) {
          fieldValue = field.value;
          break;
        }
      }

      if (fieldValue !== null) {
        break;
      }
    }

    return fieldValue;
  }

  const renderFormulaField = (field: DataRow, sectionIndex, fieldIndex) => {
    let fieldValue;

    const formulaVariables = {
      W: parseFloat(patient.weight),
      BSA: parseFloat(patient.bsa)
    };
    if (
      field.formula &&
      field?.formula !== undefined &&
      field?.formula !== null &&
      field?.formula !== ''
    ) {
      let fieldFormula = field.formula;

      fieldFormula = fieldFormula.replace(/FSF_(\w+)/g, (match, fieldId) => {
        const matchingField = findFieldValue(match);
        return matchingField && matchingField > -1 ? parseFloat(matchingField).toString() : '0';
      });

      fieldFormula = fieldFormula.replaceAll('W', 'formulaVariables.W');
      fieldFormula = fieldFormula.replaceAll('BSA', 'formulaVariables.BSA');
      fieldFormula = fieldFormula.replaceAll('SQRT', 'Math.sqrt');
      fieldFormula = fieldFormula.replaceAll('LN', 'Math.log');

      try {
        const fieldFormulaVal = eval(fieldFormula);

        if (typeof fieldFormulaVal === 'number' && fieldFormulaVal > 0) {
          // Check if the value is a decimal
          if (Number.isInteger(fieldFormulaVal)) {
            // If it's an integer, convert it to a string
            fieldValue = fieldFormulaVal;

            if (fieldValue !== memo[sectionIndex]?.[fieldIndex]) {
              setMemo((prevMemo) => ({
                ...prevMemo,
                [sectionIndex]: {
                  ...prevMemo[sectionIndex],
                  [fieldIndex]: fieldValue
                }
              }));

              // Update formData
              const updatedFormData = { ...formData };
              updatedFormData.sections[sectionIndex].fields[fieldIndex].value = fieldValue;
              setFormData(updatedFormData);
            }
          } else {
            // If it's a decimal, use toFixed to limit it to 3 decimal places
            fieldValue = fieldFormulaVal.toFixed(2);

            if (fieldValue !== memo[sectionIndex]?.[fieldIndex]) {
              setMemo((prevMemo) => ({
                ...prevMemo,
                [sectionIndex]: {
                  ...prevMemo[sectionIndex],
                  [fieldIndex]: fieldValue
                }
              }));

              // Update formData
              const updatedFormData = { ...formData };
              updatedFormData.sections[sectionIndex].fields[fieldIndex].value = fieldValue;
              setFormData(updatedFormData);
            }
          }
        } else {
          // console.log(`Formula of field ${field.formula} does not produce a valid positive number`);
        }
      } catch (err) {
        // console.log(`Formula of field ${field.formula} is not correct`);
      }
    } else {
      // console.log(`Both formula and value of field ${field.formula} not found`);
    }

    return fieldValue;
  };

  const handleSubmitSection = async (sectionIndex) => {
    const newSectionLoading = [...sectionLoading];
    newSectionLoading[sectionIndex] = true;
    setSectionLoading(newSectionLoading);
    const payload = {
      params: {}
    };

    formData.sections[sectionIndex].fields.forEach((field) => {
      if (field.value !== undefined && field.value !== null) {
        payload.params[field._id] = {
          fieldVal: field.value
        };
      }
    });
    const reportData = new FormData();

    reportData.append('hospitalId', 'aaaaaaaaaa');
    reportData.append('patientId', patient._id);
    reportData.append('patientWeight', patient?.weight);
    reportData.append('patientAge', JSON.stringify(patient?.age));
    reportData.append('patientBsa', patient?.bsa);
    reportData.append('patientDoctorRef', patient?.ref_doctor);
    reportData.append('doctorId', patient?.doctor_id?._id);
    reportData.append('caseNumber', patient?.case_number);
    reportData.append('formId', formData?._id);
    reportData.append('params', JSON.stringify(payload.params));
    reportData.append('reportId', reportId);

    try {
      const response = await postReportData(getAllReportURL, reportData);

      if (response.status === 200) {
        setLoadings(false);
        message.success('Report Section Saved Succesfuly');
        setReportRes(response?.data?.data?.reportinfo);
        setReportId(response?.data?.data?.reportinfo?._id);

        const newSectionLoading = [...sectionLoading];
        newSectionLoading[sectionIndex] = false;
        setSectionLoading(newSectionLoading);

        const newSectionSaved = [...sectionSaved];
        newSectionSaved[sectionIndex] = true;
        setSectionSaved(newSectionSaved);
      } else {
        const newSectionLoading = [...sectionLoading];
        newSectionLoading[sectionIndex] = false;
        setSectionLoading(newSectionLoading);
      }
    } catch (error: any) {
      const newSectionLoading = [...sectionLoading];
      newSectionLoading[sectionIndex] = false;
      setSectionLoading(newSectionLoading);
      message.error(error?.response?.data?.message);
      // console.error('Error:', error?.response?.data?.message);
    }
  };

  const handleSubmitReport = async () => {
    setLoadings(true);
    const reportData = new FormData();

    reportData.append('reportId', reportId);

    selectedFiles.forEach((image, index) => {
      reportData.append(`media`, image);
    });
    try {
      const response = await postReportMedia(reportMediaURL, reportData);
      const reportFindingRes = await postReportFindingData(
        `${getAllReportURL}/findings/${reportId}`,
        checkedFindingIds
      );
      if (reportFindingRes.status === 200) {
        setLoadings(false);
        message.success('Report Saved Succesfuly');
        setReportSubmit(true);
      } else {
        setLoadings(false);
      }
    } catch (error: any) {
      setLoadings(false);
      message.error(error?.response?.data?.message);
      // console.error('Error:', error?.response?.data?.message);
    }
  };

  usePageData(pageData);
  return (
    <>
      <Spin
        spinning={loadings}
        size='large'
        indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
      >
        {!reportSubmit ? (
          !loadings && (
            <>
              <div className='sectionStyle'>
                <span className='floatHeading'>Sections</span>
                <Collapse accordion style={{ marginTop: '2rem' }}>
                  {formData?.sections.map((section, mainIndex) => {
                    return (
                      <Panel header={`${section?.title}`} key={section._id}>
                        <Spin
                          spinning={sectionLoading[mainIndex]}
                          size='large'
                          indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                        >
                          {section?.fields.map((field, fieldIndex) => {
                            return (
                              <div key={field._id} className='mt-4'>
                                <Table
                                  dataSource={[field]}
                                  rowKey='id'
                                  pagination={false}
                                  style={{ position: 'relative' }}
                                  className={field?.heading === 'formula' && 'formula-row'}
                                >
                                  <Column
                                    title={`Test Parameter`}
                                    dataIndex='testParameterOption'
                                    key={`test-param-${field._id}`}
                                    className='custome_width_table'
                                    render={(text, record: DataRow) => (
                                      <span> {record?.title}</span>
                                    )}
                                  />

                                  <Column
                                    title={`Value `}
                                    dataIndex='value'
                                    key={`value-${field._id}`}
                                    className='custome_width_table'
                                    render={(text, record: DataRow) => (
                                      <>
                                        {record?.heading === 'input' && (
                                          <Input
                                            value={record.value}
                                            type='text'
                                            className='inputNumber min_custom_width'
                                            placeholder='Number Value'
                                            style={{ background: 'white' }}
                                            disabled={sectionSaved[mainIndex]}
                                            onChange={(value) => {
                                              const isValidNumber = /^\d*\.?\d*$/.test(
                                                value.target.value
                                              );
                                              if (
                                                isValidNumber ||
                                                value.target.value === '' ||
                                                value.target.value === '0'
                                              ) {
                                                const updatedFormData = { ...formData };
                                                updatedFormData.sections[mainIndex].fields[
                                                  fieldIndex
                                                ].value = value.target.value;
                                                setFormData(updatedFormData);
                                              }
                                            }}
                                          />
                                        )}

                                        {record?.heading === 'text' && (
                                          <Input
                                            value={record.value}
                                            type='text'
                                            className='inputNumber min_custom_width'
                                            placeholder='Text Value '
                                            style={{ background: 'white' }}
                                            disabled={sectionSaved[mainIndex]}
                                            onChange={(value) => {
                                              const updatedFormData = { ...formData };
                                              updatedFormData.sections[mainIndex].fields[
                                                fieldIndex
                                              ].value = value.target.value;
                                              setFormData(updatedFormData);
                                            }}
                                          />
                                        )}
                                        {record?.heading === 'dropdown' && (
                                          <Select
                                            value={record.value}
                                            placeholder='Select Value'
                                            className='report_secet'
                                            disabled={sectionSaved[mainIndex]}
                                            onChange={(value) => {
                                              const updatedFormData = { ...formData };
                                              updatedFormData.sections[mainIndex].fields[
                                                fieldIndex
                                              ].value = value;
                                              setFormData(updatedFormData);
                                            }}
                                          >
                                            {record?.dropdown.map((option) => (
                                              <Option key={option} value={option}>
                                                {option}
                                              </Option>
                                            ))}
                                          </Select>
                                        )}

                                        {record?.heading === 'formula' && (
                                          <>
                                            {(() => {
                                              const renderedField = renderFormulaField(
                                                record,
                                                mainIndex,
                                                fieldIndex
                                              );

                                              if (renderedField !== undefined) {
                                                return (
                                                  <Input
                                                    value={renderedField}
                                                    readOnly
                                                    className='min_custom_width'
                                                    disabled={sectionSaved[mainIndex]}
                                                  />
                                                );
                                              }
                                              return null;
                                            })()}

                                            {/* {record.formula} */}
                                          </>
                                        )}
                                      </>
                                    )}
                                  />

                                  {field?.heading !== 'dropdown' ? (
                                    <>
                                      {standards[mainIndex]?.fieldStandards[fieldIndex]
                                        ?.standards !== '' && (
                                        <Column
                                          key={`standard-${field._id}-${fieldIndex}`}
                                          title={'Range'}
                                          className='custome_width_table'
                                          dataIndex='ranges'
                                          render={(text, index: number) => (
                                            <div
                                              style={{
                                                gap: '1rem'
                                              }}
                                              className='d-flex flex-column justify-content-start align-items-start'
                                              key={index}
                                            >
                                              {standards[mainIndex]?.fieldStandards[
                                                fieldIndex
                                              ]?.standards.map((standard, standardIndex) =>
                                                renderStandardFields(standard, field?.ranges)
                                              )}
                                            </div>
                                          )}
                                        />
                                      )}

                                      {field?.heading === 'text' && (
                                        <Column title={''} className='custome_width_table' />
                                      )}
                                      {field?.heading === 'input' && (
                                        <Column title={''} className='custome_width_table' />
                                      )}
                                      {field?.heading === 'formula' && (
                                        <Column title={''} className='custome_width_table' />
                                      )}
                                    </>
                                  ) : (
                                    <Column title={''} className='custome_width_table' />
                                  )}
                                </Table>
                              </div>
                            );
                          })}
                          <div className='saveSection d-flex mt-3 justify-content-end'>
                            <Button
                              type='primary'
                              loading={sectionLoading[mainIndex]}
                              disabled={sectionSaved[mainIndex]}
                              onClick={() => handleSubmitSection(mainIndex)}
                            >
                              {!loadings && <i className='icofont-save'></i>}Save Section
                            </Button>
                          </div>
                        </Spin>
                      </Panel>
                    );
                  })}
                </Collapse>
              </div>
              <div className='sectionStyle'>
                <span className='floatHeading'>Media</span>

                <MultipleImgUpload
                  onImagesChange={setSelectedFiles}
                  initialImages={selectedFiles}
                />
              </div>
              {findingData.length > 0 && (
                <div className='sectionStyle'>
                  <span className='floatHeading'>Findings</span>
                  <Finding
                    findingData={findingData}
                    setFindingData={setFindingData}
                    checkedFindingIds={checkedFindingIds}
                    setCheckedFindingIds={setCheckedFindingIds}
                  />
                </div>
              )}
              <div className='d-flex mt-3 justify-content-center '>
                <Button type='primary' loading={loadings} onClick={handleSubmitReport}>
                  {!loadings && <i className='icofont-save'></i>}Save Report
                </Button>
                <Button
                  className='ml-3'
                  type='primary'
                  onClick={() => {
                    reset(true);
                  }}
                >
                  <i className='icofont-undo'></i>
                  Reset
                </Button>
              </div>
            </>
          )
        ) : (
          <ConclusionTemplate
            repordId={reportRes?._id}
            setLoading={setLoadings}
            specie={patient?.specie?._id}
          />
        )}
      </Spin>
    </>
  );
};

export default DataTable;
