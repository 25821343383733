import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Spin, message } from 'antd';
import axios from 'axios';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { forgotPasswordUrl, getAllUsersURL } from '../../constants/url';
import PublicLayout from '../../layout/public/Public';
import { login } from '../../redux/auth/action';
import './sessions.scss';

const SignInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required')
});

const SignIn = () => {
  const navigate = useNavigate();
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: SignInSchema,
    onSubmit: (values) => {
      setLoading(true);
      axios
        .post(getAllUsersURL, values)
        .then((res) => {
          if (res.status === 200) {
            try {
              if (res.data.data.user.role === 'ADMIN') {
                localStorage.setItem('role', 'admin');
                localStorage.setItem('token', res.data.data.accessToken);
                localStorage.setItem('admin_id', res?.data?.data?.user?._id);
                localStorage.setItem('name', res?.data?.data?.user?.fullName);
                navigate('/default-dashboard');
                dispatch(login());
              } else if (res.data.data.user.role === 'DOCTOR') {
                localStorage.setItem('role', 'doctor');
                localStorage.setItem('doctor_id', res?.data?.data?.user?._id);
                localStorage.setItem('token', res.data.data.accessToken);
                localStorage.setItem('name', res?.data?.data?.user?.fullName);
                navigate('/default-dashboard');
                dispatch(login());
              } else if (res.data.data.user.role === 'HOSPITAL') {
                localStorage.setItem('role', 'hospital');
                localStorage.setItem('token', res.data.data.accessToken);
                navigate('/default-dashboard');
                dispatch(login());
              }
              message.success('User logged in Successfully');
            } catch (err) {
              console.log(err);
              message.error('Authorization Error! Please try again later.');
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            message.error('Invalid credentials');
          } else {
            message.error('Something went wrong');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  const handleFormValuesChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };

  const handleForgotPassword = () => {
    setForgotPasswordModalVisible(true);
  };

  const handleModalClose = () => {
    setForgotPasswordModalVisible(false);
  };

  //separate formik instance for the modal form
  const modalFormik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Email is required')
    }),
    onSubmit: (value) => {
      axios
        .post(forgotPasswordUrl, value)
        .then((res) => {
          if (res.status === 202) {
            message.success('Forgot Link sent successfully');
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 400) {
            message.error('User not found');
          } else {
            message.error('Validation Error');
          }
        })
        .finally(() => {
          setForgotPasswordModalVisible(false);
        });
    }
  });

  return (
    <PublicLayout bgImg={`${window.origin}/content/register-login-bg.png`}>
      <h4 className='mt-0 mb-1'>Welcome Back!</h4>
      <p className='text-color-200'>Enter your Email and Password to access your Account</p>

      <Form
        layout='vertical'
        className='mb-4'
        onFinish={formik.handleSubmit}
        initialValues={formik.initialValues}
      >
        <Form.Item
          name='email'
          label='Email Address'
          rules={[{ required: true, message: 'Email is required' }]}
          validateStatus={formik.errors.email ? 'error' : ''}
          help={formik.errors.email}
        >
          <Input
            placeholder='Enter your email'
            name='email'
            value={formik.values.email}
            onChange={handleFormValuesChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>

        <Form.Item
          name='password'
          label='Password'
          rules={[{ required: true, message: 'Password is required' }]}
          validateStatus={formik.errors.password ? 'error' : ''}
          help={formik.errors.password}
        >
          <Input.Password
            placeholder='Password'
            name='password'
            value={formik.values.password}
            onChange={handleFormValuesChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <Button
          block={false}
          type='primary'
          htmlType='submit'
          style={{
            width: '100%',
            marginTop: '2rem'
          }}
        >
          {loading ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: 'white' }} spin />} />
          ) : (
            'Submit'
          )}
        </Button>
      </Form>

      <div className='sign_in_forget_div justify-between' style={{ justifyContent: 'space-between' }}>
{/*         <p>
          Don't have an account?
          <Link className='link_font ml-2' to='../sign-up'>
            Sign up!
          </Link>
        </p> */}
        <Button type='link' style={{ color: 'red' }} onClick={handleForgotPassword}>
          Forget Password?
        </Button>
      </div>
      <Modal
        visible={forgotPasswordModalVisible}
        onCancel={handleModalClose}
        footer={null}
        className='sigIn_modal_css'
      >
        <Form onFinish={modalFormik.handleSubmit} layout='vertical' initialValues={{ email: '' }}>
          <Form.Item
            name='email'
            label='Email Address'
            rules={[{ required: true, message: 'Email is required' }]}
            validateStatus={modalFormik.errors.email ? 'error' : ''}
            help={modalFormik.errors.email}
          >
            <Input
              placeholder='Enter your email'
              name='email'
              value={modalFormik.values.email}
              onChange={modalFormik.handleChange}
              onBlur={modalFormik.handleBlur}
            />
          </Form.Item>

          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form>
        <p>
          "Upon submission, a verification email will be sent to your registered email address.
          Please follow the link provided."
        </p>
      </Modal>
    </PublicLayout>
  );
};

export default SignIn;
