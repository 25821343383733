import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { formGroupUrl } from '../../../constants/url';
import { useFormGroup } from '../../../hooks/useFormGroup';
import { usePageData } from '../../../hooks/usePage';
import { IPageData } from '../../../interfaces/page';
import { fetchFormGroups } from '../../../redux/formGroup/actions';
import AddFormGroup from './AddGroup/AddFormGroup';
import FormGroupTable from './FormGroupTable';

const pageData: IPageData = {
  title: 'Form Group',
  fulFilled: true
};

const FormGroup = () => {
  const { formGroups, editFormGroup, deleteFormGroup } = useFormGroup();
  const [loading, setLoading] = useState(true);
  const role = localStorage.getItem('role');

  usePageData(pageData);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        await dispatch(fetchFormGroups(formGroupUrl, headers));
      } catch (error) {
        console.error('Failed to fetch Form Group data:', error);
        message.error('Failed to fetch Form Group data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  if (loading) {
    return (
      <Spin
        spinning={loading}
        size='large'
        indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
      />
    );
  }

  return (
    <>
      {role === 'admin' && (
        <header className='page-header'>
          <AddFormGroup />
        </header>
      )}
      <FormGroupTable
        onDeleteFormGroup={deleteFormGroup}
        onEditFormGroup={editFormGroup}
        formGroups={formGroups}
      />
    </>
  );
};

export default FormGroup;
