import { IRoute } from '../interfaces/routing';
import SettingsPage from '../pages/settings/Settings';

import viewFormPage from '../pages/dashboards/form/ViewForm';
import AddFormPage from '../pages/dashboards/form/form';
import FormPage from '../pages/dashboards/form/formTable';
import oldFormPage from '../pages/dashboards/form/oldForm';
import PatientsPage from '../pages/dashboards/patients/Patients';
import SpeciePage from '../pages/dashboards/specie/Specie';
import TestparameterPage from '../pages/dashboards/testparameter/Testparameter';

import ChartJsPage from '../pages/charts/chart-js/ChartJs';
import EchartsPage from '../pages/charts/echarts/EchartsPage';
import RechartsPage from '../pages/charts/recharts/Rechart';
import AlertsPage from '../pages/components/AlertsPage';
import AutocompletePage from '../pages/components/AutocompletePage';
import BadgesPage from '../pages/components/BadgesPage';
import ButtonsPage from '../pages/components/ButtonsPage';
import CardsPage from '../pages/components/CardsPage';
import CheckboxesPage from '../pages/components/CheckboxesPage';
import ContactsPage from '../pages/components/ContactsPage';
import InputsPage from '../pages/components/InputsPage';
import ModalsPage from '../pages/components/ModalsPage';
import RadiosPage from '../pages/components/RadiosPage';
import RatingPage from '../pages/components/RatingsPage';
import SelectsPage from '../pages/components/SelectsPage';
import SwitchersPage from '../pages/components/SwitchersPage';
import TextareasPage from '../pages/components/TextAreaPage';
import TimelinePage from '../pages/components/TimlinesPage';
import AppointmentsPage from '../pages/dashboards/appointments/AppointmentsPage';
import GenerateReportPage from '../pages/dashboards/createReport/patientReportForm';
import ReportPage from '../pages/dashboards/createReport/reportTable';
import DashboardPage from '../pages/dashboards/dashboard/Dashboard';
import DiseasePage from '../pages/dashboards/disease/Disease';
import DoctorsPage from '../pages/dashboards/doctors/Doctors';
import InActiveDoctorsPage from '../pages/dashboards/doctors/InActiveDoctorsTable';
import FormulaPage from '../pages/dashboards/formula/Formula';
import HeadingsPage from '../pages/dashboards/heading/Heading';
import HospitalsPage from '../pages/dashboards/hospitals/Hospitals';
import OrganPage from '../pages/dashboards/organ/Organ';
import Payments from '../pages/dashboards/payments/Payments';
import SampleReportPage from '../pages/dashboards/reports/SampleReport';
import FormElementsPage from '../pages/forms/FormElementsPage';
import FormLayoutsPage from '../pages/forms/FormLayoutsPage';
import FormValidationPage from '../pages/forms/FormValidationPage';
import AntdIconsPage from '../pages/icons/AntdIconsPage';
import IcofontIconsPage from '../pages/icons/IcofontIconsPage';
import IconsOptionsPage from '../pages/icons/IconsOptionsPage';
import GoogleMapsPage from '../pages/maps/GoogleMapPage';
import PageVectorMaps from '../pages/maps/VectorMapsPage';
import WorldMapPage from '../pages/maps/WorldMapPage';
import Departments from '../pages/medic/Departments';
import DoctorProfilePage from '../pages/medic/DoctorsProfilePage';
import HospitalProfilePage from '../pages/medic/HospitalProfilePage';
import PatientProfilePage from '../pages/medic/PatientProfilePage';
import EditAccountPage from '../pages/services/EditAccounPage';
import EventsCalendarPage from '../pages/services/EventsCalendarPage';
import InvoicePage from '../pages/services/InvoicePage';
import InvoicesPage from '../pages/services/InvoicesPage';
import PricingPage from '../pages/services/PricingPage';
import UserProfilePage from '../pages/services/UserProfilePage';
import EventsTimelinePage from '../pages/services/events-timeline/EventsTimeline';
import TablesPage from '../pages/tables/TablesPage';
import TypographyPage from '../pages/typography/TypographyPage';

import EditForm from '../pages/dashboards/form/EditForm';
import FormGroup from '../pages/dashboards/formGroup/FormGroup';
import EmailTemplate from '../pages/dashboards/reports/EmailTemplate';
export const organization: IRoute[] = [
  {
    path: 'default-dashboard',
    component: DashboardPage
  },
  {
    path: 'patients',
    component: PatientsPage
  },
  {
    path: 'patient-profile',
    component: PatientProfilePage
  },
  {
    path: 'doctors',
    component: DoctorsPage
  },
  {
    path: 'doctor-profile/:id',
    component: DoctorProfilePage
  },

  {
    path: 'hospital-profile',
    component: HospitalProfilePage
  }
];
export const doctorRoute: IRoute[] = [
  {
    path: 'default-dashboard',
    component: DashboardPage
  },
  {
    path: 'specie',
    component: SpeciePage
  },
  {
    path: 'report/:id',
    component: EmailTemplate
  },
  {
    path: 'createReport',
    component: GenerateReportPage
  },
  {
    path: 'organ',
    component: OrganPage
  },
  {
    path: 'disease',
    component: DiseasePage
  },
  {
    path: 'reports',
    component: ReportPage
  },
  {
    path: 'sample-report',
    component: SampleReportPage
  },
  {
    path: 'form',
    component: FormPage
  },
  {
    path: 'view-form/:id',
    component: viewFormPage
  },
  {
    path: 'patients',
    component: PatientsPage
  },

  {
    path: 'patient-profile',
    component: PatientProfilePage
  },

  {
    path: 'doctor-profile',
    component: DoctorProfilePage
  },
  {
    path: 'doctor-profile/:id',
    component: DoctorProfilePage
  }
];

export const defaultRoutes: IRoute[] = [
  {
    path: 'settings',
    component: SettingsPage
  },

  {
    path: 'patients',
    component: PatientsPage
  },

  {
    path: 'heading',
    component: HeadingsPage
  },

  {
    path: 'specie',
    component: SpeciePage
  },
  {
    path: 'testParameter',
    component: TestparameterPage
  },

  {
    path: 'form',
    component: FormPage
  },
  {
    path: 'oldForm',
    component: oldFormPage
  },
  {
    path: 'add-Form',
    component: AddFormPage
  },

  {
    path: 'edit-form/:id',
    component: EditForm
  },
  {
    path: 'view-form/:id',
    component: viewFormPage
  },
  {
    path: 'createReport',
    component: GenerateReportPage
  },

  {
    path: 'organ',
    component: OrganPage
  },
  {
    path: 'form-group',
    component: FormGroup
  },

  {
    path: 'formula',
    component: FormulaPage
  },

  {
    path: 'disease',
    component: DiseasePage
  },
  {
    path: 'default-dashboard',
    component: DashboardPage
  },

  {
    path: 'appointments',
    component: AppointmentsPage
  },

  {
    path: 'doctors',
    component: DoctorsPage
  },
  {
    path: 'archived',
    component: InActiveDoctorsPage
  },

  {
    path: 'hospitals',
    component: HospitalsPage
  },
  {
    path: 'doctor-profile/:id',
    component: DoctorProfilePage
  },
  {
    path: 'hospital-profile',
    component: HospitalProfilePage
  },
  {
    path: 'alerts',
    component: AlertsPage
  },
  {
    path: 'autocompletes',
    component: AutocompletePage
  },
  {
    path: 'badges',
    component: BadgesPage
  },
  {
    path: 'contacts',
    component: ContactsPage
  },
  {
    path: 'inputs',
    component: InputsPage
  },
  {
    path: 'ratings',
    component: RatingPage
  },
  {
    path: 'modal-windows',
    component: ModalsPage
  },
  {
    path: 'selects',
    component: SelectsPage
  },
  {
    path: 'switchers',
    component: SwitchersPage
  },
  {
    path: 'checkboxes',
    component: CheckboxesPage
  },
  {
    path: 'radio-buttons',
    component: RadiosPage
  },
  {
    path: 'v-timeline',
    component: TimelinePage
  },
  {
    path: 'cards',
    component: CardsPage
  },
  {
    path: 'buttons',
    component: ButtonsPage
  },
  {
    path: 'textareas',
    component: TextareasPage
  },
  {
    path: 'patient-profile',
    component: PatientProfilePage
  },

  {
    path: 'payments',
    component: Payments
  },
  {
    path: 'report/:id',
    component: EmailTemplate
  },
  {
    path: 'departments',
    component: Departments
  },
  {
    path: 'invoices',
    component: InvoicesPage
  },
  {
    path: 'invoice',
    component: InvoicePage
  },
  {
    path: 'pricing',
    component: PricingPage
  },
  {
    path: 'events-timeline',
    component: EventsTimelinePage
  },
  {
    path: 'user-profile',
    component: UserProfilePage
  },
  {
    path: 'edit-account',
    component: EditAccountPage
  },
  {
    path: 'events-calendar',
    component: EventsCalendarPage
  },
  {
    path: 'echarts',
    component: EchartsPage
  },
  {
    path: 'recharts',
    component: RechartsPage
  },
  {
    path: 'chart-js',
    component: ChartJsPage
  },
  {
    path: 'typography',
    component: TypographyPage
  },
  {
    path: 'google-map',
    component: GoogleMapsPage
  },
  {
    path: 'vector-map',
    component: PageVectorMaps
  },
  {
    path: 'world-map',
    component: WorldMapPage
  },
  {
    path: 'tables',
    component: TablesPage
  },
  {
    path: 'form-elements',
    component: FormElementsPage
  },
  {
    path: 'form-layouts',
    component: FormLayoutsPage
  },
  {
    path: 'form-validation',
    component: FormValidationPage
  },
  {
    path: 'ant-icons',
    component: AntdIconsPage
  },
  {
    path: 'icons-options',
    component: IconsOptionsPage
  },
  {
    path: 'icofont-icons',
    component: IcofontIconsPage
  }
];
