export const login = () => ({
  type: 'LOGIN' as const
});

export const logout = () => ({
  type: 'LOGOUT' as const
});

export const clearLocalStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('doctor_id');
  localStorage.removeItem('admin_id');
  localStorage.removeItem('name');
};

export type AuthActionTypes = ReturnType<typeof login | typeof logout>;
