import { IHospital } from '../../interfaces/hospital';

export const SET_HOSPITALS = '[hospital] Set';
export const ADD_HOSPITAL = '[hospital] Add';
export const EDIT_HOSPITAL = '[hospital] Edit';
export const DELETE_HOSPITAL = '[hospital] Delete';

export interface SetHospitalAction {
  type: typeof SET_HOSPITALS;
  payload: IHospital[];
}

export interface AddHospitalAction {
  type: typeof ADD_HOSPITAL;
  payload: IHospital;
}

export interface EditHospitalAction {
  type: typeof EDIT_HOSPITAL;
  payload: IHospital;
}

export interface DeleteHospitalAction {
  type: typeof DELETE_HOSPITAL;
  id: string;
}

export type HospitalActions =
  | SetHospitalAction
  | AddHospitalAction
  | DeleteHospitalAction
  | EditHospitalAction;
