import { Button, message, Form, Input } from 'antd';

import { usePageData } from '../../hooks/usePage';

import { IPageData } from '../../interfaces/page';
import axios from 'axios';
import { updateAccountUrl } from '../../constants/url';

const pageData: IPageData = {
  title: 'Edit account',
  fulFilled: true
};

const FormItem = Form.Item;

const PasswordForm = () => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  const admin_id = localStorage.getItem('admin_id');
  const doctor_id = localStorage.getItem('doctor_id');
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  };
  let initialValues = {
    current_password: '',
    password: '',
    confirm_password: ''
  };
  const validatePasswordLength = (value) => {
    if (value && value.length < 6) {
      return Promise.reject('Password must be at least 6 characters');
    }
    return Promise.resolve();
  };
  let handleUpdatePassword = async (value) => {
    try {
      const res = await axios.patch(
        `${updateAccountUrl}/${role === 'admin' ? admin_id : doctor_id}`,
        value,
        { headers }
      );

      if (res.status === 200) {
        message.success('Password updated successfully');
      } else {
        message.error(`Unexpected response: ${res.status}`);
      }
    } catch (error: any) {
      message.error('Invalid credentials: Check your Current Password');
    }
  };
  return (
    <Form layout='vertical' onFinish={handleUpdatePassword} initialValues={initialValues}>
      <FormItem name='currentPassword' label='Current password'>
        <Input.Password placeholder='Current Password' />
      </FormItem>

      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <FormItem
            name='password'
            label='New Password'
            rules={[
              { required: true, message: 'Please enter new password' },
              { validator: validatePasswordLength }
            ]}
          >
            <Input.Password placeholder='New Password' />
          </FormItem>
        </div>

        <div className='col-md-6 col-sm-12'>
          <FormItem
            name='confirmPassword'
            label='Confirm Password'
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The two passwords that you entered do not match!');
                }
              })
            ]}
          >
            <Input.Password placeholder='Confirm Password' />
          </FormItem>
        </div>
      </div>

      <div className='d-flex justify-content-end'>
        <Button type='primary' htmlType='submit'>
          Change password
        </Button>
      </div>
    </Form>
  );
};

const EditAccountPage = () => {
  usePageData(pageData);
  const role = localStorage.getItem('role');

  let maxWidth = role === 'admin' ? 690 : 'none';
  return (
    <div className='stack' style={{ maxWidth: maxWidth, margin: '0 auto' }}>
      <PasswordForm />
    </div>
  );
};

export default EditAccountPage;
