import { IDoctor } from '../../interfaces/doctor';

export const SET_DOCTORS = '[doctors] Set';
export const ADD_DOCTOR = '[doctors] Add';
export const EDIT_DOCTOR = '[doctors] Edit';
export const DELETE_DOCTOR = '[doctors] Delete';

export interface SetDoctorAction {
  type: typeof SET_DOCTORS;
  payload: IDoctor[];
}

export interface AddDoctorAction {
  type: typeof ADD_DOCTOR;
  payload: IDoctor;
}

export interface EditDoctorAction {
  type: typeof EDIT_DOCTOR;
  payload: IDoctor;
}

export interface DeleteDoctorAction {
  type: typeof DELETE_DOCTOR;
  id: string;
}

export type DoctorsActions =
  | SetDoctorAction
  | AddDoctorAction
  | DeleteDoctorAction
  | EditDoctorAction;
