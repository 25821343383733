import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Input, message } from 'antd';
import PublicLayout from '../../layout/public/Public';
import { useNavigate, useParams } from 'react-router';
import { resetPasswordUrl } from '../../constants/url';

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required')
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${resetPasswordUrl}/${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            password: values.password
          })
        });

        if (response.status === 201) {
          message.success('User updated successfully');
          navigate('/sign-in');
        } else if (response.status === 400) {
          message.error('Password must be at least 6 characters');
        } else {
          message.error('Error updating password');
        }
      } catch (error) {
        console.error('Error updating password:', error);
      }
    }
  });

  const handleFormValuesChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };

  return (
    <PublicLayout bgImg={`${window.origin}/content/register-login-bg.png`}>
      <h4 className='mt-0 mb-1'>Reset Password</h4>
      <p className='text-color-200'>
        Please enter your new password to reset your current password
      </p>
      <div className='reset-password-container'>
        <Form onFinish={formik.handleSubmit} layout='vertical'>
          <Form.Item
            label='New Password'
            name='password'
            validateStatus={formik.errors.password ? 'error' : ''}
            help={formik.errors.password}
          >
            <Input.Password
              placeholder='Enter new password'
              name='password'
              value={formik.values.password}
              onChange={handleFormValuesChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>

          <Form.Item
            label='Confirm New Password'
            name='confirmPassword'
            validateStatus={formik.errors.confirmPassword ? 'error' : ''}
            help={formik.errors.confirmPassword}
          >
            <Input.Password
              placeholder='Confirm new password'
              name='confirmPassword'
              value={formik.values.confirmPassword}
              onChange={handleFormValuesChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>

          <Button type='primary' htmlType='submit'>
            Update Password
          </Button>
        </Form>
      </div>
    </PublicLayout>
  );
};

export default ResetPassword;
