import React, { useEffect, useState } from 'react';
import { Table, Input, Collapse, message, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { IFinding } from '../../../interfaces/finding';

const { TextArea } = Input;
const { Column, ColumnGroup } = Table;
const { Panel } = Collapse;
const { Option } = Select;

interface MyComponentProps {
  findingData: IFinding[];
  setFindingData: React.Dispatch<React.SetStateAction<IFinding[]>>;
  checkedFindingIds: {
    id: string;
    title: string;
    heading: string;
    descriptions: [{ id: string; description: string }];
  }[];
  setCheckedFindingIds: any;
}

const Finding: React.FC<MyComponentProps> = ({
  findingData,
  setFindingData,
  checkedFindingIds,
  setCheckedFindingIds
}) => {
  //   const [selectedHeading, setSelectedHeading] = useState<string | undefined>(undefined);
  const [selectedHeadings, setSelectedHeadings] = useState<
    Record<string, Record<string, string | undefined>>
  >({});

  const handleCheckboxChange = (category: IFinding, record: any, checked: boolean) => {
    const categoryId = category._id;
    const categoryTitle = category.title;
    if (record.details.length === 1 || selectedHeadings[categoryId]?.[record._id]) {
      const indexToSet =
        record.details.length === 1 ? 0 : selectedHeadings[categoryId]?.[record._id];
      const selectedFindingId = record?._id;
      const selectedDescriptions = record.details[indexToSet].description;

      setCheckedFindingIds((prevIds) => {
        const existingCategoryIndex = prevIds.findIndex((item) => item.id === categoryId);

        if (existingCategoryIndex !== -1) {
          // Category already exists
          const existingCategory = prevIds[existingCategoryIndex];
          const existingFindingIndex = existingCategory.descriptions.findIndex(
            (description) => description.id === selectedFindingId
          );

          if (existingFindingIndex !== -1) {
            // If descriptions are empty, remove the category
            if (prevIds[existingFindingIndex].descriptions.length === 1) {
              const updatedIds = [...prevIds];
              updatedIds.splice(existingFindingIndex, 1);
              return updatedIds;
            }
            // Finding already exists, remove it
            const updatedDescriptions = existingCategory.descriptions.filter(
              (description) => description.id !== selectedFindingId
            );

            const updatedCategory = {
              ...existingCategory,
              descriptions: updatedDescriptions
            };

            const updatedIds = [...prevIds];
            updatedIds[existingCategoryIndex] = updatedCategory;
            return updatedIds;
          } else {
            // Finding doesn't exist, add it
            const updatedCategory = {
              ...existingCategory,
              descriptions: [
                ...existingCategory.descriptions,
                { id: selectedFindingId, description: selectedDescriptions }
              ]
            };

            const updatedIds = [...prevIds];
            updatedIds[existingCategoryIndex] = updatedCategory;
            return updatedIds;
          }
        } else {
          // Category doesn't exist, create a new one
          const newCategory = {
            id: categoryId,
            title: categoryTitle,
            descriptions: [{ id: selectedFindingId, description: selectedDescriptions }]
          };

          return [...prevIds, newCategory];
        }
      });
    } else {
      message.error('Please select label first');
    }
  };

  const handleHeadingChange = (categoryId: string, finding: any, value: string) => {
    setSelectedHeadings((prevHeadings) => ({
      ...prevHeadings,
      [categoryId]: {
        ...(prevHeadings[categoryId] || {}),
        [finding._id]: value
      }
    }));

    const selectedDescriptions = finding.details[value].description;

    setCheckedFindingIds((prevIds) => {
      return prevIds.map((category) => {
        if (category.id === categoryId) {
          const updatedDescriptions = category.descriptions.map((description) => {
            if (description.id === finding._id) {
              return {
                ...description,
                description: selectedDescriptions
              };
            }
            return description;
          });

          return {
            ...category,
            descriptions: updatedDescriptions
          };
        }
        return category;
      });
    });
  };
  const handleDescriptionChange = (categoryId, findingId, detailIndex, newValue) => {
    const updatedFindingData = findingData.map((category) =>
      category._id === categoryId
        ? {
            ...category,
            findings: category.findings.map((finding) =>
              finding._id === findingId
                ? {
                    ...finding,
                    details: finding.details.map((detail, index) =>
                      index === detailIndex ? { ...detail, description: newValue } : detail
                    )
                  }
                : finding
            )
          }
        : category
    );
    setFindingData(updatedFindingData);

    setCheckedFindingIds((prevIds) => {
      if (prevIds !== undefined) {
        const existingCategoryIndex = prevIds.findIndex((item) => item.id === categoryId);

        if (existingCategoryIndex !== -1) {
          // Category already exists
          const existingCategory = prevIds[existingCategoryIndex];
          const existingFindingIndex = existingCategory.descriptions.findIndex(
            (description) => description.id === findingId
          );

          if (existingFindingIndex !== -1) {
            // Finding already exists, update its description
            const updatedDescriptions = existingCategory.descriptions.map((description) =>
              description.id === findingId ? { id: findingId, description: newValue } : description
            );

            const updatedCategory = {
              ...existingCategory,
              descriptions: updatedDescriptions
            };

            const updatedIds = [...prevIds];
            updatedIds[existingCategoryIndex] = updatedCategory;
            return updatedIds;
          }
        }
      }
      return prevIds;
    });
  };

  return (
    <Collapse accordion style={{ marginTop: '1rem' }}>
      {findingData?.map((category, categoryIndex) => (
        <Panel header={category?.title} key={category?._id}>
          <div className='mt-2'>
            {findingData.length > 0 && (
              <table className='table table_field'>
                <thead className='thead-dark'>
                  <tr>
                    <th scope='col'>Finding</th>
                    <th scope='col'>Heading</th>
                    <th scope='col'>Label</th>
                    <th scope='col'>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {category.findings.map((finding, findingIndex) => (
                    <tr key={findingIndex}>
                      <td width={'3%'}>
                        <input
                          type='checkbox'
                          checked={
                            checkedFindingIds &&
                            checkedFindingIds.some((category) =>
                              category.descriptions.some(
                                (description) => description.id === finding._id
                              )
                            )
                          }
                          onChange={(e) =>
                            handleCheckboxChange(category, finding, e.target.checked)
                          }
                        />
                      </td>
                      <td width={'25%'}>
                        <Input value={finding.title} readOnly />
                      </td>
                      {finding.details.length > 1 && (
                        <td width={'25%'}>
                          <Select
                            style={{ width: '14rem' }}
                            placeholder='Select Heading'
                            onChange={(value) =>
                              handleHeadingChange(category._id, finding, value.toString())
                            }
                            value={
                              finding.details[selectedHeadings[category._id]?.[finding._id]]
                                ?.heading
                            }
                          >
                            {finding.details.map(
                              (detail, index) =>
                                detail.heading && (
                                  <Option key={index} value={index}>
                                    {detail.heading}
                                  </Option>
                                )
                            )}
                          </Select>
                        </td>
                      )}
                      <td colSpan={finding.details.length > 1 ? 1 : 2} width={'47%'}>
                        <TextArea
                          rows={3}
                          value={
                            finding.details.length === 1
                              ? finding.details[0].description
                              : selectedHeadings[category?._id]?.[finding._id] !== undefined
                              ? finding.details[selectedHeadings[category._id]?.[finding._id]]
                                  .description
                              : ''
                          }
                          onChange={(e) =>
                            handleDescriptionChange(
                              category._id,
                              finding._id,
                              selectedHeadings[category._id]?.[finding._id] !== undefined
                                ? parseInt(selectedHeadings[category._id]?.[finding._id], 10)
                                : 0,
                              e.target.value
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </Panel>
      ))}
    </Collapse>
  );
};

export default Finding;
