import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../redux/reports/actions';

import { IAppState } from '../interfaces/app-state';
import { IReport } from '../interfaces/report';

export function useReports() {
  const dispatch = useDispatch();
  const reports = useSelector<IAppState, IReport[]>((state) => state.reports);

  const editReport = (report: IReport) => {
    return dispatch(actions.editReport(report));
  };

  const addReport = (report: IReport) => {
    return dispatch(actions.addReport(report));
  };

  const deleteReport = (id: string) => {
    return dispatch(actions.deleteReport(id));
  };

  return { reports, addReport, editReport, deleteReport };
}
