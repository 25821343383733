import { usePageData } from '../../../hooks/usePage';
import { useSpecies } from '../../../hooks/useSpecie';

import SpecieTable from './HeadingTable';

import { IPageData } from '../../../interfaces/page';
import AddHeading from './AddHeading/AddHeading';
import { useHeading } from '../../../hooks/useHeading';

const pageData: IPageData = {
  title: 'Heading',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Species',
      route: 'default-dashboard'
    },
    {
      title: 'Species'
    }
  ]
};

const SpeciePage = () => {
  const { headings, editHeading, deleteHeading } = useHeading();
  usePageData(pageData);

  return (
    <>
      <header className='page-header'>
        <AddHeading />
      </header>
      <SpecieTable
        onDeleteHeading={deleteHeading}
        onEditHeading={editHeading}
        headings={headings}
      />
    </>
  );
};

export default SpeciePage;
