import { Card, Input, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import { IFinding } from '../../../interfaces/finding';

const { TextArea } = Input;

interface MyComponentProps {
  findingData: IFinding[];
}

const ViewFindings: React.FC<MyComponentProps> = ({ findingData }) => {
  const columns: ColumnsType<any> = [
    {
      title: 'Heading',
      dataIndex: 'title',
      key: 'title',
      render: (text, record: any) => (
        <label style={{ fontWeight: 'bold', paddingTop: '20px' }}>{record.title}</label>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, record: any) => (
        <>
          {record.details.map(
            (detail, index) =>
              detail.description && (
                <div key={index}>
                  <label
                    style={{
                      borderBottom: '1px solid black',

                      fontWeight: 'bold'
                    }}
                  >
                    {detail.heading}
                  </label>
                  <label style={{ display: 'block' }}>{detail.description}</label>
                </div>
              )
          )}
        </>
      )
    }
  ];

  return (
    <Card style={{ marginTop: '1rem' }}>
      <h3 style={{ borderBottom: '1px solid #000' }}>Findings</h3>
      {findingData &&
        findingData?.map((category, categoryIndex) => (
          <Card title={category?.title} key={category?._id}>
            <div className='mt-2'>
              <Table
                dataSource={category.findings}
                rowKey='_id'
                pagination={false}
                columns={columns}
              />
            </div>
          </Card>
        ))}
    </Card>
  );
};

export default ViewFindings;
