import axios, { AxiosResponse } from 'axios';

export const getForm = async (url: string, newData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(url + newData);
    return response;
  } catch (error) {
    console.error('Error adding data:', error);
    throw error;
  }
};

export const getOrganBySpecie = async (url: string, newData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(url, newData);
    return response;
  } catch (error) {
    console.error('Error adding data:', error);
    throw error;
  }
};

export const getDiseasesBySpecie = async (url: string, headers: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(url, { headers });
    return response;
  } catch (error) {
    console.error('Error adding data:', error);
    throw error;
  }
};

export const getFormByOrgan = async (url: string, headers: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(url, { headers });
    return response;
  } catch (error) {
    console.error('Error adding data:', error);
    throw error;
  }
};

export const saveConclusionTemplate = async (
  url: string,
  diseaseId: string,
  newData: any
): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(`${url}/${diseaseId}`, newData);
    return response.data;
  } catch (error) {
    console.error('Error adding data:', error);
    throw error;
  }
};

export const postReportData = async (url: string, formData: FormData): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

export const postReportMedia = async (url: string, formData: FormData): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

export const postReportFindingData = async (url: string, Data: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(url, Data);
    return response;
  } catch (error) {
    // Handle error
    console.error('Error updating data:', error);
    throw error;
  }
};

export const deleteData = async (url: string, deleteData: any): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(`${url}`);
    return response;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};
