import React, { useState } from 'react';
import { Button, Table, Select, InputNumber, Input, Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { DollarOutlined } from '@ant-design/icons';
import './form.scss';
import { IPageData } from '../../../interfaces/page';
import { useFormula } from '../../../hooks/useFormula';
import { useTestParameter } from '../../../hooks/useTestParameter';
import { useOrgan } from '../../../hooks/useOrgan';
import { usePageData } from '../../../hooks/usePage';

const { Column } = Table;
const { Option } = Select;

interface DataRow {
  id: number;
  formulaOption: string;
  testParameterOption: string;
  value: number;
  description: string;
  organs: string;
  isSaved: boolean;
  input1: string;
  input2: string;
  input3: string;
}

interface Section {
  id: number;
  heading: string;
  data: DataRow[];
}

const pageData: IPageData = {
  title: 'Form',
  fulFilled: true
};

const DataTable: React.FC = () => {
  const { formulas } = useFormula();
  const { organs } = useOrgan();
  const { testParameters } = useTestParameter();
  const [inputText, setInputText] = useState<string>('');
  const [sections, setSections] = useState<Section[]>([
    {
      id: 1,
      heading: 'Section 1',
      data: [
        {
          id: 1,
          formulaOption: 'x+1',
          testParameterOption: 'Septum',
          value: 5,
          organs: 'Liver',
          description: '',
          isSaved: false,
          input1: '',
          input2: '',
          input3: ''
        }
      ]
    }
  ]);

  const handleAddRow = (sectionIndex: number, rowData: DataRow) => {
    const newId =
      sections[sectionIndex].data.length > 0
        ? Math.max(...sections[sectionIndex].data.map((row) => row.id), 0) + 1
        : 1;

    const newRow: DataRow = {
      id: newId,
      formulaOption: rowData.formulaOption,
      testParameterOption: rowData.testParameterOption,
      organs: rowData.organs,
      value: rowData.value,
      description: rowData.description,
      isSaved: false,
      input1: rowData.input1,
      input2: rowData.input2,
      input3: rowData.input3
    };

    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].data = [...(updatedSections[sectionIndex].data || []), newRow];
      return updatedSections;
    });
  };

  const handleRemoveRow = (id: number, sectionIndex: number) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].data = (updatedSections[sectionIndex].data || []).filter(
        (row) => row.id !== id
      );
      return updatedSections;
    });
  };

  const handleChange = (id: number, field: string, value: any, sectionIndex: number) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].data = (updatedSections[sectionIndex].data || []).map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
      return updatedSections;
    });
  };

  const handleHeadingChange = (value: string, sectionIndex: number) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].heading = value;
      return updatedSections;
    });
  };

  const handleValueChange = (id: number, value: number | undefined, sectionIndex: number) => {
    handleChange(id, 'value', value || 0, sectionIndex);
  };

  const handleDescriptionChange = (id: number, value: string, sectionIndex: number) => {
    handleChange(id, 'description', value, sectionIndex);
  };

  const handleUpdateData = (sectionIndex: number) => {
    const updatedRows = (sections[sectionIndex].data || []).filter((row) => !row.isSaved);
  };

  const handleAddSectionClick = () => {
    const newSectionIndex = sections.length + 1;
    setSections((prevSections) => [
      ...prevSections,
      {
        id: newSectionIndex,
        heading: `Section ${newSectionIndex}`,
        data: [{ ...sections[0].data[0], id: 1 }]
      }
    ]);
  };

  const [selectedOrgan, setSelectedOrgan] = useState();
  usePageData(pageData);

  const ExpandableContent = ({ sectionIndex, handleAddRow }) => {
    const [newRowData, setNewRowData] = useState({
      formulaOption: 'x+2',
      testParameterOption: 'Liam',
      organs: 'Liver',
      value: 5,
      description: '',
      input1: '',
      input2: '',
      input3: ''
    });

    const handleNewRowValueChange = (field, value) => {
      setNewRowData((prevData) => ({ ...prevData, [field]: value }));
    };

    const handleAddNewRow = () => {
      handleAddRow(sectionIndex, newRowData);
      setNewRowData((prevData) => ({
        ...prevData,
        formulaOption: 'x+2',
        testParameterOption: 'Liam',
        organs: 'Liver',
        value: 5,
        description: '',
        input1: '',
        input2: '',
        input3: ''
      }));
    };

    return (
      <div className='expandable-content'>
        {/* New input fields */}
        <div className='form-group'>
          <Input
            value={newRowData.input1}
            onChange={(e) => handleNewRowValueChange('input1', e.target.value)}
            placeholder='2x+1'
          />
        </div>
        <div className='form-group'>
          <Input
            value={newRowData.input1}
            onChange={(e) => handleNewRowValueChange('input1', e.target.value)}
            placeholder='Input 1'
          />
        </div>
        <div className='form-group'>
          <Input
            value={newRowData.input2}
            onChange={(e) => handleNewRowValueChange('input2', e.target.value)}
            placeholder='2x+4'
          />
        </div>
        <div className='form-group'>
          <Input
            value={newRowData.input2}
            onChange={(e) => handleNewRowValueChange('input2', e.target.value)}
            placeholder='Input 2'
          />
        </div>

        <div className='form-group'>
          <Input
            value={newRowData.input3}
            onChange={(e) => handleNewRowValueChange('input3', e.target.value)}
            placeholder='2x+9'
          />
        </div>
        <div className='form-group'>
          <Input
            value={newRowData.input3}
            onChange={(e) => handleNewRowValueChange('input3', e.target.value)}
            placeholder='Input 3'
          />
        </div>

        <Button type='primary' onClick={handleAddNewRow}>
          Add Row
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className='form-header'>
        <div className='form-input'>
          <Input
            placeholder='Title'
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />
        </div>
        <div className='form-input'>
          <Select
            value={selectedOrgan} // Assuming the first row's value for consistency
            onChange={(value) => setSelectedOrgan(value)}
            placeholder='Lungs'
          >
            {organs.map((item) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
        <Button type='primary' htmlType='submit'>
          Create Form
        </Button>
      </div>
      <Button type='primary' onClick={handleAddSectionClick}>
        Add Section
      </Button>
      {sections.map((section, sectionIndex) => (
        <div key={section.id} className='mt-4'>
          <Input
            value={section.heading}
            onChange={(e) => handleHeadingChange(e.target.value, sectionIndex)}
            placeholder={`Heading - Section ${sectionIndex + 1}`}
            className='mb-2'
          />
          <Table
            dataSource={section.data}
            rowKey='id'
            pagination={false}
            expandable={{
              rowExpandable: (record) => true,
              expandedRowRender: () => (
                <ExpandableContent sectionIndex={sectionIndex} handleAddRow={handleAddRow} />
              )
            }}
          >
            <Column
              title={`Test Parameter `}
              dataIndex='testParameterOption'
              key='testParameterOption'
              render={(text, record: DataRow) => (
                <Select
                  value={record.testParameterOption}
                  onChange={(value) =>
                    handleChange(record.id, 'testParameterOption', value, sectionIndex)
                  }
                >
                  {testParameters.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              )}
            />
            <Column
              title={`Formula `}
              dataIndex='formulaOption'
              key='formulaOption'
              render={(text, record: DataRow) => (
                <Select
                  value={record.formulaOption}
                  onChange={(value) =>
                    handleChange(record.id, 'formulaOption', value, sectionIndex)
                  }
                >
                  {formulas.map((item) => (
                    <Option key={item.id} value={item.heading}>
                      {item.heading}
                    </Option>
                  ))}
                </Select>
              )}
            />
            <Column
              title={`Actions`}
              key={`actions`}
              render={(text, record: DataRow) => (
                <div className='form-btn-row'>
                  {record.isSaved ? (
                    <Popconfirm
                      title='Are you sure to delete this row?'
                      okText='Yes'
                      cancelText='No'
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      onConfirm={() => handleRemoveRow(record.id, sectionIndex)}
                    >
                      <Button shape='circle' danger>
                        <span className='icofont icofont-ui-delete' />
                      </Button>
                    </Popconfirm>
                  ) : (
                    <div style={{ display: 'flex', gap: '1rem' }}>
                      <Button type='text' onClick={() => handleAddRow(sectionIndex, record)}>
                        Add Row
                      </Button>
                      <Popconfirm
                        title='Are you sure to remove this row?'
                        okText='Yes'
                        cancelText='No'
                        onConfirm={() => handleRemoveRow(record.id, sectionIndex)}
                      >
                        <Button type='text' danger>
                          Remove
                        </Button>
                        <Button type='text'>Save</Button>
                      </Popconfirm>
                    </div>
                  )}
                </div>
              )}
            />
          </Table>
        </div>
      ))}
    </>
  );
};

export default DataTable;
