import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { Avatar, Table, Button, Modal, Tag } from 'antd';

import { IHospital } from '../../../interfaces/hospital';
import HospitalForm from './AddHospital/HospitalForm';

type HospitalsProps = {
  hospitals: IHospital[];
  onEditHospital?: (hospital: IHospital) => void;
  onDeleteHospital?: (id: string) => void;
};

type HospitalImgProps = {
  img: string;
};

const HospitalImg = ({ img }: HospitalImgProps) => {
  const isData = img.startsWith('data:image');
  const isWithPath = img.startsWith('http');

  if (isData || isWithPath) {
    return <Avatar size={40} src={img} />;
  }
  return <Avatar size={40} src={`${window.location.origin}/${img}`} />;
};

const HospitalsTable = ({
  hospitals,
  onEditHospital = () => null,
  onDeleteHospital = () => null
}: HospitalsProps) => {
  const navigate = useNavigate();

  const [hospital, setHospital] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const closeModal = () => setVisibility(false);

  const handleShowInfo = () => navigate('/hospital-profile');
  const handleDeleteHospital = (id: string) => onDeleteHospital(id);
  const handleEditHospital = (editedHospital: IHospital) => {
    setHospital(editedHospital);
    setVisibility(true);
  };

  const actions = (currentHospital: IHospital) => (
    <div className='buttons-list nowrap'>
      {/* <Button shape='circle' onClick={handleShowInfo}>
        <span className='icofont icofont-external-link' />
      </Button> */}
      <Button onClick={() => handleEditHospital(currentHospital)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
      {/* <Button onClick={() => handleDeleteHospital(currentHospital.id)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button> */}
    </div>
  );

  const columns: ColumnProps<IHospital>[] = [
    {
      key: 'img',
      title: 'Logo',
      dataIndex: 'img',
      render: (img) => <HospitalImg img={img} />
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (id) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {id}
        </span>
      )
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
      render: (email) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {email}
        </span>
      )
    },
    // {
    //   key: 'visit',
    //   dataIndex: 'lastVisit',
    //   title: 'Last visit',
    //   render: (visit) => (
    //     <span className='nowrap' style={{ color: '#a5a5a5' }}>
    //       {visit.split('T')[0]}
    //     </span>
    //   )
    // },
    // {
    //   key: 'address',
    //   dataIndex: 'address',
    //   title: 'Address',
    //   render: (address) => <span style={{ minWidth: 200, display: 'block' }}>{address}</span>
    // },
    {
      key: 'number',
      dataIndex: 'number',
      title: 'Number',
      render: (phone) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
          {phone}
        </span>
      )
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      render: (status) => (
        <Tag style={{ borderRadius: 20 }} color={status === 'Approved' ? '#b7ce63' : '#cec759'}>
          {status}
        </Tag>
      ),
      sorter: (a, b) => (a.status > b.status ? 1 : -1)
    },
    {
      key: 'resendInvitation',
      dataIndex: 'resendInvitation',
      title: 'Resend Invitation',
      sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (id) => (
        <Button className='nowrap' type='primary'>
          Resend invitation
        </Button>
      )
    },
    {
      key: 'actions',
      title: 'Actions',
      render: actions
    }
  ];

  const pagination = hospitals.length <= 10 ? false : {};

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={hospitals}
        columns={columns}
      />

      <Modal
        visible={visibility}
        footer={null}
        onCancel={closeModal}
        title={<h3 className='title'>Edit Hospital</h3>}
      >
        <HospitalForm
          submitText='Update Hospital'
          onCancel={closeModal}
          onSubmit={onEditHospital}
          hospital={hospital}
        />
      </Modal>
    </>
  );
};

export default HospitalsTable;
