import { AuthActionTypes } from './action';

interface AuthState {
  authenticated: boolean;
}

const initialState: AuthState = {
  authenticated: !!localStorage.getItem('token')
};

export const authReducer = (
  state: AuthState = initialState,
  action: AuthActionTypes
): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        authenticated: true
      };
    case 'LOGOUT':
      return {
        ...state,
        authenticated: false
      };
    default:
      return state;
  }
};
